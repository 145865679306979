import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Alert = (props) => {
  return (
        <Dialog
            open={props.openAlert}
            onClose={props.toggleAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                { props.message }
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.toggleAlert()} color="primary">
                    Cancel
                </Button>
                {props.toggleAlt && <Button onClick={() => {
                    props.toggleAlert()
                    props.toggleAlt()}} 
                    color="primary">
                    Reschedule
                </Button>}
                <Button onClick={() => { 
                    props.action(); 
                    props.toggleAlert(); 
                    }} 
                    color="primary" autoFocus>
                    { props.buttonName ? props.buttonName : "Delete" }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Alert;