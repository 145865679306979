import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { updateFamily } from '../../store/actions/parentActions';
import SelectLocation from '../shared/SelectLocation';
import { connect } from 'react-redux';

class ParentDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            parentOneFirstName: props.parent.parentOneFirstName,
            parentOneLastName: props.parent.parentOneLastName,
            parentTwoFirstName: props.parent.parentTwoFirstName,
            parentTwoLastName: props.parent.parentTwoLastName,
            caregiverFirstName: props.parent.caregiverFirstName,
            caregiverLastName: props.parent.caregiverLastName,
            primaryEmail: props.parent.primaryEmail,
            secondaryEmail: props.parent.secondaryEmail,
            cellNumber: props.parent.cellNumber,
            homeNumber: props.parent.homeNumber,
            address: props.parent.address,
            city: props.parent.city,
            state: props.parent.state,
            zipCode: props.parent.zipCode,
            primaryCenter: props.parent.primaryCenter,
            secondaryCenter: props.parent.secondaryCenter,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }
    componentDidUpdate() {
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('details-form');
        if(wrapper) 
            wrapper.classList.add('active');
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = () => {
        let oldParent = { ...this.props.parent };
        let newParent = { ...this.state };
        let updatedFamily = Object.assign(oldParent, newParent);
        const { parentOneFirstName, parentOneLastName, primaryCenter, cellNumber, primaryEmail } = updatedFamily;

        if(parentOneFirstName.length === 0 || parentOneLastName.length === 0 || primaryCenter.length === 0 || cellNumber.length === 0 || primaryEmail.length === 0) {
            this.props.handleOpenPopup("error", "Please fill in required fields!")
        } else {
            this.props.updateFamily(this.props.parentId, updatedFamily);
            this.props.handleOpenPopup("success", `The ${parentOneLastName} Family updated!`);
        }
    }

    render() {
        return (
            <form className="details-form" id="details-form">
                <div className="fields-container">
                    <TextField
                        className="field"
                        label="Parent First Name*"
                        name="parentOneFirstName"
                        value={this.state.parentOneFirstName}
                        onChange={this.handleChange('parentOneFirstName')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Parent Last Name*"
                        name="parentOneLastName"
                        value={this.state.parentOneLastName}
                        onChange={this.handleChange('parentOneLastName')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Parent (2) First Name"
                        name="parentTwoFirstName"
                        value={this.state.parentTwoFirstName}
                        onChange={this.handleChange('parentTwoFirstName')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Parent (2) Last Name"
                        name="parentTwoLastName"
                        value={this.state.parentTwoLastName}
                        onChange={this.handleChange('parentTwoLastName')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Caregiver First Name"
                        name="caregiverFirstName"
                        value={this.state.caregiverFirstName}
                        onChange={this.handleChange('caregiverFirstName')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Caregiver Last Name"
                        name="caregiverLastName"
                        value={this.state.caregiverLastName}
                        onChange={this.handleChange('caregiverLastName')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Primary E-mail*"
                        name="primaryEmail"
                        value={this.state.primaryEmail}
                        onChange={this.handleChange('primaryEmail')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Secondary E-mail"
                        name="secondaryEmail"
                        value={this.state.secondaryEmail}
                        onChange={this.handleChange('secondaryEmail')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Cell Phone*"
                        name="cellNumber"
                        value={this.state.cellNumber}
                        onChange={this.handleChange('cellNumber')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Home Phone"
                        name="homeNumber"
                        value={this.state.homeNumber}
                        onChange={this.handleChange('homeNumber')}
                        margin="normal"
                        variant="outlined"/>
            
                    <TextField
                        className="field"
                        label="Address"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleChange('address')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="City"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange('city')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="State"
                        name="state"
                        value={this.state.state}
                        onChange={this.handleChange('state')}
                        margin="normal"
                        variant="outlined"/>
                    <TextField
                        className="field"
                        label="Zip Code"
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleChange('zipCode')}
                        margin="normal"
                        variant="outlined"/>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                        <>
                            <SelectLocation
                                className="field" 
                                title="Primary Center - Required"
                                name="primaryCenter"
                                value={this.state.primaryCenter} 
                                handleChange={this.handleSelect}/>
                            <SelectLocation
                                className="field" 
                                title="Secondary Center"
                                name="secondaryCenter"
                                value={this.state.secondaryCenter} 
                                handleChange={this.handleSelect}/> 
                        </>
                    }
                </div>
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <div className="action-container">
                    <Button className="save-btn" variant="contained" onClick={this.handleSubmit} color="secondary">
                        SAVE
                    </Button>
                </div>}
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.firebase.profile,
        parentError: state.parents.parentError
    }
}

export default connect(mapStateToProps, { updateFamily })(ParentDetails);