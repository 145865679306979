import React from 'react';

const InvoiceLinkRenderer = props => {
    return (
        props.data.hostedInvoiceUrl 
            ? <a href={`${props.data.hostedInvoiceUrl}`} target="_blank" style={{ color: "#1e90ff"}}>{props.value}</a>
            : props.value
    )
}

export default InvoiceLinkRenderer;