import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SelectLocation from '../shared/SelectLocation';
import './style/parentForm.scss';
import { connect } from 'react-redux';
import { createFamily } from '../../store/actions/parentActions';

class AddParentForm extends PureComponent {
    state = {
        parentOneFirstName: "",
        parentOneLastName: "",
        parentTwoFirstName: "",
        parentTwoLastName: "",
        caregiverFirstName: "",
        caregiverLastName: "",
        primaryEmail: "",
        secondaryEmail: "",
        cellNumber: "",
        homeNumber: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        primaryCenter: "",
        secondaryCenter: "",
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value })
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSave = () => {
        const { parentOneFirstName, parentOneLastName, primaryCenter, cellNumber, primaryEmail } = this.state;
        if(parentOneFirstName.length === 0 || parentOneLastName.length === 0 || primaryCenter.length === 0 || cellNumber.length === 0 || primaryEmail.length === 0) {
            this.props.handleOpenPopup("error", "Please fill in required fields!");
        } else {
            this.props.createFamily(this.state);
            this.setState({
                parentOneFirstName: "",
                parentOneLastName: "",
                parentTwoFirstName: "",
                parentTwoLastName: "",
                caregiverFirstName: "",
                caregiverLastName: "",
                primaryEmail: "",
                secondaryEmail: "",
                cellNumber: "",
                homeNumber: "",
                address: "",
                city: "",
                state: "",
                zipCode: "",
                primaryCenter: "",
                secondaryCenter: ""
            });
            this.props.handleOpenPopup("success", `The ${parentOneLastName} Family added!`);
            this.props.toggleForm();
        }
    }

    render() {
        return (
            <form className="details-form">
                <div className="fields-container">
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Parent First Name*"
                        name="parentOneFirstName"
                        value={this.state.parentOneFirstName}
                        onChange={this.handleChange('parentOneFirstName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Parent Last Name*"
                        name="parentOneLastName"
                        value={this.state.parentOneLastName}
                        onChange={this.handleChange('parentOneLastName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Parent First Name (2)"
                        name="parentTwoFirstName"
                        value={this.state.parentTwoFirstName}
                        onChange={this.handleChange('parentTwoFirstName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Parent Last Name (2)"
                        name="parentTwoLastName"
                        value={this.state.parentTwoLastName}
                        onChange={this.handleChange('parentTwoLastName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Caregiver First Name"
                        name="caregiverFirstName"
                        value={this.state.caregiverFirstName}
                        onChange={this.handleChange('caregiverFirstName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Caregiver Last Name"
                        name="caregiverLastName"
                        value={this.state.caregiverLastName}
                        onChange={this.handleChange('caregiverLastName')}
                        margin="normal"/>        
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Primary E-mail*"
                        name="primaryEmail"
                        value={this.state.primaryEmail}
                        onChange={this.handleChange('primaryEmail')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Secondary E-mail"
                        name="secondaryEmail"
                        value={this.state.secondaryEmail}
                        onChange={this.handleChange('secondaryEmail')}
                        margin="normal"/>                     
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Cell Phone*"
                        name="cellNumber"
                        value={this.state.cellNumber}
                        onChange={this.handleChange('cellNumber')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Home Phone"
                        name="homeNumber"
                        value={this.state.homeNumber}
                        onChange={this.handleChange('homeNumber')}
                        margin="normal"/> 
                    <SelectLocation 
                        className="field" 
                        title="Primary Center*"
                        name="primaryCenter"
                        value={this.state.primaryCenter} 
                        handleChange={this.handleSelect}/> 
                    <SelectLocation 
                        className="field" 
                        title="Secondary Center"
                        name="secondaryCenter"
                        value={this.state.secondaryCenter} 
                        handleChange={this.handleSelect}/>               
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Address"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleChange('address')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="City"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange('city')}
                        margin="normal"/>                     
                    <TextField
                        className="field"
                        variant="outlined"
                        label="State"
                        name="state"
                        value={this.state.state}
                        onChange={this.handleChange('state')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Zip Code"
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleChange('zipCode')}
                        margin="normal"/>                     
                </div>
                <div className="action-container">
                    <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSave}>
                        save
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect(null, { createFamily })(AddParentForm);