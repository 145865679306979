import React from 'react';
import { connect } from 'react-redux';
import Loading from './components/shared/Loading';
import './style/utilities.scss';

// Components
import Landing from './components/auth/Landing';
import Layout from './components/layout/Layout';
import Approval from './components/auth/Approval';
 
const App = props => {
    const { auth, profile } = props;
    let display = auth.uid ? <Layout /> : <Landing />;

    if(!profile.isLoaded && auth.uid){
        return <div id="loading"><Loading /></div>;
    }

    if((profile.isTeacher === false && profile.isAdmin === false && profile.isCenterManager === false)){
        return <Approval />;
    } else {
        return (
            <div className="App">
              { display }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

export default connect(mapStateToProps)(App);