import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    textField: {
      width: '40%',
      margin: 0,
    },
}));

const Searchbar = (props) => {
    const classes = useStyles();

    return (
        <TextField
          id="outlined-search"
          label="Search"
          type="search"
          value={props.search}
          onChange={props.handleChange}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />
    );
}

export default Searchbar;