import React, { Component } from 'react';
import Alert from '../shared/Alert';
import sendLogo from '../../resources/images/send.png';
import disSendLogo from '../../resources/images/send-dis.png';
import editLogo from '../../resources/images/edit-color.png';
import disEditLogo from '../../resources/images/edit-dis.png';
import deleteLogo from '../../resources/images/delete.png';
import disDeleteLogo from '../../resources/images/delete-dis.png';
import "./styles/cellRenderers.scss";
import axios from 'axios';
import { updateStudent } from '../../store/actions/studentActions';
import { connect } from 'react-redux'

class InvoiceActionsRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            alert: false,
            message: "",
            action: null
        }
    }

    toggleAlert = (message, action) => {
        this.setState(prevState => ({ 
            alert: !prevState.alert,
            message,
            action
        }));
    }


    handleSend = () => {
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/sendStripeInvoice", { invoiceId: this.props.data.invoiceId })
            .then(res => {
            let rowNode = this.props.node;
            rowNode.setDataValue("status", "open")

            if(res && res.data && res.data.lines && res.data.lines.data && res.data.lines.data.length > 0) {
                res.data.lines.data.forEach(line => {
                    if(line.metadata && line.metadata.credits && line.metadata.studentId) {
                        
                        if(this.props.students[line.metadata.studentId]) {
                            let currentStudent = this.props.students[line.metadata.studentId]
                            let totalCredits = Number(line.metadata.credits)
                            if(currentStudent.credits) {
                                totalCredits += Number(currentStudent.credits)
                            }
                            this.props.updateStudent(line.metadata.studentId, { credits: totalCredits })
                        }
                    }
                })
            }
            })
            .catch(err => console.log(err))
    }

    handleEdit = () => {
        this.props.data.toggleEditForm(this.props.node.data)
    }

    handleDelete = () => {
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/deleteStripeInvoice", { invoiceId: this.props.data.invoiceId }).then(res => {
            this.props.data.onRemoveInvoice(this.props.node.data)
        }).catch(err => console.log(err))
    }
    

    render() {
        return (
            <div className="invoice-grid-actions">
                {this.props.value === "draft"
                ? <button className="btn" onClick={() => this.toggleAlert("Are you sure you want to send this invoice?", this.handleSend)}>
                    <img className="icon" src={sendLogo} alt="send"/>
                  </button>
                : <button className="btn">
                    <img className="icon" src={disSendLogo} alt="dis send"/>
                  </button>
                }
                {this.props.value === "draft"
                ? <button className="btn" onClick={this.handleEdit}>
                    <img className="icon" src={editLogo} alt="edit"/>
                  </button>
                : <button className="btn">
                    <img className="icon" src={disEditLogo} alt="edit"/>
                  </button>
                }
                {this.props.value  === "draft"
                ? <button className="btn" onClick={() => this.toggleAlert("Are you sure you want to delete this invoice?", this.handleDelete)}>
                    <img className="icon" src={deleteLogo} alt="delete"/>
                  </button>
                : <button className="btn">
                    <img className="icon" src={disDeleteLogo} alt="delete"/>
                  </button>}
                <Alert 
                    action={this.state.action} 
                    toggleAlert={this.toggleAlert} 
                    openAlert={this.state.alert} 
                    buttonName="Accept"
                    title={this.state.message} 
                    message={`NOTE: This action can't be undone.`}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.data.students
    }
}

export default connect(mapStateToProps, { updateStudent })(InvoiceActionsRenderer);