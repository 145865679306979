import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format, addMonths, subMonths, getMonth, getYear } from 'date-fns';

import { isLocationMatch, formatMilitaryTime } from '../../js/helpers';
import HandlePayrollStatusRenderer from './HandlePayrollStatusRenderer';
import { updateSessionStatus } from '../../store/actions/sessionActions';
import { connect } from 'react-redux'; 
import '../calendar/style/calendar.scss';
import './styles/teachers.scss';

class TeacherScheduleGrid extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            currentDate: new Date(),
            open: false,
            gridOptions: {
                columnDefs: [{
                    headerName: "Date",
                    field: "date",
                }, {
                    headerName: "Time",
                    field: "time"
                }, {
                    headerName: "Class Name",
                    field: "className"
                }, {
                    headerName: "Students",
                    field: "students"
                }, {
                    headerName: "Attendance",
                    field: "attendance"
                }, {
                    headerName: "Attendance Rate",
                    field: "attendanceRate",
                }, {
                    headerName: "Paid",
                    field: "isPaid",
                    cellClassRules: {
                        "status-green": (params) => {

                            console.log(cellStyleRule(params) === 2)
                            return cellStyleRule(params) === 2
                        },
                        "status-red": (params) => cellStyleRule(params) === 1,
                    },
                }, {
                    headerName: "Payroll Status",
                    field: "isPaid",
                    cellRenderer: "handlePayrollStatusRenderer"
                }
            ]
            },
            rowData: [],
            frameworkComponents: {
                handlePayrollStatusRenderer: HandlePayrollStatusRenderer
            },
            paginationPageSize: 20
        }
    }

    componentDidMount() {
        if(this.props.sessions && this.props.classes) {
            let rowData = convertDataToRow(this.props.sessions, this.props.classes, this.props.attendanceRecords, this.state.currentDate, this.props.teacherId, this.props.teacher, this.props.updateSessionStatus);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.sessions !== this.props.sessions && this.props.sessions && this.props.classes) 
        || (prevProps.locationFilter !== this.props.locationFilter && this.props.locationFilter && this.props.classes)
        || (prevState.currentDate !== this.state.currentDate && this.props.classes)
        || (prevProps.attendanceRecords !== this.props.attendanceRecords && this.props.attendanceRecords && this.props.classes)) {
            let rowData = convertDataToRow(this.props.sessions, this.props.classes, this.props.attendanceRecords, this.state.currentDate, this.props.teacherId, this.props.teacher, this.props.updateSessionStatus);
            this.setState({ rowData });
            
        }
    }

    renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className="col col-center">
                    <span>{format(this.state.currentDate, dateFormat)}</span>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon">chevron_right</div>
                </div>
            </div>
        );
    }

    nextMonth = () => {
        this.setState({
            currentDate: addMonths(this.state.currentDate, 1)
        });
    }
    
    prevMonth = () => {
        this.setState({
            currentDate: subMonths(this.state.currentDate, 1)
        });
    }

    render() {
        return (
            <>
                {this.renderHeader()}
                <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                    <AgGridReact
                        onGridReady={this.onGridReady}
                        gridOptions={this.state.gridOptions}
                        rowData={this.state.rowData}
                        frameworkComponents={this.state.frameworkComponents}
                        pagination={true}
                        paginationPageSize={this.state.paginationPageSize}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                        }}>
                    </AgGridReact>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state, props) => {
    return { 
        students: state.firestore.data.students,
        classes: state.firestore.data.classes,
        sessions: state.firestore.data.sessions,
        attendanceRecords: state.firestore.data.attendanceRecords
    };
}

export default connect(mapStateToProps, { updateSessionStatus })(TeacherScheduleGrid);

const convertDataToRow = (data, classes, attendanceRecords, currentDate, teacherId, teacher, togglePaid) => {
    let rowData = []
    let currentMonth = getMonth(currentDate)
    let currentYear = getYear(currentDate)

    Object.keys(data).map(key => {
        let row = data[key];
        if(row && row.teacher.id === teacherId) {
            let makeupMonth = getMonth(row.date.toDate())
            let makeupYear = getYear(row.date.toDate())
            if(makeupMonth === currentMonth && makeupYear === currentYear) {
                let classObj = classes[row.classId.id];
                let totalStudents = row.attendanceRecords ? row.attendanceRecords.length : 0;
                let present = 0;
                let absent = 0;
                let other = 0;

                row.attendanceRecords && row.attendanceRecords.forEach(recordRef => {
                    let record = attendanceRecords[recordRef.id];
                    if(record) {
                        if(record.attendance === "present") present++;
                        else if(record.attendance === "absent") absent++;
                        else if(record.attendance === "other") other++;
                    }
                })

                let current = {
                    date: row.date && format(row.date.toDate(), "MMM d, yyyy"),
                    time: formatMilitaryTime(row.startTime) + " - " + formatMilitaryTime(row.endTime),
                    className: classObj.className,
                    students: totalStudents,
                    attendance: `${present} - ${absent} - ${other}`,
                    attendanceRate: present === 0 && totalStudents === 0 ? `${0}%` : `${(present / totalStudents).toFixed(2) * 100}%`,
                    isPaid: row.isTeacherPaid ? row.isTeacherPaid : false,
                    togglePaid,
                    sessionId: key
                }
                rowData.push(current);
            }
        }
    });
    rowData.sort((a, b) => new Date(b.date) - new Date(a.date))

    return rowData;
}

const cellStyleRule = (params) => {
    if(params.value === "false" || !params.value) return 1;
    if(params.value === 'true' || params.value) return 2;
}