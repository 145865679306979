const initState = {
    authError: null
}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case "LOGIN_SUCCESS":
            return {
                ...state,
                authError: null
            }
        case "LOGIN_ERROR":
            console.log(action)
            return {
                ...state,
                authError: action.err.message
            }
        case "SIGNUP_SUCCESS":
            return {
                ...state,
                authError: null
            }
        case "SIGNUP_ERROR":
            return {
                ...state,
                authError: action.err.message
            }
        case "PASSWORD_RESET_SUCCESS": 
            return {
                ...state,
                authError: null
            }
        case "PASSWORD_RESET_ERROR":
            return {
                ...state,
                authError: action.err.message
            }
        case "LOGOUT_SUCCESS": 
            return {
                ...state,
                authError: null
            }
        case "LOGOUT_ERROR":
            return {
                ...state,
                authError: action.err.message
            }
        default: 
            return state;
    }
}

export default authReducer;