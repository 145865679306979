import React, { PureComponent } from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import { Transition, animated } from 'react-spring/renderprops'
import TextField from '@material-ui/core/TextField';
import SelectJusanLevel from '../../shared/SelectJusanLevel';
import SelectAmsanLevel from '../../shared/SelectAmsanLevel';
import CustomSelect from '../../shared/CustomSelect';

import { updateAttendance } from '../../../store/actions/attendanceActions';
import { updateStudent } from '../../../store/actions/studentActions';
import { connect } from 'react-redux';

class MobileSessionStudentItem extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            attendance: props.attendanceRecord.attendance ? props.attendanceRecord.attendance : "",
            jusanLevel: props.student.jusanLevel ? props.student.jusanLevel : "",
            amsanLevel: props.student.amsanLevel ? props.student.amsanLevel : "",
            jusanEndPage: props.jusanEndPage ? props.jusanEndPage : "",
            amsanEndPage: props.attendanceRecord.amsanEndPage ? props.attendanceRecord.amsanEndPage : "",
            jusanHomework: props.attendanceRecord.jusanHomework ? props.attendanceRecord.jusanHomework : "",
            amsanHomework: props.attendanceRecord.amsanHomework ? props.attendanceRecord.amsanHomework : "",
            jusanNotes: props.attendanceRecord.jusanNotes ? props.attendanceRecord.jusanNotes : "",
            amsanNotes: props.attendanceRecord.amsanNotes ? props.attendanceRecord.amsanNotes : "",
            comments: props.attendanceRecord.comments ? props.attendanceRecord.comments : ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.saveToggle !== this.props.saveToggle) {
            let record = Object.assign({}, this.state);
            this.props.updateAttendance(this.props.attendanceRecord.student.id, this.props.attendanceRecord.session.id, record);
            if(this.state.amsanLevel !== this.props.attendanceRecord.student.amsanLevel 
            || this.state.jusanLevel !== this.props.attendanceRecord.student.jusanLevel) {
                let updatedStudent = {
                    jusanLevel: this.state.jusanLevel,
                    amsanLevel: this.state.amsanLevel
                }
                this.props.updateStudent(this.props.attendanceRecord.student.id, updatedStudent)
            }
            this.currRecord = record;
        }
    }

    currRecord = {}

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    toggleForm = () => {
        this.setState(prevState => ({ open: !prevState.open}))
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleAltChange = name => e => {
        this.setState({[name]: e.target.value});
    }

    render() {
        return (
            <>
                <div className="mobile-session-student-item-container">
                    <div className="student-name-container">
                        <h6 className="student-name">{`${this.props.student.firstName} ${this.props.student.lastName}`}</h6>
                    </div>
                    <div className="radio-group-container">
                        {/* <RadioGroup
                            aria-label="attendance"
                            name="attendance"
                            value={this.state.attendance}
                            onChange={this.handleChange}> */}
                            <div className="attendance-controller">
                                {/* <FormControlLabel value="present" control={<Radio />} label="Present" labelPlacement="top"/>
                                <FormControlLabel value="absent" control={<Radio />} label="Absent" labelPlacement="top"/>
                                <FormControlLabel value="other" control={<Radio />} label="Makeup" labelPlacement="top"/> */}
                                <CustomSelect
                                    name="attendance"
                                    placeholder="Attendance"
                                    value={this.state.attendance}
                                    handleChange={this.handleChange}>
                                    <MenuItem value="present">Present</MenuItem>
                                    <MenuItem value="absent">Absent</MenuItem>
                                    <MenuItem value="other">Makeup</MenuItem>
                                </CustomSelect>
                            </div>
                        {/* </RadioGroup> */}
                    </div>
                    <div className="expand-btn-container">
                        <IconButton className="slide-header-icon" onClick={this.toggleForm}>
                            {this.state.open ? <ArrowUpIcon/> : <ArrowDownIcon/>}
                        </IconButton>
                    </div>
                </div>
                <Transition
                    native
                    items={this.state.open} 
                    from={{ position: 'relative', overflow: 'hidden', height: 0, bottom: 0 }}
                    enter={[{ height: 'auto' }]}
                    leave={{ height: 0 }}>
                    {show =>
                        show && (props => <animated.div style={props}>
                                            <form className="progress-form" noValidate autoComplete="off">
                                                <table className="mobile-table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Jusan</th>
                                                            <th>Amsan</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="mobile-table-header"> Book </td>
                                                            <td>
                                                                <SelectJusanLevel 
                                                                    className="" 
                                                                    value={this.state.jusanLevel} 
                                                                    handleChange={this.handleChange}/>
                                                            </td>
                                                            <td>
                                                                <SelectAmsanLevel 
                                                                    className="" 
                                                                    value={this.state.amsanLevel} 
                                                                    handleChange={this.handleChange}/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="mobile-table-header"> End Page </td>
                                                            <td>
                                                                <TextField
                                                                    variant="outlined"
                                                                    type="number"
                                                                    name="jusanEndPage"
                                                                    value={this.state.jusanEndPage}
                                                                    onChange={this.handleAltChange("jusanEndPage")}
                                                                    margin="normal"
                                                                    style={{width: 120}}
                                                                    className=""/> 
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    variant="outlined"
                                                                    name="amsanEndPage"
                                                                    type="number"
                                                                    value={this.state.amsanEndPage}
                                                                    onChange={this.handleAltChange("amsanEndPage")}
                                                                    margin="normal"
                                                                    style={{width: 120}}
                                                                    className=""/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="mobile-table-header">HW End Page</td>
                                                            <td>
                                                                <TextField
                                                                    variant="outlined"
                                                                    type="number"
                                                                    name="jusanHomework"
                                                                    value={this.state.jusanHomework}
                                                                    onChange={this.handleAltChange("jusanHomework")}
                                                                    margin="normal"
                                                                    style={{width: 120}}
                                                                    className=""/> 
                                                            </td>
                                                            <td className="mobile-table-header">
                                                                <TextField
                                                                    variant="outlined"
                                                                    name="amsanHomework"
                                                                    type="number"
                                                                    value={this.state.amsanHomework}
                                                                    onChange={this.handleAltChange("amsanHomework")}
                                                                    margin="normal"
                                                                    style={{width: 120}}
                                                                    className=""/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="mobile-table-header">Notes</td>
                                                            <td>
                                                                <TextField
                                                                    variant="outlined"
                                                                    name="jusanNotes"
                                                                    value={this.state.jusanNotes}
                                                                    onChange={this.handleAltChange("jusanNotes")}
                                                                    margin="normal"
                                                                    style={{width: 120}}
                                                                    row={2}
                                                                    className=""/> 
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    variant="outlined"
                                                                    name="amsanNotes"
                                                                    style={{width: 120}}
                                                                    value={this.state.amsanNotes}
                                                                    onChange={this.handleAltChange("amsanNotes")}
                                                                    margin="normal"
                                                                    row={2}
                                                                    className=""/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="mobile-table-header">Comments</td>
                                                            <td colSpan="2">
                                                                <TextField
                                                                    id="outlined-multiline-static"
                                                                    label="Comments"
                                                                    name="comments"
                                                                    multiline
                                                                    style={{width: '100%'}}
                                                                    value={this.state.comments}
                                                                    onChange={this.handleAltChange("comments")}
                                                                    rows="2"
                                                                    margin="normal"
                                                                    variant="outlined"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        </animated.div>)
                    }
                </Transition>
            </>
        )
    }
}


export default connect(null, { updateAttendance, updateStudent })(MobileSessionStudentItem)