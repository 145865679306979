import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format } from 'date-fns';
import { isLocationMatch } from '../../js/helpers';
import ParentLinkRenderer from '../students/ParentLinkRenderer';
import EmailRenderer from '../parents/EmailRenderer';
import LinkRenderer from '../shared/LinkRenderer';

import StudentsCellRenderer from './StudentsCellRenderer';
import TeacherCellRenderer from './TeacherCellRenderer';
import PresentCellRenderer from './PresentCellRenderer';
import AbsentCellRenderer from './AbsentCellRenderer';
import OtherCellRenderer from './OtherCellRenderer';

class ReportsGrid extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [
                    {
                        headerName: "Date",
                        field: "date",
                    },
                    {
                        headerName: "Students",
                        field: "students",
                        // cellRenderer: "StudentsCellRenderer",
                        valueGetter: (params) => {
                            return params.data.students.map(student => `${student?.firstName} ${student?.lastName}`).join(",\n")
                        }
                    },
                    {
                        headerName: "Teacher",
                        field: "teacher",
                        // cellRenderer: "TeacherCellRenderer",
                        valueGetter: params => {
                            let firstName, lastName
                            if(params.data) {
                              firstName = params.data?.teacher?.firstName;
                              lastName = params.data?.teacher?.lastName;
                            }
                            if(firstName || lastName) {
                                return `${firstName} ${lastName}`;
                            }
                            return "";
                        }
                    },
                    {
                        headerName: "Num of Students",
                        field: "",
                        valueGetter: params => {
                            return params.data.students.length
                        }
                    },
                    {
                        headerName: "Present",
                        field: "",
                        // cellRenderer: "PresentCellRenderer",
                        valueGetter: params => {
                            let value = 0;
                            if(params.data.attendanceRecords) {
                              params.data.attendanceRecords.forEach(record => {
                                if(record.attendance === "other") value++;
                              })
                            }
                          
                            return value
                        }
                    },
                    {
                        headerName: "Absent",
                        field: "",
                        // cellRenderer: "AbsentCellRenderer",
                        valueGetter: params => {
                            let value = 0;
                            if(params.data.attendanceRecords) {
                              params.data.attendanceRecords.forEach(record => {
                                if(record.attendance === "absent") value++;
                              })
                            }
                          
                            return value
                        }
                    },
                    {
                        headerName: "Other",
                        field: "",
                        // cellRenderer: "OtherCellRenderer",
                        valueGetter: params => {
                            let value = 0;
                            if(params.data.attendanceRecords) {
                              params.data.attendanceRecords.forEach(record => {
                                if(record.attendance === "other") value++;
                              })
                            }
                          
                            return value
                        }
                    }

              ]
            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                parentLinkRenderer: ParentLinkRenderer,
                emailRenderer: EmailRenderer,
                StudentsCellRenderer,
                TeacherCellRenderer,
                PresentCellRenderer,
                AbsentCellRenderer,
                OtherCellRenderer
            },
            paginationPageSize: 100
        }
    }

    componentDidMount() {
        if(this.props.rowData) {
            this.setState({ rowData: this.props.rowData || [] });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }


        if(prevProps.exportGrid !== this.props.exportGrid) {
            this.state.gridOptions.api.exportDataAsCsv();
        }

        if(prevProps.rowData !== this.props.rowData) {
          this.setState({ rowData: this.props.rowData || [] });
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default ReportsGrid;