import React, { PureComponent }from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { updateStudent } from '../../store/actions/studentActions';
import { connect } from 'react-redux';
import SelectLocation from '../shared/SelectLocation';
import SelectJusanLevel from '../shared/SelectJusanLevel';
import SelectAmsanLevel from '../shared/SelectAmsanLevel';
import DatePicker from '../shared/DatePicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomSelect from '../shared/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';

class StudentDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            firstName: props.student.firstName ? props.student.firstName : "",
            lastName: props.student.lastName ? props.student.lastName : "",
            allergies: props.student.allergies ? props.student.allergies : "",
            amsanLevel: props.student.amsanLevel ? props.student.amsanLevel : "",
            jusanLevel: props.student.jusanLevel ? props.student.jusanLevel : "",
            credits: props.student.credits ? props.student.credits : "",
            primaryCenter: props.student.primaryCenter ? props.student.primaryCenter : "",
            secondaryCenter: props.student.secondaryCenter ? props.student.secondaryCenter : "",
            school: props.student.school ? props.student.school : "",
            birthday: props.student.birthday.toDate() ? props.student.birthday.toDate() : new Date(),
            status: props.student.status ? true : false,
            activeDate: props.student.activeDate ? props.student.activeDate.toDate() : new Date(),
            inactiveDate: props.student.inactiveDate ? props.student.inactiveDate.toDate() : null,
            packageDescription: props.student.packageDescription ? props.student.packageDescription : null
        }
    } 

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentDidUpdate() {
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }
    
    componentWillUnmount() {
        const wrapper = document.getElementById('details-form');
        if(wrapper) 
            wrapper.classList.add('active');
    }

    handleSubmit = () => {
        let oldStudent = { ...this.props.student };
        let newStudent = { ...this.state };
        let updatedStudent = Object.assign(oldStudent, newStudent);
        const { firstName, lastName, primaryCenter } = updatedStudent;
        if(firstName.length === 0 || lastName.length === 0 || primaryCenter.length === 0){
            this.props.handleOpenPopup("error", "Please fill in required fields!");
        } else {
            this.props.updateStudent(this.props.studentId, updatedStudent);
            this.props.handleOpenPopup("success", `${firstName} ${lastName} is updated!`);
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSwitch = name => event => {
        // if(!event.target.checked){
        //     this.setState({ inactiveDate: new Date() });
        // } else {
        //     this.setState({ inactiveDate: null });
        // }
        this.setState({ [name]: event.target.checked });
    }

    render() {
        return(
            <form className="details-form" id="details-form">
                <div className="fields-container">
                    <TextField
                        className="field"
                        variant="outlined"
                        label="First Name - Required"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange('firstName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Last Name - Required"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange('lastName')}
                        margin="normal"/>
                    <TextField
                        className="field"   
                        variant="outlined"
                        label="Allergies"
                        name="allergies"
                        value={this.state.allergies}
                        onChange={this.handleChange('allergies')}
                        margin="normal"/>
                    <TextField
                        className="field" 
                        variant="outlined"
                        label="School"
                        name="school"
                        value={this.state.school}
                        onChange={this.handleChange('school')}
                        margin="normal"/>
                    <SelectJusanLevel 
                        className="field"
                        name="jusanLevel"
                        value={this.state.jusanLevel} 
                        handleChange={this.handleSelect}/>
                    <SelectAmsanLevel 
                        className="field"
                        name="amsanLevel"
                        value={this.state.amsanLevel} 
                        handleChange={this.handleSelect}/>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <SelectLocation 
                        className="field" 
                        title="Primary Center - Required"
                        name="primaryCenter"
                        value={this.state.primaryCenter} 
                        handleChange={this.handleSelect}/>}
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <SelectLocation
                        className="field"
                        title="Secondary Center"
                        name="secondaryCenter"
                        value={this.state.secondaryCenter} 
                        handleChange={this.handleSelect}/>}
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Credits"
                        name="credits"
                        type="number"
                        value={this.state.credits}
                        onChange={this.handleChange('credits')}
                        margin="normal"/>
                    <CustomSelect 
                        className="field"
                        name="packageDescription"
                        placeholder="Package Type"
                        value={this.state.packageDescription}
                        handleChange={this.handleSelect}>
                        <MenuItem value="Package A">Package A</MenuItem>
                        <MenuItem value="Package B">Package B</MenuItem>
                        <MenuItem value="Package C">Package C</MenuItem>
                    </CustomSelect>
                    <div className="field">
                        <DatePicker 
                            className="field" 
                            name="activeDate"
                            label="Active Date" 
                            dateValue={this.state.activeDate} 
                            handleDateChange={date => this.setState({ activeDate: date })}/>
                        <DatePicker 
                            className="field" 
                            name="inactiveDate"
                            label="Inactive Date" 
                            dateValue={this.state.inactiveDate} 
                            handleDateChange={date => this.setState({ inactiveDate: date })}/>
                    </div>
                    <div className="field">
                        <DatePicker 
                            className="field" 
                            label="Birthday" 
                            dateValue={this.state.birthday} 
                            handleDateChange={date => this.setState({ birthday: date })}/>

                        <div className="field-quarter">
                            <FormControlLabel
                                control={<Switch 
                                            checked={this.state.status} 
                                            onChange={this.handleSwitch('status')} 
                                            value="status" />}
                                label="Is active?"
                                labelPlacement="start"/> 
                        </div>
                    </div>
                </div>
                <div className="form-action-container">
                    <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSubmit}>
                        save
                    </Button>
                </div>
            </form>
                
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.firebase.profile,
        studentError: state.students.studentError
    }
}

export default connect(mapStateToProps, { updateStudent })(StudentDetails);