import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import LinkRenderer from '../shared/LinkRenderer';
import EmailRenderer from './EmailRenderer';
import DeleteTeacherRenderer from './DeleteTeacherRenderer';
import { isLocationMatch, formatPhoneNumber } from '../../js/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format } from 'date-fns';

class TeachersGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Name",
                    field: "name",
                    cellRenderer: 'linkRenderer'
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter"
                }, {
                    headerName: "Secondary Center",
                    field: "secondaryCenter"
                }, {
                    headerName: "Cell Phone",
                    field: "cellPhone"
                }, {
                    headerName: "E-mail",
                    field: "email",
                    cellRenderer: "emailRenderer"
                }, {
                    headerName: "Birthday",
                    field: "birthday"
                }, {
                    headerName: "Role",
                    field: 'role'
                }, {
                    headerName: "Delete",
                    field: 'delete',
                    cellRenderer: 'deleteTeacherRenderer'
                }]
            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                emailRenderer: EmailRenderer,
                deleteTeacherRenderer: DeleteTeacherRenderer,
            },
            paginationPageSize: 20
        }
    }

    componentDidMount() {
        if(this.props.teachers) {
            let rowData = convertDataToRow(this.props.teachers, this.props.locationFilter);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if((prevProps.teachers !== this.props.teachers && this.props.teachers)
        || (this.props.teachers && prevProps.locationFilter !== this.props.locationFilter && this.props.locationFilter)) {
            let rowData = convertDataToRow(this.props.teachers, this.props.locationFilter);
            this.setState({ rowData });
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default TeachersGrid

const convertDataToRow = (data, locationFilter) => {
    let rowData = []
    Object.keys(data).map(key => {
        let row = data[key];
        if(row) {
            let bDate = row.birthday ? format(row.birthday.toDate(), "MMM d, yyyy") : "";
            
            let role = "No role assigned";
            if(row.isAdmin) role = "Admin"
            else if(row.isCenterManager) role = "Center Manager"
            else if(row.isTeacher) role = "Teacher";

            let current = {
                id: key,
                name: row.firstName + ' ' + row.lastName,
                primaryCenter: row.primaryCenter,
                secondaryCenter: row.secondaryCenter,
                cellPhone: formatPhoneNumber(row.phoneNumber),
                email: row.email,
                birthday: bDate,
                role,
                url: "teachers"
            }
            if(isLocationMatch(locationFilter, row.primaryCenter, row.secondaryCenter)){
                rowData.push(current);
            }
        }
    });
    return rowData;
}