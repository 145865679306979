import React, { PureComponent } from 'react';
import * as dateFns from 'date-fns';
import Event from './Event';
import LocationFilter from '../shared/LocationFilter';
import MakeupGrid from './MakeupGrid';
import SlidingPane from '../shared/SlidingPane';
import { connect } from 'react-redux';
import './style/calendar.scss';
import './style/event.scss';
import './style/makeupmodal.scss';

class CalendarMain extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentMonth: new Date(),
            selectedDate: new Date(),
            locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
            openClass: false,
            events: [],
            preview: false,
            showMakeup: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('calendar');
            wrapper.classList.add('active')
        }, 200)
        
        if(window.localStorage.getItem(this.props.profile.firstName)) {
            let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
            this.setState({ locationFilter: settings.locationFilter })
        } else {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.sessions !== this.props.sessions) {
            let events = this.props.sessions.slice().sort((a, b) => {
                if(a.date && b.date) return a.date.toDate() - b.date.toDate()
            });
            this.setState({ events });
        }
        if(!prevProps.profile.primaryCenter && this.props.profile.primaryCenter) {
            this.setState({ locationFilter: this.props.profile.primaryCenter });
        }
        if(prevState.locationFilter !== this.state.locationFilter) {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }
    
    renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className="col col-center">
                    <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon">chevron_right</div>
                </div>
            </div>
        );
    }

    renderEvents = (time) => {
        let events = [];
        let cellDate = dateFns.format(time, "dd LLL yyyy");
        if(this.props.sessions){
            let currentEvents = this.props.sessions.slice()
                .sort((a, b) => {
                    if(a.date && b.date) return a.date.toDate() - b.date.toDate()
                })

            events = currentEvents.map(event => {
                if(event.date) {
                    let eventDate = dateFns.format(event.date.toDate(), "dd LLL yyyy");
                    if(cellDate === eventDate && (this.state.locationFilter.toLowerCase() === "all" || this.state.locationFilter.toLowerCase() === event.location.toLowerCase())) {
                        return <Event toggleMakeup={this.toggleMakeup} key={event.id} sessionId={event.id} handlePreviewEnter={this.handlePreviewEnter} handlePreviewLeave={this.handlePreviewLeave}/>
                    }
                }
            });
        }

        let container = (
            <div className="class-event-wrapper">
                {events}
            </div>
        )
        return container;
    }
    
    renderDays = () => {
        const dateFormat = "EEEE";
        const days = [];
        let startDate = dateFns.startOfWeek(this.state.currentMonth);
    
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
                </div>);
        }
        return <div className="days row">{days}</div>;
    }
    
    renderCells = () => {
        const { currentMonth } = this.state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";
    
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`col cell ${
                            !dateFns.isSameMonth(day, monthStart)
                            ? "disabled"
                            : ""
                        }`}
                        key={day}>
                        { this.renderEvents(cloneDay) }
                        <span className="number">{formattedDate}</span>
                    </div>
                );
            day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    }

    nextMonth = () => {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
        });
    }
    
    prevMonth = () => {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
        });
    }

    handleFilter = e => {
        this.setState({ locationFilter: e.target.value })
    }

    toggleMakeup = () => {
        this.setState(prevState => ({ showMakeup: !prevState.showMakeup }));
    }
    
    render() {
        return (
            <>
                {this.state.showMakeup && <SlidingPane style={{ zIndex: 100000 }}
                    width={70}
                    header="Make up classes"
                    toggleForm={this.toggleMakeup} 
                    showForm={this.state.showMakeup}>
                        <MakeupGrid locationFilter={this.state.locationFilter}  />
                </SlidingPane>}

                <div className={`makeup-modal-container ${this.state.showMakeup && "modal-active"}`} onClick={this.toggleMakeup}>
                    <p className={`makeup-modal-text`}>Make up classes</p>
                </div>

                <div className="calendar" id="calendar">
                    {/* {this.state.preview && <PopoverPreview />} */}
                    <div className="calendar-location-filter-container">
                        <LocationFilter 
                            value={this.state.locationFilter} 
                            handleChange={this.handleFilter}/>
                    </div>
                    {this.renderHeader()}
                    {this.renderDays()}
                    {this.renderCells()}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    return { 
        profile: state.firebase.profile,
        sessions: state.firestore.ordered.sessions,
        students: state.firestore.data.students,
    }
}

export default connect(mapStateToProps)(CalendarMain);