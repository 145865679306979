import React from 'react';
import { Link } from 'react-router-dom';
import './styles/invoice.scss';

const ChildrenRenderer = (props) => {
    let renderLinks = [];
    renderLinks = Array.isArray(props.data.children) ? props.data.children.map((child, i) => {
        if(props.data.students) {
            let student = props.data.students[child];
            if(student) {
                let name = `${student.firstName} ${student.lastName}`;
                return [i > 0 && ", ",<Link key={i} to={'/students/' + child} style={{ color: "#1e90ff" }}>{name}</Link>]
            }
        }
    }) : [];

    return (<>
        {renderLinks}
    </>)
}

export default ChildrenRenderer;