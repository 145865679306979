import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import ParentRenderer from '../invoice/ParentRenderer';
import ChildrenRenderer from '../invoice/ChildrenRenderer';
import CancelSubscriptionRenderer from './CancelSubscriptionRenderer';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import '../invoice/styles/invoice.scss';

class SubscriptionGrid extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [
                {
                    headerName: "Parent",
                    field: "parent", 
                    cellRenderer: 'parentRenderer',
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter", 
                }, {
                    headerName: "Children",
                    field: "children", 
                    cellRenderer: 'childrenRenderer'
                }, {
                    headerName: "Start of Subscription",
                    field: "startDate", 
                }, { 
                    headerName: "Current Bill Date", 
                    field: "currentBill" , 
                }, {
                    headerName: "Next Bill Date",
                    field: "nextBill", 
                }, {
                    headerName: "Status",
                    field: "status", 
                    cellClassRules: {
                        "status-green": (params) => cellStyleRule(params) === 1,
                        "status-red": (params) => cellStyleRule(params) === -1,
                    },
                }, {
                    headerName: "Secondary Center",
                    field: "secondaryCenter", 
                },{
                    headerName: "Cancel Subscription",
                    field: "cancelSubscription", 
                    cellRenderer: 'cancelSubscriptionRenderer',
                }],
            },
            frameworkComponents: {
                parentRenderer: ParentRenderer,
                childrenRenderer: ChildrenRenderer,
                cancelSubscriptionRenderer: CancelSubscriptionRenderer
            },
            rowData: []
        }
    }

    componentDidMount() {
        axios.get("https://us-central1-abakidz-student-application.cloudfunctions.net/getStripeSubscriptions")
        .then(res => {
            let data = res.data.data
            let rowData = convertSubscriptionDataToRow(data, this.props.students)
            this.setState({ rowData });
        })
        .catch(err => this.props.handleOpenPopup('error', err.message));
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if(prevProps.newSubscriber !== this.props.newSubscriber && this.props.newSubscriber) {
            let newSubscriber = convertSubscriptionDataToRow([this.props.newSubscriber], this.props.students)
            this.gridApi.updateRowData({ add: newSubscriber, addIndex: 0 })
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    handleNext = () => {
        if(this.state.rowData.length > 0) {
            let lastSubscriptionId =  this.state.rowData[this.state.rowData.length - 1].id 
            axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getNextStripeSubscription", {
                lastSubscriptionId
            }).then(res => {
                let data = res.data.data
                let rowData = convertSubscriptionDataToRow(data, this.props.students)
                this.setState({ rowData });
            })
            .catch(err => this.props.handleOpenPopup('error', err.message));
        }
    }

    handlePrev = () => {
        if(this.state.rowData.length > 0) {
            let firstSubscriptionId = this.state.rowData[0].id 
            axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getPrevStripeSubscription", {
                firstSubscriptionId
            }).then(res => {
                let data = res.data.data
                let rowData = convertSubscriptionDataToRow(data, this.props.students)
                this.setState({ rowData });
            })
            .catch(err => this.props.handleOpenPopup('error', err.message));
        }
    }   

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    rowData={this.state.rowData}
                    quickFilter={this.props.search}
                    // detailCellRendererParams={this.state.detailCellRendererParams}
                    frameworkComponents={this.state.frameworkComponents}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
                <div className="invoices-pagination-button-container">
                    <button className="pagination-btn" onClick={this.handlePrev}>Previous</button>
                    <button className="pagination-btn" onClick={this.handleNext}>Next</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.data.students
    }
}

export default connect(mapStateToProps)(SubscriptionGrid);

const convertSubscriptionDataToRow = (data, students) => {
    let rowData = data.map((row) => {
        let childrenTemp = row.metadata.children ? row.metadata.children : []
        let children = [];
        let startDate = new Date(row.start_date * 1000)
        let currentBill = new Date(row.current_period_start * 1000)
        let nextBill = new Date(row.current_period_end * 1000)

        currentBill = format(currentBill, "MMM d, yyyy"); 
        startDate = format(startDate, "MMM d, yyyy"); 
        nextBill = format(nextBill, "MMM d, yyyy");

        if(childrenTemp.length > 0) {
            let child = childrenTemp.split('.');
            children = child.map(child => {
                let childProp = child.split(',')
                return {
                    name: childProp[0],
                    id: childProp[1]
                }
            })
        }

        let childrenData = []

        if(row.metadata.children) {
            childrenData = row.metadata.children.split(",");
        }
        
        return {
            id: row.id,
            parent: row.metadata.name ? row.metadata.name : "Unavailable",
            primaryCenter: row.metadata.primaryCenter ? row.metadata.primaryCenter : "",
            children: childrenData,
            startDate: startDate,
            currentBill: currentBill,
            nextBill: nextBill,
            status: row.status,
            secondaryCenter: row.metadata.secondaryCenter ? row.metadata.secondaryCenter : "",
            familyId: row.metadata.familyId ? row.metadata.familyId : "",
            subscriptionId: row.id,
            students
        }
    });
    return rowData;
} 

const cellStyleRule = (params) => {
    if(params.value === "active") return 1;
    else return -1;
}