import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Initialize Firebase - PRODUCTION
var firebaseConfig = {
    apiKey: "AIzaSyBzwLofsNSJmUZG10zkWi8DGrq-sHcnCEQ",
    authDomain: "abakidz-student-application.firebaseapp.com",
    databaseURL: "https://abakidz-student-application.firebaseio.com",
    projectId: "abakidz-student-application",
    storageBucket: "",
    messagingSenderId: "272577866694",
    appId: "1:272577866694:web:fc0ddd7dd6d52684"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;