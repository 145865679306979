const initState = {
    sessionError: null,
}

export const sessionReducer = (state = initState, action) => {
    switch(action.type) {
        case "CREATE_SESSION":
            return {
                ...state,
                session: action.session
            }
        case "CREATE_SESSION_ERROR":
            return {
                ...state,
                sessionError: action.err
            }
        case "UPDATE_SESSIONS":
            return {
                ...state,
                session: action.session
            }
        case "UPDATE_SESSIONS_ERROR": {
            return {
                ...state,
                seesionError: action.err
            }
        }
        case "ADD_STUDENT_TO_SESSION": {
            return {
                ...state,
                session: action.session
            }
        }
        case "ADD_STUDENT_TO_SESSION_ERROR": {
            return {
                ...state,
                sessionError: action.err
            }
        }
        case "REMOVE_STUDENT_FROM_SESSION": {
            return {
                ...state,
                session: action.session
            }
        }
        case "REMOVE_STUDENT_FROM_SESSION_ERROR": {
            return {
                ...state,
                sessionError: action.err
            }
        }
        case "DELETE_SESSION": {
            return {
                ...state,
            }
        }
        case "DELETE_SESSION_ERROR": {
            return {
                ...state,
                sessionError: action.err
            }
        }
        case "UPDATE_SESSION_TEACHER": {
            return {
                ...state,
                teacher: action.teacher
            }
        }
        case "UPDATE_SESSION_TEACHER_ERROR": {
            return {
                ...state,
                sessionError: action.err
            }
        }
        default: return state;
    }
}

export default sessionReducer;