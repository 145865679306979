import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { formatMilitaryTime } from '../../../js/helpers';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import '../styles/mobilesessiondetails.scss';
import { Swipeable } from 'react-swipeable'
import Button from '@material-ui/core/Button';
import MobileSessionStudentItem from './MobileSessionStudentItem';
import TextField from '@material-ui/core/TextField';
import "../styles/mobilesessionstudentitem.scss";
import { updateSession } from '../../../store/actions/sessionActions';

class MobileSessionDetails extends PureComponent {
    state = {
        saveToggle: false,
        comments: "",
        teacher: ""
    }

    componentDidMount() {
        let session = this.props.sessions[this.props.selectedSession]
        if(session) {
            this.setState({ 
                teacher: session.teacher.id, 
                comments: session.comments 
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.sessions !== this.props.sessions && this.props.sessions) {
            let session = this.props.sessions[this.props.selectedSession]
            if(session) {
                this.setState({ 
                    teacher: session.teacher.id,
                    comments: session.comments
                })
            }
        }
    }

    handleSwipeDown = (e) => {
        if(e.dir === "Down") this.props.closeSelectedSession()
    }

    handleSave = () => {
        this.props.updateSession(this.props.selectedSession, this.state.teacher, this.state.comments);
        this.setState(prevState => ({ saveToggle: !prevState.saveToggle }));
        // this.handleOpenPopup("success", `Session updated!`);
        // this.props.closeSelectedSession()
        alert("Saved!")
    }

    handleChange = name => e => {
        this.setState({[name]: e.target.value});
    }

    render() {
        const session = this.props.sessions[this.props.selectedSession];
        let date = "";
        let teacherName = "";
        let location = "";
        let className ="";
        let startTime = "";
        let endTime = "";
        let studentDisplay = [];
        if(session) {
            let teacher = this.props.teachers[session.teacher.id];
            let classObj = this.props.classes[session.classId.id];
            if(teacher) teacherName = `${teacher.firstName} ${teacher.lastName}`;
            if(classObj) className = `${classObj.className}`; 
            date = format(session.date.toDate(), "MMMM d, yyyy");
            location = session.location;
            startTime = session.startTime;
            endTime = session.endTime;
            if(session.attendanceRecords) {
                studentDisplay = session.attendanceRecords.map(recordRef => {
                    let record = this.props.attendanceRecords[recordRef.id];
                    if(record) {
                        let student = this.props.students[record.student.id];
                        if(student) {
                            return <MobileSessionStudentItem 
                                key={recordRef.id}
                                attendanceRecord={record} 
                                attendanceRecords={this.props.attendanceRecords}
                                saveToggle={this.state.saveToggle}
                                student={student}
                                session={session}/>
                        }
                    }
                })
            }
        }

        return (
            <div className="mobile-session-details-container">
                <Swipeable onSwiped={(eventData) => this.handleSwipeDown(eventData)} >
                    <div className="header-container">
                        <div className="info-container">
                            <h1 className="date-text">{date}</h1>
                            <h4 className="info-text">{`${teacherName} | ${className} | ${location} | ${formatMilitaryTime(startTime)} - ${formatMilitaryTime(endTime)}`}</h4>
                        </div>
                        <div className="mobile-session-close-container">
                            <ArrowDownIcon />
                        </div>
                    </div>
                </Swipeable>
                <div className="mobile-session-main-container">
                    { studentDisplay }
                    <TextField
                        id="outlined-multiline-static"
                        label="Class comments"
                        name="comments"
                        multiline
                        style={{width: '100%'}}
                        value={this.state.comments}
                        onChange={this.handleChange("comments")}
                        rows="2"
                        margin="normal"
                        variant="outlined"/>
                </div>
                
                <div className="save-btn-container">
                    <Button color="secondary" variant="contained" size="medium" onClick={this.handleSave}>  
                        Save
                    </Button>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return { 
        sessions: state.firestore.data.sessions,
        attendanceRecords: state.firestore.data.attendanceRecords,
        teachers: state.firestore.data.teachers,
        classes: state.firestore.data.classes,
        students: state.firestore.data.students

    }
}

export default connect(mapStateToProps, { updateSession })(MobileSessionDetails);