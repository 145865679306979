const initState = {
    teacherError: null
}

const teacherReducer = (state = initState, action) => {
    switch(action.type) {
        case "UPDATE_TEACHER": 
            return {
                ...state,
                teacher: action.teacher
            }
        case "UPDATE_TEACHER_ERROR":
            return {
                ...state,
                teacherError: action.err
            }
        default: return state;
    }
}

export default teacherReducer;