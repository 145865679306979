import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore, firestoreReducer } from 'redux-firestore';
import { reactReduxFirebase, getFirebase, firebaseReducer } from 'react-redux-firebase';
import fbConfig from '../../config/fbConfig';

// Reducers
import authReducer from './authReducer';
import classReducer from './classReducer';
import sessionReducer from './sessionReducer';
import familyReducer from './familyReducer';
import studentReducer from './studentReducer';
import teacherReducer from './teacherReducer';
import locationReducer from './locationReducer';
import invoiceReducer from './invoiceReducer';
import stripeReducer from './stripeReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    classes: classReducer,
    sessions: sessionReducer,
    parents: familyReducer,
    students: studentReducer,
    teachers: teacherReducer,
    locations: locationReducer,
    invoices: invoiceReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    stripe: stripeReducer,
});

const configOptions = {
    useFirestoreForProfile: true, 
    userProfile: 'teachers', 
    attachAuthIsReady: true,
    // enableClaims: true
}

export default createStore(rootReducer, 
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reactReduxFirebase(fbConfig, configOptions), // redux binding for firebase
        reduxFirestore(fbConfig), // redux binding for firestore
    )
);