import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import Button from '@material-ui/core/Button';
import SlidingPane from '../shared/SlidingPane'
import SelectStudent from '../shared/SelectStudent';
import DatePicker from '../shared/DatePicker';
import './style/parent.scss';
import format from 'date-fns/format'

import { connect } from 'react-redux';
import { updateFamily } from '../../store/actions/parentActions';
import DeleteReferralCellRenderer from './DeleteReferralCellRenderer';

class ReferallGrid extends Component {
    constructor() {
        super();
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Referrals",
                    field: "studentsName",
                    // width: 120
                }, {
                    headerName: "Parent",
                    field: "parentsName"
                }, {
                    headerName: "Start Date",
                    field: 'startDate'
                }, {
                    headerName: "Is Used",
                    field: 'isUsed',
                    editable: true,
                    // cellEditor: "agRichSelectCellEditor",
                    cellEditorParams: {
                        values: [true, false]
                    }
                }, {
                    headerName: "Delete",
                    field: "delete",
                    cellRenderer: 'deleteReferralCellRenderer'
                }]
            },
            frameworkComponents: {
                deleteReferralCellRenderer: DeleteReferralCellRenderer
            },
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            referrals: [],
            showForm: false,
            studentId: null,
            date: new Date()
        }
    }

    componentDidMount() {
        if(this.props.students && this.props.families) {
            this.init()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.students && this.props.families && prevProps.parent !== this.props.parent) {
            this.init()
        }
    }

    init = () => {
        let rowData = [];
        let referrals = []

        if(this.props.students && this.props.parent.referrals) {
            this.props.parent.referrals.forEach((referral, i) => {
                let student = this.props.students[referral.studentId]
                let parent = this.props.families[student.familyId.id]
                
                let row = {
                    i: i,
                    studentsName: `${student.firstName} ${student.lastName}`,
                    parentsName: `${parent.parentOneFirstName} ${parent.parentOneLastName}`,
                    startDate: format(referral.startDate.toDate(), "MMM d, yyyy"),
                    isUsed: referral.isUsed,
                    delete: this.handleDelete
                }

                let ref = {
                    studentId: referral.studentId,
                    startDate: referral.startDate
                }

                referrals.push(ref);
                rowData.push(row)
            })
        }
        this.setState({ rowData, referrals })
    }

    handleDelete = (i) => {
        let temp = this.state.referrals.slice();
        temp.splice(i, 1)
        let updatedFamily = {
            referrals: temp
        }
        this.props.updateFamily(this.props.parentId, updatedFamily)
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }


    handleDateChange = date => {
        this.setState({ date: date });
    }

    handleSelect = event => {
        this.setState({ studentId: event.target.value });
    }

    handleAddReferral = () => {
        if(this.state.studentId && this.state.date) {
            let referral = {
                studentId: this.state.studentId,
                startDate: this.state.date,
                isUsed: false
            }
            let updatedFamily = {
                referrals: [...this.state.referrals, referral]
            }

            this.props.updateFamily(this.props.parentId, updatedFamily)
            this.toggleForm();
            // console.log(updatedFamily)
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        const {
            gridOptions,
            rowData,
            frameworkComponents,
            defaultColDef
        } = this.state;

        return (
            <div 
                className="ag-theme-material" 
                style={{ height: '50vh', width: '100%'}}>
                {this.props.showAdd && 
                <>
                    <SlidingPane 
                        header="Add a referral" 
                        width={30}
                        toggleForm={this.toggleForm} 
                        showForm={this.state.showForm}>
                        {
                            this.state.showForm && 
                            <div className="referral-form-container">
                                <SelectStudent 
                                    className="field" 
                                    name="studentId"
                                    handleChange={this.handleSelect}
                                    value={this.state.studentId}
                                />

                                <DatePicker 
                                    variant="inline"
                                    label="Start Date*" 
                                    dateValue={this.state.date} 
                                    handleDateChange={this.handleDateChange}/>
                                <Button
                                    style={{ marginTop: 30 }}
                                    variant="contained" 
                                    color="secondary"
                                    onClick={this.handleAddReferral}>
                                    Save
                                </Button>
                            </div>
                        }
                    </SlidingPane>
                    <Button 
                        variant="contained" 
                        color="secondary"
                        onClick={this.toggleForm}
                        style={{ marginBottom: '1rem' }}>
                        Add Referral
                    </Button>
                </>
                }
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={gridOptions}
                    rowData={rowData}
                    frameworkComponents={frameworkComponents}
                    defaultColDef={defaultColDef}
                    domLayout='autoHeight'>
                </AgGridReact>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.data.students,
        families: state.firestore.data.families
    }
}

export default connect(mapStateToProps, { updateFamily })(ReferallGrid);