import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const CustomSelect = (props) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const formControl = {
        minWidth: props.width ? props.width : 120,
        marginTop: props.margin === "dense" ? ".5rem" : " 1rem",
        marginBottom: '.5rem'
    }

    return (
        <FormControl variant="outlined" className={props.className} style={formControl}>
            <InputLabel style={props.style} ref={inputLabel}>{props.placeholder}</InputLabel>
            <Select
                style={props.style}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
                margin={props.margin}
                input={<OutlinedInput labelWidth={labelWidth} />}>
                {props.children}
            </Select>
        </FormControl>
    );
}

export default CustomSelect;