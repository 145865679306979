import React from 'react';
import { Route } from 'react-router-dom';
import logo from '../../resources/images/abakidz-logo.png';
import './style/auth.scss';

// Components
import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';

const Landing = () => {
    return (
        <div className="auth-container">
            <div className="auth-logo-container">
                <img className="auth-logo" src={logo} alt="abakidz tactile brain logo"/>
            </div>
            <div className="auth-component-container">
                <Route exact path="/" component={ Login } />
                <Route exact path="/signup" component={ SignUp } />
                <Route exact path="/forgot-password" component={ ForgotPassword } />
            </div>
        </div>
    );
}

export default Landing;