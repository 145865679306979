import React from 'react';
import Button from '@material-ui/core/Button';
import './style/auth.scss';
import logo from '../../resources/images/abakidz-logo.png';
import { connect } from 'react-redux'; 
import { logout } from '../../store/actions/authActions';

const Approval = (props) => {
    return (
        <div className="auth-container">
            <div className="auth-logo-container">
                <img className="auth-logo" src={logo} alt="abakidz tactile brain logo"/>
            </div>
            <div className="auth-component-container">
                <div className="approval-wrapper">
                    <h1 className="header">{`Hi ${props.profile.firstName}, thanks for signing up!`}</h1>
                    <h1 className="header">Please contact your administrator for account approval.</h1>
                    <Button
                        className="login-btn"
                        variant="contained" 
                        onClick={props.logout}
                        color="secondary">
                            Login
                    </Button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

export default connect(mapStateToProps, { logout })(Approval);