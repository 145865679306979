import React, { PureComponent } from 'react';
import Alert from '../shared/Alert';
import './styles/cellRenderers.scss';
import axios from 'axios';

class ChangeInvoiceStatusRenderer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            openVoid: false,
            openPaid: false,
            messageAlert: "Are you sure you want to mark invoice as 'paid'?"
        }
    }

    togglePaid = () => {
        this.setState(prevState => ({ openPaid: !prevState.openPaid }));
    }

    toggleVoid = () => {
        this.setState(prevState => ({ openVoid: !prevState.openVoid }));
    }

    handleChange = event => {
        if(event.target.value === "paid") {
            this.togglePaid()
        } else if(event.target.value === "void") {
            this.toggleVoid()
        }
    }

    acceptPaid = () => {
        axios
            .post("https://us-central1-abakidz-student-application.cloudfunctions.net/payStripeInvoiceOutOfBand", 
                { 
                    invoiceId: this.props.data.invoiceId 
                })
            .then(res => {
                console.log(res)
                let rowNode = this.props.node;
                rowNode.setDataValue("status", "paid")
            })
            .catch(err => console.log(err))
    }

    acceptVoid = () => {
        axios
            .post("https://us-central1-abakidz-student-application.cloudfunctions.net/voidStripeInvoice", 
                { 
                    invoiceId: this.props.data.invoiceId 
                })
            .then(res => {
                let rowNode = this.props.node;
                rowNode.setDataValue("status", "void")
            })
            .catch(err => console.log(err))
    }

    render() {
        return (
            <>
                <select
                    name="value"
                    value={this.state.value} 
                    placeholder="Status"
                    disabled={this.state.value === "paid" || this.state.value === "void" || this.state.value === "draft"}
                    onChange={this.handleChange}>
                    <option value={"open"}>Open</option>
                    <option value={"paid"}>Paid</option>
                    <option value={"void"}>Void</option>
                </select>
                <Alert 
                    action={this.acceptPaid} 
                    toggleAlert={this.togglePaid} 
                    openAlert={this.state.openPaid} 
                    buttonName="Accept"
                    title={"Are you sure you want to mark invoice as 'paid'?"} 
                    message={`NOTE: This action can't be undone.`}/>
                <Alert 
                    action={this.acceptVoid} 
                    toggleAlert={this.toggleVoid} 
                    openAlert={this.state.openVoid} 
                    buttonName="Accept"
                    title={"Are you sure you want to void this invoice?"} 
                    message={`NOTE: This action can't be undone.`}/>
            </>
        )
    }
}

export default ChangeInvoiceStatusRenderer;