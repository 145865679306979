import React, { PureComponent } from 'react';
import SlidingPane from '../shared/SlidingPane';
import LocationFilter from '../shared/LocationFilter';
import Searchbar from '../shared/Searchbar';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { connect } from 'react-redux';
import InvoiceForm from './InvoiceForm';
import InvoiceGrid from './InvoiceGrid';
import './styles/invoice.scss';
import { getAllStripePlans } from '../../store/actions/stripeActions';

class Invoice extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            // locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
            search: "",
            alert: {
                variant: "success",
                open: false,
                message: ""
            },
            newInvoice: null
        }
    }

    componentDidMount() {
        if(!this.props.stripePlans) {
            this.props.getAllStripePlans();
        }

        // if(window.localStorage.getItem(this.props.profile.firstName)) {
        //     let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
        //     this.setState({ locationFilter: settings.locationFilter })
        // } else {
        //     let settings = {
        //         locationFilter: this.state.locationFilter
        //     }
        //     window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        // if(!prevProps.profile.primaryCenter && this.props.profile.primaryCenter) {
        //     this.setState({ locationFilter: this.props.profile.primaryCenter });
        // }
        // if(prevState.locationFilter !== this.state.locationFilter) {
        //     let settings = {
        //         locationFilter: this.state.locationFilter
        //     }
        //     window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        // }
    }


    // handleFilter = e => {
    //     this.setState({ locationFilter: e.target.value });
    // }

    handleChange = e => {
        this.setState({ search: e.target.value.toLowerCase() });
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    handleNewInvoice = (newInvoice) => {
        this.setState({ newInvoice })
    }

    render() {
        return (
            <div className="tab-container">
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) &&
                    <SlidingPane header="Create an invoice" toggleForm={this.toggleForm} showForm={this.state.showForm} width={80}>
                        {/* <AddClassForm toggleForm={this.toggleForm} handleOpenPopup={this.handleOpenPopup}/> */}
                        {this.state.showForm && <InvoiceForm 
                                                    toggleForm={this.toggleForm} 
                                                    showForm={this.state.showForm} 
                                                    handleOpenPopup={this.handleOpenPopup}
                                                    handleNewInvoice={this.handleNewInvoice}/>}
                    </SlidingPane>
                }
                
                <div className="list-actions-container">
                    {/* <LocationFilter 
                        value={this.state.locationFilter}
                        handleChange={this.handleFilter} /> */}
                    <Searchbar 
                        search={this.state.search}
                        handleChange={this.handleChange}/>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                    <>
                        <div className="action-container">
                            <h4 style={{marginRight: '1rem'}}>Issue</h4>
                            <Fab size="small" color="secondary" aria-label="add" onClick={this.toggleForm}>
                                <AddIcon/> 
                            </Fab>
                        </div>
                        <div className="action-container">
                            <h4 style={{marginRight: '1rem'}}>Create invoice</h4>
                            <Fab size="small" color="secondary" aria-label="add" onClick={this.toggleForm}>
                                <AddIcon/> 
                            </Fab>
                        </div>
                    </>
                }
                </div>
                <div className="invoice-grid-container">
                    <InvoiceGrid 
                        handleOpenPopup={this.handleOpenPopup} 
                        search={this.state.search} 
                        newInvoice={this.state.newInvoice}
                        locationFilter={this.state.locationFilter}/>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
    }
}

export default connect(mapStateToProps, { getAllStripePlans})(Invoice);