import React from 'react';
import { Link } from 'react-router-dom';
import './styles/invoice.scss';

const ParentRenderer = (props) => {
    return (
        <Link to={'/parents/' + props.data.familyId} style={{ color: "#1e90ff"}}>{props.value}</Link>
    )
}

export default ParentRenderer;