import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../shared/DatePicker';
import { connect } from 'react-redux';
import { createSessions } from '../../store/actions/sessionActions';
import addWeeks from 'date-fns/addWeeks';

class AddSessionForm extends PureComponent {
    state = {
        date: new Date(),
        startTime: "15:00",
        endTime: "16:00",
        numOfSessions: 1
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleDateChange = date => {
        this.setState({  date });
    }

    handleSave = () => {
        let num = Number(this.state.numOfSessions);
        let { date, startTime, endTime } = this.state;

        if(num > 10){
            this.props.handleOpenPopup("error", "Exceeding maximum instances of sessions! (Max: 10 sessions)");
        } else if( !date || !startTime || !endTime || startTime.length !== 5 || endTime.length !== 5){
            this.props.handleOpenPopup("error", "Please fill complete all fields or reset the times!");
        } else {
            for(let i = 0; i < num; i++){
                let result = addWeeks(this.state.date, i);
                this.props.createSessions(this.props.classId, this.props.classObj, this.state, result);
            }
            this.setState({
                date: new Date(),
                startTime: "15:00",
                endTime: "16:00",
                numOfSessions: 1,
            });
            this.props.handleOpenPopup("success", "New session(s) added!");
            this.props.toggleForm();
        }
    }

    handleTime = event => {
        this.setState({ [event.target.name]: event.target.value});
    }

    render() {
        // console.log(this.state)
        return (
            <form className="details-form">
                <div className="fields-container">
                    <DatePicker 
                        // className="field" 
                        label="Date" 
                        dateValue={this.state.date} 
                        handleDateChange={this.handleDateChange} />  
                    <TextField
                        name="startTime"
                        id="time"
                        label="Start time"
                        type="time"
                        defaultValue="15:00"
                        className="field"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                        onChange={this.handleTime} />
                    <TextField
                        name="endTime"
                        id="time"
                        label="End time"
                        type="time"
                        defaultValue="16:00"
                        className="field"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }} 
                        onChange={this.handleTime}/>
                    <TextField
                        id="standard-number"
                        label="Number of sessions"
                        value={this.state.numOfSessions}
                        onChange={this.handleChange('numOfSessions')}
                        type="number"
                        className="field"
                        InputLabelProps={{ shrink: true }}
                        margin="normal" />
                </div>
                <div className="action-container">
                    <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSave}>
                        Save
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect(null, { createSessions })(AddSessionForm);
