import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const MobileLink = (props) => {
    return (
        <NavLink exact={ true } className="mobile-link-container" activeClassName="active" to={props.link} >
            <div className="mobile-link-icon">
                <img className="mobile-link-icon-img" src={props.icon} alt={props.icon}/>
            </div>
            <div className="mobile-link-content">
                <h5 className="link-text">{props.content}</h5>
            </div>
        </NavLink>
    )
}

export default withRouter(MobileLink);