import React, { PureComponent } from 'react';
import ParentsChildrenGrid from './ParentsChildrenGrid';
import ParentsChildrenHeader from './ParentsChildrenHeader';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './style/parent.scss';

class ParentsChildren extends PureComponent {
    constructor() {
        super();
        this.state = {
            children: []
        }
    }

    componentDidMount() {
        console.log()
        setTimeout(() => {
            const wrapper = document.getElementById('sub-list-container');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentDidUpdate(prevProps, prevState) {
        setTimeout(() => {
            const wrapper = document.getElementById('sub-list-container');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('sub-list-container');
        if(wrapper) wrapper.classList.remove('active')
    }

    render() {
        return (
            <div className="sub-list-container" id="sub-list-container">
                <ParentsChildrenHeader parentId={this.props.parentId} handleOpenPopup={this.props.handleOpenPopup}/>
                <div className="parents-children-grid-container">
                    <ParentsChildrenGrid 
                        parent={this.props.parent}
                        profile={this.props.profile}
                        students={this.props.students}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const id = props.parentId;
    const parents = state.firestore.data.families;
    const parent = parents ? parents[id] : {};
    return { 
        students: state.firestore.data.students,
        profile: state.firebase.profile,
        parent 
    };
}

export default compose(
    connect(mapStateToProps),
    // firestoreConnect([
    //     { collection: "families" }
    // ])
)(ParentsChildren);