import React, { Component } from 'react';
import './styles/subscriptionform.scss'

class SubscriptionItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            package: props.values.package,
            description: "",
            child: "",
            quantity: props.values.quantity,
            unitPrice: props.values.unitPrice,
            discount: props.values.discount,
            amount: props.values.amount,
            packageType: ""
        }
    }
    
    componentDidMount() {
 
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.package !== this.state.package) {
            console.log(this.state)
           
            let plan = JSON.parse(this.state.package)
            const { id, amount, interval_count } = plan
            let description = plan.metadata.description ? plan.metadata.description : "";
            let student = this.props.students[this.state.child];
            if(student) { description = `${student.firstName} ${student.lastName} - ${description}`;}
            this.setState({
                unitPrice: (amount / interval_count) / 100,
                amount: (amount * this.state.quantity) / 100,
                description,
                quantity: interval_count,
                packageType: plan.metadata.description,
                plan: plan.id
            })
            this.props.getItemAmount((amount * this.state.quantity) / 100, this.props.i)
            this.props.handleDescription(this.state.description, plan.nickname, plan.id, this.props.i);
        }
    }

    handleChange = event => {
        if(event.target.name === "discount" && event.target.value >= 0 && event.target.value <= 100) {
            this.setState({ discount: event.target.value });
            
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
        this.props.handleChangeItem(event, this.props.i)
    }

    render() {
        return (
            <tr className="table-row">
                <td>
                    <div className="select-container">
                        <select 
                            className="select" 
                            value={this.state.child}
                            name="child"
                            onChange={this.handleChange}>
                            <option value={null}></option>
                            {this.props.children && this.props.children.map(key => {
                                let student = this.props.students[key];
                                let name = student.firstName + " " + student.lastName; 
                            return <option key={key} value={key}>{name}</option>
                            })}
                        </select>
                    </div>
                </td>
                <td>
                    <div className="select-container">
                        <select 
                            className="select" 
                            value={this.state.package}
                            name="package"
                            onChange={this.handleChange}>
                            <option value={null}></option>
                            {this.props.stripePlans.map((plan, i) => {
                                if(this.props.plan === "monthly" && plan.interval === "month") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                                // else if(this.props.plan === "3-month" && plan.interval_count === 3 && plan.interval === "month") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                                // else if(this.props.plan === "6-month" && plan.interval_count === 6 && plan.interval === "month") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                                else if(this.props.plan === "12-month" && plan.interval === "year") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                            }).sort((a, b) => a.nickname - b.nickname)}
                        </select>
                    </div>
                </td>
                <td>
                    <input
                        className="input-wrapper" 
                        type="number"
                        name="quantity" 
                        value={this.state.quantity}
                        placeholder="quantity"
                        onChange={this.handleChange}/>
                </td>
                <td>
                    <div className="input-wrapper">$
                    <input
                        className="input" 
                        type="number" 
                        name="unitPrice" 
                        value={this.state.unitPrice.toFixed(2)}
                        placeholder="unitPrice"
                        onChange={this.handleChange}/>
                    </div>
                </td>
                <td>
                    <div className="discount-wrapper">
                    <input
                        className="input" 
                        type="number" 
                        name="discount" 
                        value={this.state.discount}
                        placeholder="discount"
                        onChange={this.handleChange}/>%
                    </div>
                </td>
                <td>
                    <div className="input-wrapper">$
                    <input
                        className="input" 
                        type="number"
                        name="amount" 
                        value={this.state.amount.toFixed(2)}
                        placeholder="amount"
                        onChange={this.handleChange}/>
                    </div>
                </td>
                <td>
                <button className="minus-btn" onClick={e => this.props.handleDeleteItem(e, this.props.i)}>
                    -
                </button>
                </td>
            </tr>
        )
    }
}

export default SubscriptionItem;