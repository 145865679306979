import React, { PureComponent } from 'react';

import { Route, NavLink, Redirect, Switch } from 'react-router-dom';
import Loading from '../shared/Loading';

import ParentDetails from './ParentDetails';
import ParentsChildren from './ParentsChildren';
import PaymentHistory from './PaymentHistory';
import ReferralGrid from './ReferralGrid';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';


class Parent extends PureComponent {
    state = {
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentDidUpdate() {
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('content-container');
        if(wrapper) wrapper.classList.remove('active')
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        const { parent, profile } = this.props;
        const { params, url } =  this.props.match;

        if(!parent) {
            return <Loading />;
        };

        return (
            <div className="content-container" id="content-container">
                <header className="header">
                    <h1>{parent.parentOneFirstName} {parent.parentOneLastName}</h1>
                    <div className="navbar-container">
                        <NavLink activeClassName="nested-active-route" to={`${url}/account`}>Account</NavLink>
                        <NavLink activeClassName="nested-active-route" to={`${url}/children`}>Children</NavLink>
                        {(profile.isAdmin || profile.isCenterManager) && <NavLink activeClassName="nested-active-route" to={`${url}/payment`}>Payment History</NavLink>}
                        <NavLink activeClassName="nested-active-route" to={`${url}/referrals`}>Referrals</NavLink>
                    </div>
                </header>

                <div className="main-content-container">
                    <Switch>
                        <Redirect exact from={`${url}`} to={`${url}/account`}/>
                        <Route exact path={`${url}/account`} render={props => <ParentDetails {...props} parentId={params.id} parent={parent} handleOpenPopup={this.handleOpenPopup}/>}/>
                        <Route exact path={`${url}/children`} render={props => <ParentsChildren {...props} parentId={params.id} handleOpenPopup={this.handleOpenPopup}/>}/>
                        <Route exact path={`${url}/referrals`} render={props => <ReferralGrid {...props} showAdd={true} parentId={params.id} parent={parent} handleOpenPopup={this.handleOpenPopup}/>}/>
                        {(profile.isAdmin || profile.isCenterManager) && <Route exact path={`${url}/payment`} render={props => <PaymentHistory {...props} parentId={params.id} parent={parent}/>}/>}
                    </Switch>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const id = props.match.params.id
    const parents = state.firestore.data.families;
    const parent = parents ? parents[id] : null;
    
    return { 
        parent,
        profile: state.firebase.profile 
    }
}

export default compose(
    firestoreConnect([
        { collection: 'families' },
        { collection: 'students' }
    ]),
    connect(mapStateToProps),
)(Parent);