import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import LinkRenderer from '../shared/LinkRenderer';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format } from 'date-fns';
import DeleteStudentRenderer from '../students/DeleteStudentRenderer';

class ParentsChildrenGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Name",
                    field: "name",
                    cellRenderer: 'linkRenderer'
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter"
                }, {
                    headerName: "Jusan Level",
                    field: "jusanLevel",
                }, {
                    headerName: "Amsan Level",
                    field: "amsanLevel",
                }, {
                    headerName: "Secondary Center",
                    field: "secondaryCenter"
                }, {
                    headerName: "Allergies",
                    field: "allergies"
                }, {
                    headerName: "Birthday",
                    field: "birthday"
                }, {
                    headerName: "Credits",
                    field: "credits"
                }, {
                    headerName: "Active",
                    field: "active",
                    cellClassRules: {
                        "status-green": params => cellStyleRule(params) === 1,
                        "status-red": params => cellStyleRule(params) === 0,
                    }
                }, {
                    headerName: "Delete",
                    field: 'delete',
                    cellRenderer: 'deleteStudentRenderer'
                }]
            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                // parentLinkRenderer: ParentLinkRenderer,
                deleteStudentRenderer: DeleteStudentRenderer
            },
            paginationPageSize: 20
        }
    }

    componentDidMount() {
        if(this.props.students) {
            let rowData = convertDataToRow(this.props.parent, this.props.students);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if(prevProps.parent !== this.props.parent && this.props.students) {
            let rowData = convertDataToRow(this.props.parent, this.props.students);
            this.setState({ rowData });
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if(this.props.profile.isTeacher) {
            this.state.gridOptions.columnApi.setColumnVisible('delete', false)
        }
    };

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default ParentsChildrenGrid;

const convertDataToRow = (parent, students) => {
    let rowData = []

    parent.students.forEach(studentRef => {
        let row = students[studentRef.id];

        if(row) {
            let bDate = row.birthday ? format(row.birthday.toDate(), "MMM d, yyyy") : "";

            let current = {
                id: studentRef.id,
                name: `${row.firstName} ${row.lastName}`,
                primaryCenter: row.primaryCenter,
                secondaryCenter: row.secondaryCenter,
                jusanLevel: row.jusanLevel,
                amsanLevel: row.amsanLevel,
                allergies: row.allergies,
                birthday: bDate,
                credits: row.credits,
                active: row.status,
                url: "students",
                familyRef: row.familyId
            }
            rowData.push(current);
        }
    })
    return rowData;
}

const cellStyleRule = (params) => {
    if(params.value) return 1;
    else if(!params.value) return 0;
    else return -1;
}