import React, { Component } from 'react';

class HandlePayrollStatusRenderer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isTeacherPaid: props.data.isPaid
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.props.data.togglePaid(this.props.data.sessionId, { isTeacherPaid: e.target.value })
    }

    render() {
        return (
            <div>
                <select 
                    name="isTeacherPaid"
                    value={this.state.isTeacherPaid}
                    placeholder="Status"
                    onChange={this.handleChange}>
                    <option value={true}>Paid</option>
                    <option value={false}>Open</option>
                </select>
            </div>
        )
    }
}

export default HandlePayrollStatusRenderer;