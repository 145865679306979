import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import CustomSelect from '../shared/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';
import SelectLocation from '../shared/SelectLocation';
import TextField from '@material-ui/core/TextField';
import SelectStudents from '../shared/SelectStudents';
import SelectTeacher from '../shared/SelectTeacher';
import SelectSubject from '../shared/SelectSubject';
import SelectClassType from '../shared/SelectClassType';

import { connect } from 'react-redux';
import { createClass } from '../../store/actions/classActions';
import { colors } from '../../js/helpers';

class AddClassForm extends PureComponent {
    state = {
        className: "",
        location: "",
        selectedStudents: [],
        teacher: "",
        subject: "",
        classType: "",
        color: "",
        address: ""
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleStudents = event => {
        this.setState({ selectedStudents: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSave = () => {
        const { className, location, teacher, subject, classType, color } = this.state;
        
        if(className.length === 0 || location.length === 0 || teacher.length === 0 
        || subject.length === 0 || classType.length === 0 || color.length === 0){
            this.props.handleOpenPopup("error", "Please fill in required fields!");
        } else {
            let newClass = Object.assign({}, this.state);
            this.props.createClass(newClass);
            this.props.toggleForm();
            this.props.handleOpenPopup("success", `${className} class added!`)
            this.setState({
                className: "",
                location: "",
                selectedStudents: [],
                teacher: "",
                subject: "",
                classType: "",
                color: "",
                address: ""
            });
        }
    }

    render() {
        console.log(this.state.location)
        return (
            <form className="details-form">
                <div className="fields-container">
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Class Name*"
                        name="className"
                        value={this.state.className}
                        onChange={this.handleChange('className')}
                        margin="normal"/>
                    <SelectSubject 
                        className="field"
                        value={this.state.subject}
                        handleChange={this.handleSelect}/>
                    <SelectClassType 
                        className="field"
                        value={this.state.classType}
                        handleChange={this.handleSelect}/>
                    <SelectLocation 
                        className="field" 
                        title="Select Location*"
                        name="location"
                        value={this.state.location} 
                        handleChange={this.handleSelect}/>  
                    <CustomSelect 
                        className="field"
                        name="color"
                        placeholder="Color*"
                        value={this.state.color}
                        handleChange={this.handleSelect}>
                            {colors.map((color) => {
                                return (
                                    <MenuItem key={color.color} value={color.color}>
                                        <div className="option-container">
                                            <div className="option-color" style={{ backgroundColor: color.color }}></div>
                                            <p>{color.name}</p>
                                        </div>
                                    </MenuItem>
                                )
                            })}
                    </CustomSelect>
                    {this.state.location === "Private" && <TextField
                        className="field"
                        variant="outlined"
                        label="Address"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleChange('address')}
                        margin="normal"/>}
                    <SelectTeacher 
                        className="field"
                        handleChange={this.handleSelect}
                        value={this.state.teacher}/>
                    <SelectStudents 
                        className="field"
                        location={this.state.location}
                        selectedStudents={this.state.selectedStudents} 
                        handleStudents={this.handleStudents}/>
                </div>
                <div className="action-container">
                    <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSave}>
                        save
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect(null, { createClass })(AddClassForm);