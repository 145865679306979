import React, { PureComponent } from 'react';
import cancelIcon from '../../resources/images/cancel.png';
import Alert from '../shared/Alert';
import { connect } from 'react-redux';
import { deleteTeacher } from '../../store/actions/teacherActions';
import '../subscriptions/styles/subscriptions.scss';

class DeleteTeacherRenderer extends PureComponent {
    state = {
        openAlert: false,
    }

    toggleAlert = () => {
        this.setState(prevState => ({ openAlert: !prevState.openAlert }));
    }

    toggleAccept = () => {
        this.props.api.updateRowData({ remove: [this.props.data] })
        this.props.deleteTeacher(this.props.data.id);
        alert(`${this.props.data.name} deleted!`);
    }
  
    render() {
        return (
            <>
                <button className="cancel-subscription-button" onClick={this.toggleAlert}>
                    <img className="cancel-icon" src={cancelIcon} alt=""/>
                </button>
                <Alert 
                    action={this.toggleAccept} 
                    toggleAlert={this.toggleAlert} 
                    openAlert={this.state.openAlert} 
                    title={`Are you sure you want to delete the ${this.props.data.name}?`} 
                    message={`NOTE: This action can't be undone.`}/>
            </>
        )
    }
}

export default connect(null, { deleteTeacher })(DeleteTeacherRenderer);