import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from 'react-redux';
import axios from 'axios';
import PayInvoiceRenderer from './PayInvoiceRenderer';
import InvoiceActionsRenderer from './InvoiceActionsRenderer';
import ChangeInvoiceStatusRenderer from './ChangeInvoiceStatusRenderer';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles/invoice.scss';
import { format, addDays, addMonth } from 'date-fns';

class InvoiceGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Reference",
                    field: "reference", 
                    cellRenderer: 'payInvoiceRenderer',
                    width: 150
                }, {
                    headerName: "Date",
                    field: "date", 
                    width: 100
                }, {
                    headerName: "Parent",
                    field: "parent", 
                    width: 150
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter", 
                    width: 150
                }, {
                    headerName: "Status",
                    field: "status", 
                    cellClassRules: {
                        "status-grey": (params) => cellStyleRule(params) === 2,
                        "status-green": (params) => cellStyleRule(params) === 1,
                        "status-yellow": (params) => cellStyleRule(params) === 0,
                        "status-red": (params) => cellStyleRule(params) === -1,
                    },
                    width: 100
                }, {
                    headerName: "Total",
                    field: "total", 
                    width: 100
                }, {
                    headerName: "Actions",
                    field: "status",
                    cellRenderer: 'invoiceActionsRenderer',
                    width: 150
                }, {
                    headerName: "Change Status",
                    field: "status",
                    cellRenderer: 'changeInvoiceStatusRenderer',
                    width: 100
                }],
            },
            frameworkComponents: {
                payInvoiceRenderer: PayInvoiceRenderer,
                changeInvoiceStatusRenderer: ChangeInvoiceStatusRenderer,
                invoiceActionsRenderer: InvoiceActionsRenderer
            },
            rowData: [],
            paginationPageSize: 20,
        }
    }

    componentDidMount() {
        axios.get("https://us-central1-abakidz-student-application.cloudfunctions.net/getStripeInvoices").then(res => {
            let data = res.data.data;
            let rowData = convertInvoiceDateToRow(data, this.props.students, this.onRemoveInvoice, this.onUpdateInvoice, this.props.toggleEditForm)
            this.setState({ rowData });
        })
        
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }
        if(prevProps.newInvoice !== this.props.newInvoice && this.props.students) {
            this.onAddInvoice(this.props.newInvoice)
        }

        if(prevProps.updatedInvoice !== this.props.updatedInvoice && this.props.students) {
            this.onUpdateInvoice()
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    handleNext = () => {
        if(this.state.rowData.length > 0) {
            let lastInvoiceId =  this.state.rowData[this.state.rowData.length - 1].invoiceId
            axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getNextStripeInvoices", {
                lastInvoiceId
            }).then(res => {
                let data = res.data.data
                let rowData = convertInvoiceDateToRow(data, this.props.students, this.onRemoveInvoice, this.onUpdateInvoice, this.props.toggleEditForm)
                this.setState({ rowData });
            })
            .catch(err => this.props.handleOpenPopup('error', err.message));
        }
    }

    handlePrev = () => {
        if(this.state.rowData.length > 0) {
            let firstInvoiceId = this.state.rowData[0].invoiceId
            axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getPrevStripeInvoices", {
                firstInvoiceId
            }).then(res => {
                let data = res.data.data
                let rowData = convertInvoiceDateToRow(data, this.props.students, this.onRemoveInvoice, this.onUpdateInvoice, this.props.toggleEditForm)
                this.setState({ rowData });
            })
            .catch(err => this.props.handleOpenPopup('error', err.message));
        }
    }   

    onAddInvoice = (data) => {
        let invoice = convertInvoiceDateToRow([data], this.props.students, this.onRemoveInvoice, this.onUpdateInvoice, this.props.toggleEditForm);
        this.gridApi.updateRowData({ add: invoice, addIndex: 0 });
    }

    onUpdateInvoice = () => {
        let { editableInvoice, updatedInvoice } = this.props;
        let invoice = convertInvoiceDateToRow([updatedInvoice], this.props.students, this.onRemoveInvoice, this.onUpdateInvoice, this.props.toggleEditForm);
        let data = Object.assign(editableInvoice, invoice[0])
        this.gridApi.updateRowData({ update: [data] })
    }

    onRemoveInvoice = (data) => {
        this.gridApi.updateRowData({ remove: [data] })
    }

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '95%', width: '100%' }}>
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    rowData={this.state.rowData}
                    quickFilter={this.props.search || this.props.locationFilter}
                    frameworkComponents={this.state.frameworkComponents}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
                <div className="invoices-pagination-button-container">
                    <button className="pagination-btn" onClick={this.handlePrev}>Previous</button>
                    <button className="pagination-btn" onClick={this.handleNext}>Next</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.data.students
    }
}

export default connect(mapStateToProps, {})(InvoiceGrid);

const convertInvoiceDateToRow = (data, students, onRemoveInvoice, onUpdateInvoice, toggleEditForm) => {
    let rowData = []
    data.forEach((row) => {
        if(row) {
            let date = format(new Date(row.created * 1000), "MMM d, yyyy");
            let childrenKeys = row.metadata.children ? row.metadata.children.split(",") : [];
    
            let current = {
                familyId: row.metadata.familyId,
                invoiceId: row.id,
                hostedInvoiceUrl: row.hosted_invoice_url,
                reference: row.number,
                children: row.metadata.children,
                childrenKeys: childrenKeys,
                date,
                parent: row.customer_name,
                primaryCenter: row.metadata.primaryCenter,
                total: "$ " + (row.total / 100).toFixed(2),
                status: row.status,
                onRemoveInvoice,
                onUpdateInvoice,
                toggleEditForm
            }
            rowData.push(current);
        }
    });

    return rowData;
} 

const cellStyleRule = (params) => {
    if(params.value === "paid") return 1;
    else if(params.value === "open") return 0;
    else if(params.value === "draft") return  2;
    else return -1;
}