import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { getFamily } from '../../store/actions/parentActions';
import { updateStudent } from '../../store/actions/studentActions';
import './styles/subscriptionform.scss';
import '../invoice/styles/invoiceform.scss';

// import Components 
import SubscriptionItem from './SubscriptionItem';
import SelectFamily from '../shared/SelectFamily';
import CustomSelect from '../shared/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

class SubscriptionForm extends Component {
    constructor() {
        super();
        this.state = {
            plan: "monthly",
            familyId: "",
            items: [],
            subtotal: 0,
            total: 0,
            discount: 0,
            plans: null,
            children: [],
            additional: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let discount = 1
        if(prevState.plan !== this.state.plan) {
            let subtotal = 0;
            
            for(let i = 0; i < this.state.items.length; i++) {
                let item = this.state.items[i];
                subtotal += Number(item.amount);
            }
            discount = 1;
            if(this.state.discount === "3-months-discount-subscription") discount = .95; 
            else if(this.state.discount === "6-months-discount-subscription") discount = .90; 
            else if(this.state.discount === "12-months-discount-subscription") discount = .85; 
            this.setState({ subtotal, total: subtotal * discount, items: [] });
        }

        if(prevState.items !== this.state.items) {
            let subtotal = 0;
            this.state.items.forEach(item => {
                subtotal += item.amount
            });
            discount = 1;
            if(this.state.discount === "3-months-discount-subscription") discount = .95; 
            else if(this.state.discount === "6-months-discount-subscription") discount = .90; 
            else if(this.state.discount === "12-months-discount-subscription") discount = .85; 
            this.setState({ subtotal, total: subtotal * discount });
        }

        if(prevState.familyId !== this.state.familyId) {
            let selectedFamily = this.props.families[this.state.familyId];
            this.setState({ children: [], selectedFamily });
            selectedFamily.students.forEach(student => {
                // let curr =  this.props.students[student.id]
                // let child = {
                //     id: student.id.toString(),
                //     name: (curr.firstName + ' ' + curr.lastName).trim()
                // }
                this.setState(prevState => ({ children: [...prevState.children, student.id]}));
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            plan: "monthly",
            familyId: "",
            child: "",
            items: [],
            subtotal: 0,
            total: 0,
            discount: 0,
            plans: null
        })
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if(event.target.name === "plan") {
            if(event.target.value === "monthly") this.setState({ discount: 0 }); 
            else if(event.target.value === "3-month") this.setState({ discount: "3-months-discount-subscription" }); 
            else if(event.target.value === "6-month") this.setState({ discount: "6-months-discount-subscription" }); 
            else if(event.target.value === "12-month") this.setState({ discount: "12-months-discount-subscription" }); 
        }
    }

    handleAddService = () => {
        let item = {
            package: "",
            description: "Package A",
            quantity: 1,
            unitPrice: 0,
            discount: 0,
            amount: 0,
            packageType: ""
        }
        this.setState(prevState => ({ items: [...prevState.items, item] }));
    }

    handleDeleteItem = (e, i) => {
        let newItems = this.state.items.slice();
        newItems.splice(i, 1)
        this.setState({ items: newItems });
    }

    handleChangeItem = (event, i) => {
        let newItems = this.state.items.slice()
        newItems[i][event.target.name] = event.target.value;
        this.setState({ items: newItems });
    }

    getItemAmount = (itemAmount, i) => {
        let newItems = this.state.items.slice()
        newItems[i].amount = itemAmount
        this.setState({ items: newItems });
    }

    handleItemAmount = (packageName, i) => {
        let newItems = this.state.items.slice()
        newItems[i].package = packageName;
        this.setState({ items: newItems });
    }

    handleDescription = (description, packageType, plan, i) => {
        let newItems = this.state.items.slice();
        newItems[i].description = description;
        newItems[i].packageType = packageType;
        newItems[i].plan = plan;
        this.setState({ items: newItems });
    }

    handleSend = (e) => {
        e.preventDefault();
        if(this.props.showForm){

            let children = this.state.items.map(item => {
                return item.child
            })
            let selectedFamily = this.props.families[this.state.familyId]

            let subscription = {
                plan: this.state.plan,
                items: this.state.items,
                subtotal: this.state.subtotal,
                total: this.state.total,
                discount: this.state.discount,
                plans: this.state.plans,
                additional: this.state.additional,
                parentOneFirstName: selectedFamily.parentOneFirstName,
                parentOneLastName: selectedFamily.parentOneLastName,
                stripeId: selectedFamily.stripeId,
                primaryCenter: selectedFamily.primaryCenter,
                secondaryCenter: selectedFamily.secondaryCenter,
                primaryEmail: selectedFamily.primaryEmail,
                cellNumber: selectedFamily.cellNumber,
                city: selectedFamily.city,
                address: selectedFamily.address,
                zipCode: selectedFamily.zipCode,
                state: selectedFamily.zipCode,
                id: this.state.familyId,
                children
            }

            if(subscription.discount === 0) {
                delete subscription.discount
            }

            this.props.toggleForm();

            // console.log(subscription)

            axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/processStripeSubscriptionAPI", subscription)
                .then(res => {
                    console.log(res.data)
                    this.props.handleOpenPopup("success", "Subscription created for " + subscription.parentOneFirstName + " " +  subscription.parentOneLastName + "!")
                    this.props.updateNewSubscriber(res.data)
                    this.state.items.forEach(item => {
                        let updatedChild = {
                            packageId: item.package,
                            packageDescription: item.description,
                            dateBilled: new Date()
                        }
                        this.props.updateStudent(item.child, updatedChild)
                    })
                }).catch(err => {
                    this.props.handleOpenPopup("error", err.message)
                })
        }
    }

    render() {
        return (
            <form className="details-form" id="subscription-form">
                <div className="customer-information-container">
                    <SelectFamily
                        style={{ marginTop: '.5rem' }}
                        className="field"
                        label="Select Customer"
                        value={this.state.familyId}
                        handleChange={this.handleChange}
                        margin="dense"
                        required/>
                    <CustomSelect
                        style={{ marginTop: '.5rem' }}
                        className="field"
                        name="plan"
                        value={this.state.plan} 
                        placeholder="Select Plan"
                        margin="dense"
                        handleChange={this.handleChange}
                        required>
                        <MenuItem value={"monthly"}>Monthly Plan's</MenuItem>
                        {/* <MenuItem value={"3-month"}>3 Month Plan's</MenuItem>
                        <MenuItem value={"6-month"}>6 month Plan's</MenuItem> */}
                        <MenuItem value={"12-month"}>12 Month Plan's</MenuItem>
                    </CustomSelect>
                </div>
                {/* <div className="children-container">
                    {this.state.children.length !== 0 && <p className="children">Children: { this.state.children.map(child => child.name).join(', ') }</p>}
                </div> */}
                <Divider />
                <div className="package-container">
                    <table className="package-table">
                        <thead>
                            <tr>
                                <th>Students</th>
                                <th>Package</th>
                                <th>Qt</th>
                                <th>Unit Price</th>
                                <th>Discount</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.items.map((item, i)=> {
                                return <SubscriptionItem 
                                            key={i} 
                                            i={i} 
                                            plan={this.state.plan}
                                            values={item}
                                            students={this.props.students}
                                            children={this.state.children}
                                            getItemAmount={this.getItemAmount}
                                            handleDescription={this.handleDescription}
                                            handleChangeItem={this.handleChangeItem}
                                            handleDeleteItem={this.handleDeleteItem}
                                            handleItemAmount={this.handleItemAmount}
                                            stripePlans={this.props.stripePlans}/>
                            })}
                            <tr>
                                <th className="add-package-container">
                                    <Fab size="small" color="secondary" aria-label="add" onClick={this.handleAddService}>
                                        <AddIcon />
                                    </Fab>
                                    <ListItemText style={{ color: '#2e3436', marginLeft: '.5rem' }} primary="Add item"/>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Divider />
                <div className="totals-container">
                    <div>
                        <textarea 
                            className="additional-notes-input"
                            rows="5" cols="70"
                            type="text" 
                            name="additional" 
                            value={this.state.additional} 
                            onChange={this.handleChange} 
                            placeholder="Additional notes"/>    
                    </div>
                    <div className="totals">
                        <div className="row">
                            <p className="total-title">Subtotal: </p>
                            <p className="total-num">{this.state.subtotal.toFixed(2)}</p>
                        </div>
                        <div className="row" id="discounts-wrapper">
                            <p className="total-title">Discount:</p>
                            <select 
                                className="discount" 
                                name="discount"
                                value={this.state.discount}
                                onChange={this.handleChange}>
                                <option value={0}>None</option>
                                {/* <option value="3-months-discount-subscription">3-Months 5% Discount</option>
                                <option value="6-months-discount-subscription">6-Months 10% Discount</option> */}
                                <option value="12-months-discount-subscription">12-Months 15% Discount</option>
                            </select>
                        </div>
                        <div className="row">
                            <p className="total-title">Total: </p>
                            <p className="total-num">{this.state.total.toFixed(2)}</p>
                        </div>
                    </div>
                </div>

                <div className="form-action-container" id="invoice-action">
                    <Button 
                        className="form-action-btn" 
                        variant="contained" 
                        color="secondary" 
                        onClick={this.handleSend}>
                        Send
                    </Button>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        family: state.parents,
        families: state.firestore.data.families,
        students: state.firestore.data.students,
        stripePlans: state.stripe.stripePlans
    }
}

export default connect(mapStateToProps, { getFamily, updateStudent })(SubscriptionForm);