import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import { connect } from 'react-redux';


const SelectStudents = props => {
    const classes = useStyles();
    const theme = useTheme();

    const { selectedStudents, students, handleStudents, location } = props;
    const formControl = {
        margin: theme.spacing(1),
        minWidth: 200,
        // maxWidth: 300,
    }

    if(!students){
        return <div></div>;
    }

    return (
        <FormControl className={props.className} style={formControl}>
            <InputLabel htmlFor="select-multiple-chip">Select Students</InputLabel>
            <Select
                multiple
                value={Object.keys(selectedStudents).map((student) => selectedStudents[student])}
                onChange={handleStudents}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => {
                    return(
                        <div className={classes.chips}>
                            {selected.map((value, i) => { 
                                return <Chip key={i} label={`${value.firstName} ${value.lastName}`} className={classes.chip} />
                            })}
                        </div>
                    )}
                }
                MenuProps={MenuProps}>
                    {Object.keys(selectedStudents).map((key, i)=> {
                        return (<MenuItem key={i} value={selectedStudents[key]} style={getStyles(selectedStudents[key], selectedStudents, theme)}>
                                    {selectedStudents[key].firstName} {selectedStudents[key].lastName}
                                </MenuItem>
                            )
                        }).slice().sort((a, b) => {
                            if(a.firstName < b.firstName) return -1
                            else if(a.firstName > b.firstName) return 1;
                            return 0
                        })
                    }
                    {students.map((student, i) => {
                        if(Array.isArray(selectedStudents)) {
                            if(!selectedStudents.find(item => item.id === student.id)) {
                                if(location === student.primaryCenter || location === student.secondaryCenter || !location) {
                                    return (<MenuItem key={i} value={student} style={getStyles(student, selectedStudents, theme)}>
                                                {student.firstName} {student.lastName}
                                            </MenuItem>)
                                }
                            }
                        } else if(!selectedStudents[student.id]){
                            return (<MenuItem key={i} value={student} style={getStyles(student, selectedStudents, theme)}>
                                        {student.firstName} {student.lastName}
                                    </MenuItem>
                                )
                        }}).slice().sort((a, b) => {
                            if(a.firstName < b.firstName) return -1
                            else if(a.firstName > b.firstName) return 1;
                            return 0
                        })
                    } 
                }
            </Select>
        </FormControl>
    )
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.ordered.students
    }
}

export default connect(mapStateToProps)(SelectStudents);

const getStyles = (name, selectedStudents, theme) => {
    let found = false;
    for(let i = 0; i < selectedStudents.length; i++){
        let current = selectedStudents[i];
        if(current.id === name.id) found = true;
    }
    return {
      fontWeight:
        found
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        // maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
