import React, { Component } from 'react';
import { connect } from 'react-redux';
import addDays from 'date-fns/addDays';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import InvoiceFormHeader from './InvoiceFormHeader';
import EditLineItem from './EditLineItem';
import axios from 'axios';
import './styles/invoice.scss';

class EditForm extends Component {
    constructor() {
        super(); 
        this.state = {
            familyId: "",
            selectedFamily: "",
            selectedStudents: [],
            date: new Date(),
            days_until_due: 7,
            type: "monthly",
            line_items: [],
            total_amount: 0,
            discount: "",
            footer: `A 24-hour advance notice is required to cancel or reschedule a class in order to avoid any charges or loss of class credit.\n\nPlease note that students will no longer be allowed to attend classes prior to receiving payment for them.`
        }
    }

    componentDidMount() {
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getStripeInvoice", {invoiceId: this.props.editableInvoice.invoiceId }).then(res => {
            let selectedFamily = this.props.familiesObj[res.data.metadata.familyId];
            let selectedStudents = selectedFamily.students.map(studentRef => {

                let student = Object.assign({}, this.props.students[studentRef.id]);
                if(student) {
                    student.id = studentRef.id;
                    return student;
                }
            })

            let line_items = res.data.lines.data.map(item => {
                return {
                    id: item.id,
                    student: item.metadata.studentId,
                    description: item.description,
                    unit_amount: (item.amount / item.quantity) / 100,
                    quantity: item.quantity,
                    total: item.amount / 100,
                    plan: item.metadata.plan,
                }
            })
            this.setState({
                invoiceId: res.data.id,
                familyId: res.data.metadata.familyId,             
                selectedFamily,
                selectedStudents,
                date: new Date(res.data.created * 1000),
                days_until_due: 7,
                type: res.data.metadata.type,
                line_items,
                total_amount: res.data.total / 100,
                discount: res.data.discount,
                footer: res.data.footer
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.familyId !== this.state.familyId) {
            let selectedFamily = this.props.familiesObj[this.state.familyId];
            let selectedStudents = selectedFamily.students.map(studentRef => {
                let student = Object.assign({}, this.props.students[studentRef.id]);
                student.id = studentRef.id;
                return student;
            })
            this.setState({ selectedFamily, selectedStudents });
        }
        if(prevState.type !== this.state.type) {
            let discount = "";
            if(this.state.type === "annually") discount = "12-months-discount";
            else discount = "";
            this.setState({ line_items: [], total_amount: 0, discount });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    updateItem = (obj, i) => {
        let newItems = this.state.line_items.slice()
        newItems[i] = {...obj}
        this.setState({ 
            line_items: newItems
        }, () => {
            let total_amount = this.state.line_items.reduce((acc, item) => acc + Number(item.total), 0);
            if(total_amount) {
                if(this.state.discount) total_amount *= .85;
                    this.setState({ total_amount });
            }
        });
    }

    deleteItem = (e, id) => {
        e.preventDefault()
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/deleteLineItems", { lineItemId: id}).then(res => {
            let newItems = this.state.line_items.slice(0, this.state.line_items.length - 1)
            this.setState({ line_items: newItems }, () => {
                let total_amount = this.state.line_items.reduce((acc, item) => acc + Number(item.total), 0);
                if(total_amount) {
                    if(this.state.discount) total_amount *= .85;
                        this.setState({ total_amount });
                }
            });
        }).catch(err => console.log(err))
    }

    handleSave = (e) => {
        e.preventDefault();
        let invoice = this.prepareInvoice();

        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/updateStripeInvoice", { invoice })
            .then(res => {
                this.props.handleUpdateInvoice(res.data)
                this.props.toggleForm()
            })
            .catch(error => console.log(error));
    }

    prepareInvoice = () => {
        const { 
            invoiceId,
            selectedFamily, 
            familyId, 
            line_items, 
            days_until_due,
            type,
            discount,
            date,
            footer } = this.state;

        let invoice = {
            id: {
                id: invoiceId
            },
            invoice: {
                familyId: familyId,
                stripeId: selectedFamily.stripeId,
                parentOneFirstName: selectedFamily.parentOneFirstName,
                parentOneLastName: selectedFamily.parentOneLastName,
                primaryCenter: selectedFamily.primaryCenter,
                secondaryCenter: selectedFamily.secondaryCenter,
                primaryEmail: selectedFamily.primaryEmail,
                cellNumber: selectedFamily.cellNumber,
                city: selectedFamily.city,
                address: selectedFamily.address,
                zipCode: selectedFamily.zipCode,
                state: selectedFamily.zipCode,
                line_items,
                days_until_due,
                type,
                discount,
                date,
                footer
            }
        }
        return invoice;
    }

    render() {
        let displayItems = this.state.line_items && this.state.line_items.map((item, i) => {
            return <EditLineItem 
                        key={i} 
                        {...item} 
                        i={i}
                        last={this.state.line_items.length}
                        type={this.state.type}
                        updateItem={this.updateItem}
                        deleteItem={this.deleteItem}
                        stripePlans={this.props.stripePlans}
                        selectedFamily={this.state.selectedFamily}
                        selectedStudents={this.state.selectedStudents}/>
        });

        return (
            <div>
                <InvoiceFormHeader 
                    {...this.state} 
                    families={this.props.families}
                    handleChange={this.handleChange}/>
                <div className="line-items-container">
                    {displayItems}
                </div>
                <div className="invoice-footer-container">
                    <div className="invoice-footer-notes">
                        <textarea 
                            className="invoice-footer"
                            rows="5" cols="70"
                            type="text" 
                            name="footer" 
                            value={this.state.footer} 
                            onChange={this.handleChange} 
                            placeholder="Additional notes"/>    
                    </div>
                    <div className="invoice-totals">
                        <div className="totals-container">
                            <h3 className="subheader">Discount:  </h3>
                            <h3 className="header">{this.state.discount ? '15%' : '0%'}</h3>
                        </div>
                        <div className="totals-container">
                            <h3 className="subheader">Total Amount: </h3>
                            <h3 className="header">$ {this.state.total_amount && this.state.total_amount.toFixed(2)}</h3>
                        </div>
                        <Button  
                            className="send-btn"
                            variant="contained" 
                            color="secondary" 
                            onClick={this.handleSave}>
                            Save Draft
                        </Button>
                    </div>
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        familiesObj: state.firestore.data.families,
        families: state.firestore.ordered.families,
        students: state.firestore.data.students,
        stripePlans: state.stripe.stripePlans
    }
}

export default connect(mapStateToProps)(EditForm);