import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import CustomSelect from '../shared/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';
import SelectLocation from '../shared/SelectLocation';
import TextField from '@material-ui/core/TextField';
import SelectStudents from '../shared/SelectStudents';
import SelectTeacher from '../shared/SelectTeacher';
import SelectSubject from '../shared/SelectSubject';
import SelectClassType from '../shared/SelectClassType';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { updateClass } from '../../store/actions/classActions';
import { updateSessions } from '../../store/actions/sessionActions';
import { colors } from '../../js/helpers';
import cloneDeep from 'lodash.clonedeep';

class ClassDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            className: props.classObj.className,
            location: props.classObj.location,
            selectedStudents: [],
            teacher: props.classObj.teacher,
            subject: props.classObj.subject,
            classType: props.classObj.classType,
            color: props.classObj.color,
            address: props.classObj.address ? props.classObj.address : "",
        }
    }

    componentDidMount() {
        if(this.props.classObj && this.props.students) {
            this.props.classObj.students.forEach(studentRef => {
                let student = cloneDeep(this.props.students[studentRef.id]);
                student.id = studentRef.id;
                let key = studentRef.id
                let obj = {};
                obj[key] = student
                obj[key].id = key;
                this.setState(prevProps => ({
                    selectedStudents: { 
                        ...prevProps.selectedStudents,
                        ...obj
                    }
                }));
            });

        }

        if(this.props.classObj && this.props.teachers) {
            this.setState({ teacher: this.props.classObj.teacher.id });
        }

        if(this.props.classObj && this.props.classObj.subject) {
            this.setState({ subject: this.props.classObj.subject.id });
        }

        if(this.props.classObj && this.props.classObj.classType) {
            this.setState({ classType: this.props.classObj.classType.id });
        }

        if(this.props.classObj && this.props.sessions) {
            let sessionsData = [];
            if(this.props.classObj.sessions) {
                this.props.classObj.sessions.forEach(sessionRef => {
                    let session = this.props.sessions[sessionRef.id];
                    if(session)
                        sessionsData.push({id: sessionRef.id, date: session.date });
                });
                this.setState({ sessionsData });
            }
        }
        
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.students !== this.props.students && this.props.classObj) {
            this.props.classObj.students.forEach(studentRef => {
                let student = cloneDeep(this.props.students[studentRef.id]);
                student.id = studentRef.id;
                let key = studentRef.id
                let obj = {};
                obj[key] = student
                obj[key].id = key;
                this.setState(prevProps => ({
                    selectedStudents: { 
                        ...prevProps.selectedStudents,
                        ...obj
                    }
                }));
            });
        }

        if(prevProps.classObj !== this.props.classObj && this.props.classObj.subject) {
            this.setState({ subject: this.props.classObj.subject.id });
        }

        if (prevProps.classObj !== this.props.classObj && this.props.classObj.classType) {
            this.setState({ classType: this.props.classObj.classType.id });
        }

        if (prevProps.sessions !== this.props.sessions && this.props.sessions) {
            let sessionsData = [];
            if(this.props.classObj.sessions) {
                this.props.classObj.sessions.forEach(sessionRef => {
                    let session = this.props.sessions[sessionRef.id];
                    if(session)
                        sessionsData.push({id: sessionRef.id, date: session.date });
                });
                this.setState({ sessionsData });
            }
        }

        if (prevProps.teachers !== this.props.teachers && this.props.teachers) {
            this.setState({ teacher: this.props.classObj.teacher.id });
        }

        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('details-form');
        if(wrapper) 
            wrapper.classList.add('active');
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleStudents = event => {
        this.setState({ selectedStudents: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSave = () => {
        const { className, location, teacher, subject, classType, color } = this.state;
        if(className.length === 0 || location.length === 0 || teacher.length === 0 
        || subject.length === 0 || classType.length === 0 || color.length === 0){
            this.props.handleOpenPopup("error", "Please fill in required fields!");
        } else {
            let updatedClass = Object.assign({ ...this.props.classObj }, { ...this.state })
            delete updatedClass.alert;

            this.props.updateClass(this.props.classId, updatedClass);
            let currentDate = new Date();

            // UPDATE CLASS SESSIONS
            if(this.state.sessionsData) {
                this.state.sessionsData.forEach(session => {
                    if(session.date.toDate() >= currentDate){
                        this.props.updateSessions(this.props.classId, updatedClass, session.id, this.state.selectedStudents);
                    }
                });
            }
            this.props.handleOpenPopup("success", `${className} class is updated!`);
        }
    }

    render() {
        return (
            <form className="details-form" id="details-form">
                <div className="fields-container">
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Class Name - Required"
                        name="className"
                        value={this.state.className}
                        onChange={this.handleChange('className')}
                        margin="normal"/>
                    <SelectSubject
                        className="field" 
                        name="subject"
                        value={this.state.subject}
                        handleChange={this.handleSelect}/>
                    <SelectClassType
                        className="field"
                        value={this.state.classType}
                        handleChange={this.handleSelect}/>
                    <SelectLocation 
                        className="field" 
                        title="Select Location - Required"
                        name="location"
                        value={this.state.location} 
                        handleChange={this.handleSelect}/>
                    <CustomSelect
                        className="field" 
                        name="color"
                        placeholder="Color - Required"
                        value={this.state.color}
                        handleChange={this.handleSelect}>
                            {colors.map((color) => {
                                return (
                                    <MenuItem key={color.color} value={color.color}>
                                        <div className="option-container">
                                            <div className="option-color" style={{ backgroundColor: color.color }}></div>
                                            <p>{color.name}</p>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                    </CustomSelect> 
                    {this.state.location === "Private" && <TextField
                            className="field"
                            variant="outlined"
                            label="Address"
                            name="address"
                            value={this.state.address}
                            onChange={this.handleChange('address')}
                            margin="normal"/>}
                    <SelectTeacher
                        className="field" 
                        handleChange={this.handleSelect}
                        value={this.state.teacher}/> 
                    <SelectStudents
                        className="field" 
                        location={this.state.location}
                        selectedStudents={this.state.selectedStudents} 
                        handleStudents={this.handleStudents}/>
                </div>
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                    <div className="action-container">
                        <Button 
                            className="form-action-btn" 
                            variant="contained" 
                            color="secondary" 
                            onClick={this.handleSave}>
                            save
                        </Button>
                    </div>
                }
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        profile: state.firebase.profile,
        students: state.firestore.data.students,
        sessions: state.firestore.data.sessions,
        subjects: state.firestore.data.subjects,
        classTypes: state.firestore.data.classTypes,
        teachers: state.firestore.data.teachers,
        // sessions
    }
}

export default compose(
    connect(mapStateToProps, { updateClass, updateSessions }),
    firestoreConnect([
        { collection: "classTypes" },
        { collection: "subjects" }
    ])
)(ClassDetails);