import React, { PureComponent } from 'react'
import { AgGridReact } from 'ag-grid-react';
import LinkRenderer from '../shared/LinkRenderer';
import ParentLinkRenderer from '../students/ParentLinkRenderer';
// import DeleteLocationRenderer from './DeleteLocationRenderer';
// import { isLocationMatch } from '../../js/helpers';
import { connect } from 'react-redux'; 
import { format } from 'date-fns';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import "./style/locationStudents.scss";

class LocationStudentsGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Students",
                    field: "student",
                    cellRenderer: 'linkRenderer',
                }, {
                    headerName: "Parents",
                    field: "parents",
                    cellRenderer: 'parentLinkRenderer'
                }, {
                    headerName: "Birthday",
                    field: "birthday"
                }, {
                    headerName: "Secondary Center",
                    field: "secondaryCenter"
                }, {
                    headerName: "Jusan Level",
                    field: "jusanLevel",
                }, {
                    headerName: "Amsan Level",
                    field: "amsanLevel",
                }, {
                    headerName: "Active",
                    field: "active",
                    cellClassRules: {
                        "status-green": params => cellStyleRule(params) === 1,
                        "status-red": params => cellStyleRule(params) === 0,
                    }
                }]

            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                parentLinkRenderer: ParentLinkRenderer,
                // deleteLocationRenderer: DeleteLocationRenderer
            },
            paginationPageSize: 20
        }
    } 

    componentDidMount() {
        if(this.props.students) {
            let rowData = convertDataToRow(this.props.students, this.props.families, this.props.location);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if(prevProps.students!== this.props.students && this.props.students) {
            let rowData = convertDataToRow(this.props.students, this.props.families, this.props.location);
            this.setState({ rowData });
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    // isExternalFilterPresent={this.isExternalFilterPresent}
                    // doesExternalFilterPass={this.doesExternalFilterPass}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}


const mapStateToProps = (state, props) => {
    return {
        students: state.firestore.data.students,
        families: state.firestore.data.families,
    }
}

export default connect(mapStateToProps)(LocationStudentsGrid);

const convertDataToRow = (students, families, location) => {
    let rowData = [];

    Object.keys(students).map(key => {
        let row = students[key];
        if(row.primaryCenter.toLowerCase() === location.toLowerCase()) {
            let parents = families[row.familyId.id];
            let parentsName = `${parents.parentOneFirstName} ${parents.parentOneLastName}`;
            let bDate = row.birthday ? format(row.birthday.toDate(), "MMM d, yyyy") : "";

            let data = {
                id: key,
                student: `${row.firstName} ${row.lastName}`,
                parents: parentsName,
                birthday: bDate,
                secondaryCenter: row.secondaryCenter,
                jusanLevel: row.jusanLevel,
                amsanLevel:row.amsanLevel,
                active: row.status,
                url: "students",
                familyRef: row.familyId,
                parentId: row.familyId.id,
            }
            rowData.push(data);
        }
    })

    return rowData;
}

const cellStyleRule = (params) => {
    if(params.value) return 1;
    else if(!params.value) return 0;
    else return -1;
}