import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function SimpleSelect(props) {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    const formControl = {
        minWidth: 120,
        marginTop: '16px',
        marginBottom: '8px',
    }

    return (
        <FormControl variant="outlined" className={props.className} style={formControl}>
            <InputLabel ref={inputLabel}>Amsan</InputLabel>
            <Select
                name="amsanLevel"
                value={props.value}
                onChange={props.handleChange}
                input={<OutlinedInput labelWidth={labelWidth}/>}>
                <MenuItem value="Level 12">Level 12</MenuItem>
                <MenuItem value="Level 11">Level 11</MenuItem>
                <MenuItem value="Level 10">Level 10</MenuItem>
                <MenuItem value="Level 9">Level 9</MenuItem>
                <MenuItem value="Level 8">Level 8</MenuItem>
                <MenuItem value="Level 7">Level 7</MenuItem>
                <MenuItem value="Level 6">Level 6</MenuItem>
                <MenuItem value="Level 5">Level 5</MenuItem>
                <MenuItem value="Level 4">Level 4</MenuItem>
                <MenuItem value="Level 3">Level 3</MenuItem>
                <MenuItem value="Level 2">Level 2</MenuItem>
                <MenuItem value="Level 1">Level 1</MenuItem>
                <MenuItem value="Degree 1">Degree 1</MenuItem>
                <MenuItem value="Degree 2">Degree 2</MenuItem>
            </Select>
        </FormControl>
    );
}