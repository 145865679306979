const initState = {
    locationError: null
}

const locationReducer = (state = initState, action) => {
    switch(action.type){
        case "CREATE_LOCATION":
            return {
                ...state,
                location: action.location
            }
        case "CREATE_LOCATION_ERROR":
            return {
                ...state,
                locationError: action.err
            }
        case "UPDATE_LOCATION":
            return {
                ...state,
                location: action.location
            }
        case "UPDATE_LOCATION_ERROR":
            return {
                ...state,
                locationError: action.err
            }
        case "DELETE_LOCATION":
            return {
                ...state,
                location: action.location
            }
        case "DELETE_LOCATION_ERROR":
            return {
                ...state,
                locationError: action.err
            }
        default: 
            return state;
    }
}

export default locationReducer;