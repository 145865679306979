import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import { updateLocation } from '../../store/actions/locationsActions';

class LocationDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            address: props.location.address,
            city: props.location.city,
            location: props.location.location,
            state: props.location.state,
            students: props.location.students,
            teachers: props.location.teachers,
            unit: props.location.unit,
            zipCode: props.location.zipCode,
            // color: props.location.color
        }
    }
    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentDidUpdate() {
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('details-form');
        if(wrapper) 
            wrapper.classList.add('active');
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleColor = event => {
        this.setState({ color: event.target.value });
    }

    handleSave = () => {
        let oldLocation = { ...this.props.location };
        let newLocation = { ...this.state };
        let updatedLocation = Object.assign(oldLocation, newLocation);
        if(updatedLocation.location.length === 0){
            this.props.handleOpenPopup("error", `Please complete the required fields!`);
        } else {
            this.props.updateLocation(this.props.locationId, updatedLocation);
            this.props.handleOpenPopup("success", `${updatedLocation.location} location added!`);
        }
    }

    render() {
        return (
            <form className="details-form" id="details-form">
                <div className="fields-container">
                    <TextField
                        variant="outlined"
                        label="Location*"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange('location')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Address"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleChange('address')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Unit"
                        name="unit"
                        value={this.state.unit}
                        onChange={this.handleChange('unit')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="City"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange('city')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="State"
                        name="state"
                        value={this.state.state}
                        onChange={this.handleChange('state')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Zip Code"
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleChange('zipCode')}
                        margin="normal"
                        className="field"/>
                </div>
                {(this.props.profile.isAdmin) && <div className="action-container">
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={this.handleSave}>
                            Save
                    </Button>
                </div>}
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps, { updateLocation })(LocationDetails);