import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/invoice.scss';
import deleteLogo from '../../resources/images/delete.png';

class LineItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            student: props.student,
            description: props.description,
            unit_amount: props.unit_amount,
            quantity: props.quantity,
            total: props.total,
            plan: props.plan,
            credits: 0,
            plan_nickname: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevState.plan !== this.state.plan || prevState.student !== this.state.student) 
        && this.state.plan && this.state.student) {
            let student = this.props.selectedStudents.find(student => student.id === this.state.student);
            let plan = this.props.stripePlans.find(plan => this.state.plan === plan.id);

            if(this.state.plan !== "custom") {
                this.setState({ 
                    unit_amount: (plan.amount / 100).toFixed(2),
                    quantity: plan.interval_count,
                    total: (plan.amount / 100) * plan.interval_count,
                    description: student.firstName + " " + student.lastName + " - " + plan.metadata.description,
                    plan_nickname: plan.nickname
                });
            }
        }
        if(prevState.quantity !== this.state.quantity || prevState.unit_amount !== this.state.unit_amount) {
            this.setState({ total: this.state.quantity * this.state.unit_amount })
        }
        this.props.updateItem(this.state, this.props.i)
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <div className="line-item-container">
                <span className="delete-col">
                    <h5 className="field-header"></h5>
                    {this.props.i === this.props.last - 1 && <button className="btn" onClick={this.props.deleteItem}><img className="img" src={deleteLogo} alt="delete"/></button>}
                </span>
                <div className="field-container">
                    <h5 className="field-header">Select Student</h5>
                    <select
                        className="input"
                        name="student"
                        value={this.state.student}
                        onChange={this.handleChange}>
                        <option value=""></option>
                        {this.props.selectedStudents.map(student => {
                            return <option className="option" key={student.id} value={student.id}>{student.firstName} {student.lastName}</option>
                        })}
                </select>
                </div>
                <div className="field-container">
                    <h5 className="field-header">Plan</h5>
                    <select
                        className="input"
                        name="plan"
                        value={this.state.plan}
                        onChange={this.handleChange}>
                        <option value=""></option>
                        {this.props.stripePlans.map((plan, i) => {
                            if((this.props.type === "monthly" && plan.interval === "month") || plan.name === "MISC") 
                                return <option className="option" key={plan.id} value={plan.id}>{plan.nickname}</option>
                            else if((this.props.type === "annually" && plan.interval === "year") || plan.name === "MISC") 
                                return <option key={plan.id} value={plan.id}>{plan.nickname}</option>
                        }).sort((a, b) => b.nickname - a.nickname)}
                        <option value="custom">Custom Charge</option>
                    </select>
                </div>
                <div className="field-container">
                    <h5 className="field-header">Description</h5>
                    <textarea 
                        className="input"
                        rows="3"
                        name="description" 
                        value={this.state.description} 
                        onChange={this.handleChange} />
                </div>
                <div className="field-container small">
                    <h5 className="field-header">Credits</h5>
                    <input 
                        className="input small"
                        name="credits" 
                        type="number"
                        value={this.state.credits} 
                        onChange={this.handleChange} />
                </div>
                <div className="field-container small">
                <h5 className="field-header">Unit Price</h5>
                <input 
                    className="input small"
                    name="unit_amount" 
                    value={this.state.unit_amount} 
                    onChange={this.handleChange} />
                </div>
                <div className="field-container small">
                <h5 className="field-header">Quantity</h5>
                <input 
                    className="input small"
                    name="quantity" 
                    value={this.state.quantity} 
                    onChange={this.handleChange}/>
                </div>
                <div className="field-container">
                    <h5 className="field-header">Total</h5>
                    <span>
                        {this.state.total && `$ ${this.state.total.toFixed(2)}`}
                    </span>
                </div>
                
                    
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        families: state.firestore.data.families
    }
}

export default connect(mapStateToProps)(LineItem)