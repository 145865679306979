import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Images
import dashboard from '../resources/images/dashboard.png';
import home from '../resources/images/home.png';
import calendar from '../resources/images/calendar.png';
import classes from '../resources/images/classes.png';
import students from '../resources/images/students.png';
import parents from '../resources/images/parents.png';
import teachers from '../resources/images/teachers.png';
import locations from '../resources/images/locations.png';
import invoice from '../resources/images/invoice.png';
import subscriptions from '../resources/images/rss.png';
import report from '../resources/images/reports.png';

// Components
import SidebarLink from './SidebarLink';

const SidebarLinkList = (props) => {
    let links;
    if(props.profile.isAdmin){
        links = adminLinks;
    } else if(props.profile.isCenterManager){
        links = centerManagerLinks;
    } else if(props.profile.isTeacher){
        links = teacherLinks;
    } else {
        links = [];
    }
    
    const displayLinks = links.map((item, i) => {
        return <SidebarLink key={i} {...item}/>;
    });

    return (
        <div className="layout-sidebar-links-container">
            { displayLinks }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default withRouter(connect(mapStateToProps)(SidebarLinkList));

export const adminLinks = [
    {
        icon: dashboard,
        link: "/",
        content: "dashboard"
    },
    {
        icon: calendar,
        link: "/calendar",
        content: "calendar"
    },
    {
        icon: classes,
        link: "/classes",
        content: "classes"
    },
    {
        icon: students,
        link: "/students",
        content: "students"
    },
    {
        icon: parents,
        link: "/parents",
        content: "parents"
    },
    {
        icon: teachers,
        link: "/teachers",
        content: "teachers"
    },
    {
        icon: locations,
        link: "/locations",
        content: "locations"
    },
    // {
    //     icon: home,
    //     link: "/private",
    //     content: "private"
    // },
    {
        icon: invoice,
        link: "/invoice",
        content: "invoice"
    },
    // {
    //     icon: subscriptions,
    //     link: "/subscriptions",
    //     content: "subscriptions"
    // },
    {
        icon: report,
        link: "/reports",
        content: "reports"
    },
    {
        icon: report,
        link: "/attendance-reports",
        content: "attendance report"
    },
    // {
    //     icon: invoice,
    //     link: "/invoice-alt",
    //     content: "invoiceAlt"
    // }
];

export const centerManagerLinks = [
    {
        icon: dashboard,
        link: "/",
        content: "dashboard"
    },
    {
        icon: calendar,
        link: "/calendar",
        content: "calendar"
    },
    {
        icon: classes,
        link: "/classes",
        content: "classes"
    },
    {
        icon: students,
        link: "/students",
        content: "students"
    },
    {
        icon: parents,
        link: "/parents",
        content: "parents"
    },
    {
        icon: teachers,
        link: "/teachers",
        content: "teachers"
    },
    // {
    //     icon: home,
    //     link: "/private",
    //     content: "private"
    // },
    {
        icon: invoice,
        link: "/invoice",
        content: "invoice"
    },
    // {
    //     icon: subscriptions,
    //     link: "/subscriptions",
    //     content: "subscriptions"
    // },
    {
        icon: report,
        link: "/reports",
        content: "reports"
    },
    {
        icon: report,
        link: "/attendance-reports",
        content: "attendance-reports"
    },
];

export const teacherLinks = [
    {
        icon: dashboard,
        link: "/",
        content: "dashboard"
    },
    {
        icon: calendar,
        link: "/calendar",
        content: "calendar"
    },
    {
        icon: classes,
        link: "/classes",
        content: "classes"
    },
    {
        icon: students,
        link: "/students",
        content: "students"
    },
    {
        icon: parents,
        link: "/parents",
        content: "parents"
    },
    {
        icon: home,
        link: "/private",
        content: "private"
    },
    {
        icon: report,
        link: "/reports",
        content: "reports"
    }
];