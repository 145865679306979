export const updateTeacher = (teacherId, updatedTeacher) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection('teachers').doc(teacherId).update({
            ...updatedTeacher
        }).then(() => {
            dispatch({ type: "UPDATE_TEACHER", teacher: updatedTeacher });
        }).catch(err => {
            dispatch({ type: "UPDATE_TEACHER_ERROR", err });
        });
    }
}

export const deleteTeacher = (teacherId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const teacherRef = firestore.doc('/teachers/' + teacherId);

        teacherRef.delete().then(deletedTeacher => {
            dispatch({ type: "DELETE_TEACHER", teacher: deletedTeacher});
        }).catch(err => {
            dispatch({ type: "DELETE_TEACHER_ERROR", err });
        });
    }
}