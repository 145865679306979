import React, { PureComponent } from 'react';
import LocationFilter from '../shared/LocationFilter';
import Searchbar from '../shared/Searchbar';
import TeachersGrid from './TeachersGrid';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { connect } from 'react-redux';
import './styles/teachers.scss';

class Teachers extends PureComponent {
    state = {
        showForm: false,
        locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
        search: "",
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        if(window.localStorage.getItem(this.props.profile.firstName)) {
            let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
            this.setState({ 
                locationFilter: settings.locationFilter,
                search: settings.search
            })
        } else {
            let settings = {
                locationFilter: this.state.locationFilter,
                search: this.state.search
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.profile.primaryCenter && this.props.profile.primaryCenter) {
            this.setState({ locationFilter: this.props.profile.primaryCenter });
        }
        if(prevState.locationFilter !== this.state.locationFilter || prevState.search !== this.state.search) {
            let settings = {
                locationFilter: this.state.locationFilter,
                search: this.state.search
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    handleFilter = e => {
        this.setState({ locationFilter: e.target.value });
    }

    handleChange = e => {
        this.setState({ search: e.target.value.toLowerCase() });
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        return (
            <div className="tab-container">
                <div className="list-actions-container">
                    <LocationFilter 
                        value={this.state.locationFilter}
                        handleChange={this.handleFilter} />
                    <Searchbar 
                        search={this.state.search}
                        handleChange={this.handleChange}/>
                </div>
                {/* <TeacherList locationFilter={this.state.locationFilter} search={this.state.search} handleOpenPopup={this.handleOpenPopup}/> */}
                <div className="teachers-grid-container">
                    <TeachersGrid 
                        teachers={this.props.teachers}
                        locationFilter={this.state.locationFilter} 
                        search={this.state.search} 
                        handleOpenPopup={this.handleOpenPopup}/>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        teachers: state.firestore.data.teachers
    }
}

export default connect(mapStateToProps)(Teachers);