import React, { PureComponent } from 'react';
import './style/layout.scss';

// Components
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import Main from './main/Main';
import MobileNavbar from './navbar/MobileNavbar';
import MobileCalendar from '../mobile/components/MobileCalendar'
import { connect } from 'react-redux';

class Layout extends PureComponent {
    state = {
        className: "nav-icon3",
        open: false,
        mobile: false,
        locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
    }

    componentDidMount() {
        if(window.innerWidth < 500) {
            this.setState({ mobile: true });
        }
        if(window.localStorage.getItem(this.props.profile.firstName)) {
            let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
            this.setState({ locationFilter: settings.locationFilter })
        } else {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        } 
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.profile.primaryCenter && this.props.profile.primaryCenter) {
            this.setState({ locationFilter: this.props.profile.primaryCenter });
        }
        if(prevState.locationFilter !== this.state.locationFilter) {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }
    
    toggleMenu = () => {
        if(this.state.className === "nav-icon3")
            this.setState({ 
                className: "nav-icon3 open",
                open: true,
            });
        else {
            this.setState({ 
                className: "nav-icon3",
                open: false,
            });
        }
    }

    handleFilter = e => {
        this.setState({ locationFilter: e.target.value })
    }

    render() {
        return (
            <div className="spa-container">
                <Navbar toggleMenu={this.toggleMenu} state={this.state} locationFilter={this.state.locationFilter} handleFilter={this.handleFilter}/>
                {!this.state.mobile && <Sidebar />}
                {!this.state.mobile && <Main />}
                {this.state.open && <MobileNavbar/>}
                {this.state.mobile && <MobileCalendar locationFilter={this.state.locationFilter}/>}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return { 
        profile: state.firebase.profile,
    }
}


export default connect(mapStateToProps)(Layout);