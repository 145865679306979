import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { login, socialLogin } from '../../store/actions/authActions';

// Components
import { Link } from 'react-router-dom';

// Styles
import './style/auth.scss';
import google from '../../resources/images/google.png';
// import facebook from '../../resources/images/facebook.png';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';


class Login extends PureComponent {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            alert: {
                variant: "success",
                open: false,
                message: ""
            }
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.authError && ((prevProps.authError !== this.props.authError))) {
            this.handleOpenPopup("error", this.props.authError);
        }
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { 
                                        variant: prevState.alert.variant, 
                                        open: false, 
                                        message: prevState.alert.message 
                                    }
                                }));
    }

    handleChange = name => e => {
        this.setState({ [name]: e.target.value });
    }

    handleSubmit = e => {
        this.props.login({ email: this.state.email, password: this.state.password});
    }

    handleSocial = (social) => {
        this.props.socialLogin(social);
    }

    onEnter = (e) => {
        if(e.key === "Enter") {
            this.handleSubmit(e)
        }
    }

    render() {
        return(
            <div className="login-wrapper" onKeyDown={this.onEnter}>
                <form className="login-form">
                    <TextField
                        id="outlined-email-input"
                        label="email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        id="outlined-password-input"
                        label="password"
                        type="password"
                        onChange={this.handleChange('password')}
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                    />
                    <Button 
                        variant="contained" 
                        onClick={this.handleSubmit}
                        color="secondary"
                        style={style.login}>
                        LOGIN
                    </Button>
                    {/* <div className="social-links-container"> */}
                        <Button 
                            style={style.social}
                            className="social-btn"
                            variant="contained"
                            onClick={() => this.handleSocial("google")}>
                            <img className="social-image" src={google} alt="abakidz math tutor"/>
                            <p className="social-text">Sign in with Google</p>
                        </Button>
                        {/* <Button 
                            style={style.social}
                            className="social-btn"
                            variant="contained"
                            onClick={() => this.handleSocial("facebook")}>
                            <img className="social-image" src={facebook} alt="abakidz math tutor facebook"/>
                            <p className="social-text">Facebook login</p>
                        </Button> */}
                    {/* </div> */}
                </form>

                <div className="signup-forgot-password-wrapper">
                    <Link to="/forgot-password">forgot password</Link>
                    <Link to="/signup">sign up</Link>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const style = {
    login: {
        boxShadow: 'none',
    },
    social: {
        backgroundColor: '#f1f1f1',
        boxShadow: 'none',
        marginTop: '1rem'
    }
}



const mapStateToProps = state => {
    return {
        authError: state.auth.authError
    }
}


export default connect(mapStateToProps, { login, socialLogin })(Login);