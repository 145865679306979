import React, { PureComponent } from 'react';
import { formatMilitaryTime } from '../../js/helpers';
import format from 'date-fns/format';
import icon from './resources/images/user.png';
import SlidingPane from '../shared/SlidingPane';
import SessionDetails from '../class/SessionDetails';
import { connect } from 'react-redux';

class Event extends PureComponent {
    state = {
        showDetails: false,
        students: [],
        location: "",
        // teacher: "",
        className: "",
        color: "",
        complete: [],
        preview: false
    }

    data = [];

    componentDidMount() {
        if(this.props.session.attendanceRecords && this.props.attendanceRecords) {
            for(let i = 0; i < this.props.session.attendanceRecords.length; i++) {
                let recordRef = this.props.session.attendanceRecords[i];
                let data = this.props.attendanceRecords[recordRef.id];
                if(data) 
                    this.setState(prevState => ({ complete: [...prevState.complete, data.attendance] }));
            }
        }

        // // GETS TEACHER
        // if(this.props.session.teacher && this.props.teachers) {
        //     let teacher = this.props.teachers[this.props.session.teacher.id];
        //     if(teacher) {
        //         let name = `${teacher.firstName} ${teacher.lastName}`;
        //         this.setState({ teacher: name });
        //     }
        // }

        if(this.props.session && this.props.classes) {
            let classObj = this.props.classes[this.props.session.classId.id];
            if(classObj) {
                this.setState({ 
                    className: classObj.className,
                    color: classObj.color
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.session.attendanceRecords && prevProps.attendanceRecords !== this.props.attendanceRecords) {
            for(let i = 0; i < this.props.session.attendanceRecords.length; i++) {
                let recordRef = this.props.session.attendanceRecords[i];
                let data = this.props.attendanceRecords[recordRef.id];
                this.setState(prevState => ({ complete: [...prevState.complete, data.attendance] }));
            }
        }

        // // GETS TEACHER
        // if(this.props.session.teacher && this.props.teachers !== prevProps.teachers) {
        //     let teacher = this.props.teachers[this.props.session.teacher.id];
        //     if(teacher) {
        //         let name = `${teacher.firstName} ${teacher.lastName}`;
        //         this.setState({ teacher: name });
        //     }
        // }

        if(this.props.session.classId && prevProps.classes !== this.props.classes) {
            let classObj = this.props.classes[this.props.session.classId.id];
            this.setState({ 
                className: classObj.className,
                color: classObj.color
            });
        }

        if(prevProps.session && prevProps.session.students 
        && prevProps.session.students.length !== this.props.session.students.length
        && this.props.students) {
            this.props.session.students.forEach(student => {
                let curr = this.props.students[student.id];
                let name = `${curr.firstName} ${curr.lastName}`;
                this.setState(prevState => ({ students: [...prevState.students, name] }));
            })
        }

        // if(prevProps.session.teacher && prevProps.session.teacher !== this.props.session.teacher
        // && this.props.teachers){
        //     let curr = this.props.teachers[this.props.session.teacher.id];
        //     let name = `${curr.firstName} ${curr.lastName}`;
        //     this.setState({ teacher: name });
        // }

        if(prevProps.session.lastUpdated && prevProps.session.lastUpdated !== this.props.session.lastUpdated) {
            this.setState({ complete: [] });
            setTimeout(() => {
                if(this.props.session && this.props.session.attendanceRecords) {
                    for(let i = 0; i < this.props.session.attendanceRecords.length; i++) {
                        let recordRef = this.props.session.attendanceRecords[i];
                        let data = this.props.attendanceRecords[recordRef.id];
                        this.setState(prevState => ({ complete: [...prevState.complete, data.attendance] }));
                    }
                }
            }, 1000);
        }
    }

    toggleDetails = () => {
        this.setState(prevState => ({ showDetails: !prevState.showDetails }));
        this.props.toggleMakeup()
    }

    render(){
        if(!this.props.session) return <div></div>;
        const { session } = this.props;
        
        let color = "";
        if(this.state.complete.length !== 0 && this.state.complete.every(item => item.length > 0)) {
            color = '#2ecc71';
        } else if(this.state.complete.some(item => item.length === 0) && this.state.complete.some(item => item.length > 0)) {
            color = '#f1c40f';
        }

        return (
            <>
                {this.state.showDetails 
                && <SlidingPane 
                    width={70}
                    header={format(session.date.toDate(), "MMMM d, yyyy")}
                    subheader={`${this.state.className} | ${session.location} | ${formatMilitaryTime(session.startTime)} - ${formatMilitaryTime(session.endTime)}`}
                    toggleForm={this.toggleDetails} 
                    showForm={this.state.showDetails}>
                    <SessionDetails
                        session={this.props.session}
                        sessionId={this.props.sessionId}
                        toggleForm={this.toggleDetails}/>
                </SlidingPane>}
                {/* <div className="class-event-container" style={{backgroundColor: this.state.color }} onClick={this.toggleDetails} onMouseEnter={this.props.handlePreviewEnter} onMouseLeave={this.props.handlePreviewLeave}> */}
                <div className="class-event-container" style={{backgroundColor: this.state.color }} onClick={this.toggleDetails}>
                    <div className="event-columns-header">
                        <h5 className="class-event-header">{this.state.className}</h5>
                        <div className="event-status" style={{ backgroundColor: color }}></div>
                    </div>
                    <div className="class-event-summary">
                        <div className="event-columns">
                            <h5 className="class-event-subheader">{formatMilitaryTime(session.startTime)}-{formatMilitaryTime(session.endTime)}</h5>
                            <div className="event-students">
                                <img className="class-event-student-icon"src={icon} alt="abakidz student"/>
                                <h6 className="student-names"> &nbsp; {this.props.session.students.length}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const id = props.sessionId;
    const sessions = state.firestore.data.sessions;
    const session = sessions ? sessions[id] : {};
    return { 
        attendanceRecords: state.firestore.data.attendanceRecords,
        students: state.firestore.data.students,
        teachers: state.firestore.data.teachers,
        classes: state.firestore.data.classes,
        session 
    };
}

export default connect(mapStateToProps)(Event);