const initState = {
    classError: null,
}

export const classReducer = (state = initState, action) => {
    switch(action.type){
        case "CREATE_CLASS":
            return {
                ...state,
                class: action.classObj
            }
        case "CREATE_CLASS_ERROR":
            return {
                ...state,
                classError: action.err
            }
        case "UPDATE_CLASS":
            return {
                ...state,
                class: action.classObj
            }
        case "UPDATE_CLASS_ERROR":
            return {
                ...state,
                classError: action.err
            }
        default:
            return state
    }
}

export default classReducer;