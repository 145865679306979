import React, { PureComponent } from 'react';
import SlidingPane from '../shared/SlidingPane';
import AddChildForm from './AddChildForm';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';

class ParentsChildrenHeader extends PureComponent {
    state = {
        showForm: false,
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }

    render() {
        return (
            <>
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                <>
                    <SlidingPane header="Add a child" toggleForm={this.toggleForm} showForm={this.state.showForm}>
                        {this.state.showForm && <AddChildForm parentId={this.props.parentId} toggleForm={this.toggleForm} handleOpenPopup={this.props.handleOpenPopup}/>}
                    </SlidingPane>
                    <Button color="secondary" variant="contained" size="small" onClick={this.toggleForm}>
                        <AddIcon />
                        Add a child
                    </Button>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(ParentsChildrenHeader);