import React from 'react';
import TextField from '@material-ui/core/TextField';
import SelectJusanLevel from '../shared/SelectJusanLevel';
import SelectAmsanLevel from '../shared/SelectAmsanLevel';
import './style/progressForm.scss';

const ProgressForm  = (props) => {
    return (
        <div className="progress-form-container">
            <form className="progress-form" noValidate autoComplete="off">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Book</th>
                            <th>End Page</th>
                            <th>Homework</th>
                            <th>Class Progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Jusan
                            </td>
                            <td>
                                <SelectJusanLevel 
                                    className="" 
                                    value={props.parent.jusanLevel} 
                                    handleChange={props.handleChange}/>
                            </td>
                            <td>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    name="jusanEndPage"
                                    value={props.parent.jusanEndPage}
                                    onChange={props.handleAltChange("jusanEndPage")}
                                    margin="normal"
                                    className=""/> 
                            </td>
                            <td>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    name="jusanHomework"
                                    value={props.parent.jusanHomework}
                                    onChange={props.handleAltChange("jusanHomework")}
                                    margin="normal"
                                    className=""/> 
                            </td>
                            <td>
                                <TextField
                                        variant="outlined"
                                        name="jusanNotes"
                                        value={props.parent.jusanNotes}
                                        onChange={props.handleAltChange("jusanNotes")}
                                        margin="normal"
                                        style={{width: '100%'}}
                                        className=""/> 
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                Amsan
                            </td>
                            <td>
                                <SelectAmsanLevel 
                                    className="" 
                                    value={props.parent.amsanLevel} 
                                    handleChange={props.handleChange}/> 
                            </td>
                            <td>
                                <TextField
                                    variant="outlined"
                                    name="amsanEndPage"
                                    type="number"
                                    value={props.parent.amsanEndPage}
                                    onChange={props.handleAltChange("amsanEndPage")}
                                    margin="normal"
                                    className=""/> 
                            </td>
                            <td>
                                <TextField
                                    variant="outlined"
                                    name="amsanHomework"
                                    type="number"
                                    value={props.parent.amsanHomework}
                                    onChange={props.handleAltChange("amsanHomework")}
                                    margin="normal"
                                    className=""/> 
                            </td>
                            <td>
                                <TextField
                                        variant="outlined"
                                        name="amsanNotes"
                                        style={{width: '100%'}}
                                        value={props.parent.amsanNotes}
                                        onChange={props.handleAltChange("amsanNotes")}
                                        margin="normal"
                                        className=""/> 
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                Comments
                            </td>
                            <td colSpan="4">
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Comments"
                                    name="comments"
                                    multiline
                                    style={{width: '100%'}}
                                    value={props.parent.comments}
                                    onChange={props.handleAltChange("comments")}
                                    rows="2"
                                    margin="normal"
                                    variant="outlined"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default ProgressForm;