import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { updateTeacher } from '../../store/actions/teacherActions';
import { connect } from 'react-redux';
import SelectLocation from '../shared/SelectLocation';
import DatePicker from '../shared/DatePicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomSelect from '../shared/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';

class TeacherDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            firstName: props.teacher.firstName ? props.teacher.firstName : "",
            lastName: props.teacher.lastName ? props.teacher.lastName : "",
            primaryCenter: props.teacher.primaryCenter ? props.teacher.primaryCenter : "",
            secondaryCenter: props.teacher.secondaryCenter ? props.teacher.secondaryCenter : "",
            birthday: props.teacher.birthday.toDate() ? props.teacher.birthday.toDate() : new Date(),
            address: props.teacher.address ? props.teacher.address: "",
            city: props.teacher.city ? props.teacher.city : "",
            state: props.teacher.state ? props.teacher.state : "",
            zipCode: props.teacher.state ? props.teacher.zipCode : "",
            isAdmin: props.teacher.isAdmin ? true : false,
            isTeacher: props.teacher.isTeacher ? true : false,
            isCenterManager: props.teacher.isCenterManager ? true : false,
            phoneNumber: props.teacher.phoneNumber ? props.teacher.phoneNumber : "",
            email: props.teacher.email ? props.teacher.email : "",
            prefix: props.teacher.prefix ? props.teacher.prefix : ""
        }
    } 

    componentDidMount() {
        console.log(this.props)
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentDidUpdate() {
        setTimeout(() => {
            const wrapper = document.getElementById('details-form');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('details-form');
        if(wrapper) 
            wrapper.classList.add('active');
    }

    handleSubmit = () => {
        const { firstName, lastName, primaryCenter } = this.state;
        if(primaryCenter.length === 0 || firstName.length === 0 || lastName.length === 0) {
            this.props.handleOpenPopup("error", "Please fill in required fields!");
        } else {
            let oldTeacher = { ...this.props.teacher };
            let newTeacher = { ...this.state };
            let updatedTeacher = Object.assign(oldTeacher, newTeacher);
            this.props.updateTeacher(this.props.teacherId, updatedTeacher);
            this.props.handleOpenPopup("success", `${firstName} ${lastName} is updated!`);
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSwitch = name => event => {
        this.setState({ [name]: event.target.checked });
    }

    handleDateChange = date => {
        this.setState({ birthday: date });
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <form className="details-form" id="details-form">
                <div className="fields-container">
                    <TextField
                        variant="outlined"
                        label="First Name*"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange('firstName')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Last Name*"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange('lastName')}
                        margin="normal"
                        className="field"/>
                    <CustomSelect 
                        className="field"
                        name="prefix"
                        placeholder="Prefix"
                        value={this.state.prefix}
                        handleChange={this.handleSelect}>
                        <MenuItem value="Mrs.">Mrs.</MenuItem>
                        <MenuItem value="Mr.">Mr.</MenuItem>
                        <MenuItem value="Master">Master</MenuItem>
                    </CustomSelect>
                    <TextField
                        variant="outlined"
                        label="Address"
                        name="adress"
                        value={this.state.address}
                        onChange={this.handleChange('address')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="City"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange('city')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="State"
                        name="state"
                        value={this.state.state}
                        onChange={this.handleChange('state')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Zip Code"
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleChange('zipCode')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Phone Number*"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.handleChange('phoneNumber')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Email*"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        className="field"/>
                    <SelectLocation 
                        className="field" 
                        title="Primary Center*"
                        name="primaryCenter"
                        value={this.state.primaryCenter} 
                        handleChange={this.handleSelect}/>
                    <SelectLocation
                        className="field" 
                        title="Secondary Center"
                        name="secondaryCenter"
                        value={this.state.secondaryCenter} 
                        handleChange={this.handleSelect}/>
                    <div className="field" >
                        <DatePicker 
                            className="field" 
                            label="Birthday" 
                            dateValue={this.state.birthday} 
                            handleDateChange={this.handleDateChange}/>
                    </div>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                        <div className="switch-field">
                            {this.props.profile.isAdmin && 
                            <>
                                <FormControlLabel
                                    className="field-start" 
                                    control={<Switch 
                                                checked={this.state.isAdmin} 
                                                onChange={this.handleSwitch('isAdmin')} 
                                                value="isAdmin" />}
                                    label="Is Admin?"
                                    labelPlacement="start"/>   
                                <FormControlLabel
                                    className="field-start" 
                                    control={<Switch 
                                                checked={this.state.isCenterManager} 
                                                onChange={this.handleSwitch('isCenterManager')} 
                                                value="isCenterManager" />}
                                    label="is Center Manager?"
                                    labelPlacement="start"/> 
                            </>}
                            <FormControlLabel
                                className="field-start" 
                                control={<Switch 
                                            checked={this.state.isTeacher} 
                                            onChange={this.handleSwitch('isTeacher')} 
                                            value="isTeacher" />}
                                label="Is Teacher?"
                                labelPlacement="start"/>  
                        </div>
                    }
                    
                </div>
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                    <div className="action-container">
                        <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSubmit}>
                            save
                        </Button>
                    </div>
                }
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.firebase.profile,
        studentError: state.students.studentError
    }
}

export default connect(mapStateToProps, { updateTeacher })(TeacherDetails);