import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
// import LinkRenderer from '../shared/LinkRenderer';
// import ParentLinkRenderer from './ParentLinkRenderer.js'
// import { isLocationMatch } from '../../js/helpers';
import TeacherRenderer from '../class/TeacherRenderer';
import ClassLinkRenderer from './ClassLinkRenderer';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format } from 'date-fns';

class AttendanceGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Class Name",
                    field: "className",
                    cellRenderer: 'classLinkRenderer'
                }, {
                    headerName: "Date",
                    field: "date",
                    // cellRenderer: 'parentLinkRenderer'
                }, {
                    headerName: "Status",
                    field: "status",
                    cellClassRules: {
                        "status-green": params => cellStyleRule(params) === 1,
                        "status-red": params => cellStyleRule(params) === 0,
                        "status-yellow": params => cellStyleRule(params) === -1
                    }
                }, {
                    headerName: "Teacher",
                    field: "teacher",
                    cellRenderer: "teacherRenderer"
                }, {
                    headerName: "Jusan Level",
                    field: "jusanLevel",
                }, {
                    headerName: "Jusan End Page",
                    field: "jusanEndPage",
                }, {
                    headerName: "Jusan Homework",
                    field: "jusanHomework",
                }, {
                    headerName: "Jusan Notes",
                    field: "jusanNotes",
                }, {
                    headerName: "Amsan Level",
                    field: "amsanLevel",
                }, {
                    headerName: "Amsan End Page",
                    field: "amsanEndPage",
                }, {
                    headerName: "Amsan Homework",
                    field: "amsanHomework",
                }, {
                    headerName: "Amsan Notes",
                    field: "amsanNotes",
                }, {
                    headerName: "Comments",
                    field: "comments",
                }]
            },
            rowData: [],
            frameworkComponents: {
                // linkRenderer: LinkRenderer,
                teacherRenderer: TeacherRenderer,
                classLinkRenderer: ClassLinkRenderer
            },
            paginationPageSize: 20
        }
    }

    componentDidMount() {
        if(this.props.student && this.props.sessions && this.props.attendanceRecords && this.props.classes && this.props.teachers) {
            let rowData = convertDataToRow(this.props.student, this.props.sessions, this.props.attendanceRecords, this.props.classes, this.props.teachers);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.student !== this.props.student && this.props.student ) 
        || (prevProps.attendanceRecords !== this.props.attendanceRecords && this.props.attendanceRecords)
        || (prevProps.sessions !== this.props.sessions && this.props.sessions)
        || (prevProps.classes !== this.props.classes && this.props.classes)
        || (prevProps.teachers !== this.props.teachers && this.props.teachers)) {
            let rowData = convertDataToRow(this.props.student, this.props.sessions, this.props.attendanceRecords, this.props.classes, this.props.teachers);
            this.setState({ rowData });
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    // quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default AttendanceGrid;

const convertDataToRow = (student, sessions, attendanceRecords, classes, teachers) => {
    let rowData = []
    if(student && student.attendanceRecords && student.attendanceRecords.length > 0) {
        student.attendanceRecords.forEach(recordRef => {
            if(attendanceRecords) {
                let row = attendanceRecords[recordRef.id];
                if(row && sessions[row.session.id]) {
                    let currentSession = sessions[row.session.id];
                    let currentClass = classes[currentSession.classId.id];
                    let currentTeacher = teachers[currentSession.teacher.id];
                    let current = {}
    
                    current.status = row.attendance;
                    current = {
                        status: row.attendance,
                        jusanLevel: row.jusanLevel,
                        jusanEndPage: row.jusanEndPage,
                        jusanHomework: row.jusanHomework,
                        jusanNotes: row.jusanNotes,
                        amsanLevel: row.amsanLevel,
                        amsanEndPage: row.amsanEndPage,
                        amsanHomework: row.amnsanHomework,
                        amsanNotes: row.amsanNotes,
                        comments: row.comments,
                    }
                    if(currentSession) {
                        current.date = format(currentSession.date.toDate(), "MMM d, yyyy");
                        current.classId = currentSession.classId.id
                        current.teacherId = currentSession.teacher.id
                    }
                    if(currentClass) current.className = currentClass.className;
                    if(currentTeacher) current.teacher = `${currentTeacher.firstName} ${currentTeacher.lastName}`;
                    rowData.push(current);
                }
            }
        })
    }

    rowData.sort((a, b) => new Date(b.date) - new Date(a.date))
    return rowData;
}

const cellStyleRule = (params) => {
    if(params.value === 'present') return 1;
    else if(params.value === 'absent') return 0;
    else if(params.value === 'other') return -1;
    else return;
}