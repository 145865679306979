import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import '../../style/slidingPane.scss';

const SlidingPane = props => {
    let customWidth = {};
    let visible = "hide";
    let overlay = "overlay-hide";

    if(props.showForm) {
        visible = "show";
        overlay = "overlay-show";
    }

    if(props.width) {
        customWidth = {
            width: `${props.width}%`
        }
    }

    return (
        <div id="add-item-action-container">
            <div id="slider-container" className={overlay}>
                <div id="slider-inner-container" className={visible} style={customWidth}>
                    <div className="header-container">
                        <div className="slide-header">
                            <IconButton className="slide-header-icon" onClick={props.toggleForm}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <h1 className="header">{props.header}</h1>
                        </div>
                        <div className="slide-subheader">
                            <h4 className="subheader">{props.subheader}</h4>
                        </div>
                    </div>
                    <div className="slide-content">
                    { props.children }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlidingPane;