import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SelectLocation from '../shared/SelectLocation';
import SelectJusanLevel from '../shared/SelectJusanLevel';
import SelectAmsanLevel from '../shared/SelectAmsanLevel';
import SelectFamily from '../shared/SelectFamily';
import DatePicker from '../shared/DatePicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import { createStudent } from '../../store/actions/studentActions';


class AddStudentForm extends PureComponent {
    constructor() {
        super();
        this.state = {
            firstName: "",
            lastName: "",
            birthday: new Date(),
            familyId: "",
            school: "",
            activeDate: new Date(),
            inactiveDate: null,
            status: false,
            amsanLevel: 0,
            jusanLevel: 0,
            primaryCenter: "",
            secondaryCenter: "",
            credits: 0,
            allergies: ""
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDateChange = date => {
        this.setState({ birthday: date });
    }
    
    handleSwitch = name => event => {
        // if(!event.target.checked){
        //     this.setState({ inactiveDate: new Date() });
        // } else {
        //     this.setState({ inactiveDate: null });
        // }
        this.setState({ [name]: event.target.checked });
    }

    handleSave = () => {
        const { familyId, firstName, lastName, primaryCenter } = this.state;
        if(familyId.length === 0 || firstName.length === 0 || lastName.length === 0 || primaryCenter.length === 0) {
            this.props.handleOpenPopup("error", "Please fill in required fields!");
        } else {
            this.props.createStudent(this.state);
            this.setState({
                firstName: "",
                lastName: "",
                birthday: new Date(),
                familyId: "",
                school: "",
                activeDate: new Date(),
                inactiveDate: null,
                status: false,
                amsanLevel: 0,
                jusanLevel: 0,
                primaryCenter: "",
                secondaryCenter: "",
                credits: 0,
                allergies: "",
            });
            this.props.handleOpenPopup("success", `${firstName} ${lastName} is added!`);
        }
    }

    render() {
        return (
            <form className="details-form">
                <div className="fields-container">
                    <TextField
                        className="field"
                        variant="outlined"
                        label="First Name*"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange('firstName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Last Name*"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange('lastName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Allergies"
                        name="allergies"
                        value={this.state.allergies}
                        onChange={this.handleChange('allergies')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="School"
                        name="school"
                        value={this.state.school}
                        onChange={this.handleChange('school')}
                        margin="normal"/>
                    <SelectLocation 
                        className="field" 
                        title="Primary Center*"
                        name="primaryCenter"
                        value={this.state.primaryCenter} 
                        handleChange={this.handleSelect}/>
                    <SelectLocation 
                        className="field" 
                        title="Secondary Center"
                        name="secondaryCenter"
                        value={this.state.secondaryCenter} 
                        handleChange={this.handleSelect}/>  
                    <SelectJusanLevel 
                        className="field" 
                        value={this.state.jusanLevel} 
                        handleChange={this.handleSelect}/>
                    <SelectAmsanLevel 
                        className="field" 
                        value={this.state.amsanLevel} 
                        handleChange={this.handleSelect}/> 
                    <SelectFamily 
                        className="field" 
                        label="Family*"
                        value={this.state.familyId} 
                        handleChange={this.handleSelect}/>  
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Credits"
                        name="credits"
                        value={this.state.credits}
                        onChange={this.handleChange('credits')}
                        margin="normal"/> 
                    <div className="field">
                        <DatePicker 
                            className="field" 
                            label="Birthday" 
                            dateValue={this.state.birthday} 
                            handleDateChange={this.handleDateChange}/>  
                        <div className="field-quarter">
                            <FormControlLabel
                                control={<Switch 
                                            checked={this.state.status} 
                                            onChange={this.handleSwitch('status')} 
                                            value={false} />}
                                label="Active"
                                labelPlacement="start"/>
                        </div> 
                    </div>
                   
                </div>
                <div className="action-container">
                    <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSave}>
                        save
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect(null, { createStudent })(AddStudentForm);