import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import LinkRenderer from '../shared/LinkRenderer';
import DeleteLocationRenderer from './DeleteLocationRenderer';
import { isLocationMatch } from '../../js/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

class LocationGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Location",
                    field: "location",
                    cellRenderer: 'linkRenderer',
                    filter: "agTextColumnFilter"
                    
                }, {
                    headerName: "Address",
                    field: "address",
                    width: 300
                }, {
                    headerName: "Number of students",
                    field: "numOfStudents"
                }, {
                    headerName: "Number of teachers",
                    field: "numOfTeachers"
                }, {
                    headerName: "Delete",
                    field: "delete",
                    cellRenderer: 'deleteLocationRenderer'
                }]

            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                deleteLocationRenderer: DeleteLocationRenderer
            },
            paginationPageSize: 20
        }
    } 

    // locationType = 'all'

    componentDidMount() {
        if(this.props.locations) {
            let rowData = convertDataToRow(this.props.locations, this.props.locationFilter);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if((prevProps.locations !== this.props.locations && this.props.locations) 
        || (this.props.locations && prevProps.locationFilter !== this.props.locationFilter && this.props.locationFilter)) {
            let rowData = convertDataToRow(this.props.locations, this.props.locationFilter);
            this.setState({ rowData });
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridColumnApi.setColumnVisible("delete", this.props.isAdmin);
    };

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    // isExternalFilterPresent={this.isExternalFilterPresent}
                    // doesExternalFilterPass={this.doesExternalFilterPass}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default LocationGrid;

const convertDataToRow = (data, locationFilter) => {
    let rowData = []
    console.log(data, locationFilter)
    Object.keys(data).map(key => {
        let row = data[key];
        if(row) {
            let current ={
                id: key,
                location: row.location,
                address: `${row.address}\n${row.city}, ${row.state} ${row.zipCode}`,
                numOfStudents: row.students,
                numOfTeachers: row.teachers,
                url: "locations"   
            }
            if(isLocationMatch(locationFilter, row.location)){
                rowData.push(current);
            }
        }
    });
    return rowData;
}