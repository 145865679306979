import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import LinkRenderer from '../shared/LinkRenderer';
import ChildrenRenderer from '../invoice/ChildrenRenderer';
import EmailRenderer from './EmailRenderer';
import SecondaryEmailRenderer from './SecondaryEmailRenderer';
import { isLocationMatch, formatPhoneNumber } from '../../js/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import DeleteParentRenderer from './DeleteParentRenderer';
import { actionTypes } from 'react-redux-firebase';

class ParentsGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Name",
                    field: "name",
                    cellRenderer: 'linkRenderer'
                }, {
                    headerName: "Children",
                    field: "children",
                    cellRenderer: 'childrenRenderer'
                }, {
                    headerName: "E-mail",
                    field: "primaryEmail",
                    cellRenderer: 'emailRenderer'
                }, {
                    headerName: "E-mail(1)",
                    field: "secondaryEmail",
                    cellRenderer: "secondaryEmailRenderer"
                }, {
                    headerName: "Cell Number",
                    field: "cellNumber"
                }, {
                    headerName: "Home Number",
                    field: "homeNumber"
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter"
                }, {
                    headerName: "Secondary Center",
                    field: "secondaryCenter"
                }, {
                    headerName: "Delete",
                    field: 'delete',
                    cellRenderer: 'deleteParentRenderer'
                }]
            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                childrenRenderer: ChildrenRenderer,
                emailRenderer: EmailRenderer,
                deleteParentRenderer: DeleteParentRenderer,
                secondaryEmailRenderer: SecondaryEmailRenderer
            },
            paginationPageSize: 100
        }
    }

    componentDidMount() {
        if(this.props.families) {
            let rowData = convertDataToRow(this.props.families, this.props.students, this.props.locationFilter);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if((prevProps.families !== this.props.families && this.props.families ) 
        || (prevProps.students !== this.props.students && this.props.students) 
        || (prevProps.locationFilter !== this.props.locationFilter && this.props.locationFilter)) {
            if(this.props.families && this.props.students) {
                let rowData = convertDataToRow(this.props.families, this.props.students, this.props.locationFilter);
                this.setState({ rowData });
            }
        }
        
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if(this.props.profile.isTeacher) {
            this.state.gridOptions.columnApi.setColumnVisible('delete', false)
        }
    };

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default ParentsGrid;

const convertDataToRow = (data, students, locationFilter) => {
    let rowData = []
    Object.keys(data).map(key => {
        let row = data[key];
        if(row) {
            let children = row.students.map(student => student.id)

            let current = {
                id: key,
                name: row.parentOneFirstName + ' ' + row.parentOneLastName,
                parentOneLastName: row.parentOneLastName,
                children: children,
                primaryEmail: row.primaryEmail,
                secondaryEmail: row.secondaryEmail,
                cellNumber: formatPhoneNumber(row.cellNumber),
                homeNumber: formatPhoneNumber(row.homeNumber),
                primaryCenter: row.primaryCenter,
                secondaryCenter: row.secondaryCenter,
                students,
                url: "parents"
            }
            
            if(isLocationMatch(locationFilter, row.primaryCenter, row.secondaryCenter)){
                rowData.push(current);
            }
        }
    });
    return rowData.sort((a, b) => a.name - b.name);
}