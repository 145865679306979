import React, { PureComponent } from 'react';
import { isSameDay } from 'date-fns';
import { connect } from 'react-redux';
import MobileEvent from './MobileEvent';

class MobileCalendarMain extends PureComponent {
    constructor() {
        super();
        this.state = {
            selectedSessions: []
        }
    }
    componentDidMount() {
        if(this.props.sessions) {
            this.findSessions();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.sessions !== this.props.sessions && this.props.sessions) 
        || (prevProps.selectedDate !== this.props.selectedDate && this.props.sessions)
        || (prevProps.locationFilter !== this.props.locationFilter && this.props.sessions)) {
            this.findSessions();
        }
    }

    findSessions = () => {
        let selectedSessions = this.props.sessions.filter(session => {
            if(isSameDay(session.date.toDate(), this.props.selectedDate)) {
                if(this.props.locationFilter.toLowerCase() === session.location.toLowerCase() || this.props.locationFilter.toLowerCase() === "all")
                    return session;
            }
        })
        this.setState({ selectedSessions });
    }


    render() {
        return(
            <div className="mobile-calendar-main-container">
                { this.state.selectedSessions.map(session => {
                    return <MobileEvent 
                            key={session.id} 
                            session={session} 
                            handleSelectSession={this.props.handleSelectSession}/>
                })}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return { 
        sessions: state.firestore.ordered.sessions,
    }
}

export default connect(mapStateToProps)(MobileCalendarMain);