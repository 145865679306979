import React, { PureComponent } from 'react';
// import AttendanceItem from './AttendanceItem';
import './styles/attendance.scss';
// import cloneDeep from 'lodash.clonedeep'
import AttendanceGrid from './AttendanceGrid';

import { compose } from 'redux';
import { connect } from 'react-redux';

class Attendance extends PureComponent {
    componentDidMount( ) {
        setTimeout(() => {
            const wrapper = document.getElementById('sessions-list-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentDidUpdate(){
        setTimeout(() => {
            const wrapper = document.getElementById('sessions-list-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }
    
    componentWillUnmount() {
        const wrapper = document.getElementById('sessions-list-container');
        if(wrapper) wrapper.classList.remove('active')
    }

    render() {
        return (
            <div className="sessions-list-container" id="sessions-list-container">
                <AttendanceGrid 
                    sessions={this.props.sessions}
                    teachers={this.props.teachers}
                    attendanceRecords={this.props.attendanceRecords}
                    student={this.props.student}
                    classes={this.props.classes}/>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const id = props.studentId;
    const students = state.firestore.data.students;
    const student = students ? students[id] : {};
    return { 
        sessions: state.firestore.data.sessions,
        teachers: state.firestore.data.teachers,
        attendanceRecords: state.firestore.data.attendanceRecords,
        classes: state.firestore.data.classes,
        student,
    }
}

export default compose(
    connect(mapStateToProps),
    // firestoreConnect([
    //     { collection: "students" },
    //     { collection: "attendanceRecords" }
    // ])
)(Attendance);