import React, { PureComponent } from 'react';
import { Route, NavLink, Redirect, Switch } from 'react-router-dom';
import Loading from '../shared/Loading';
import { connect } from 'react-redux';
import './style/class.scss';
import pin from '../calendar/resources/images/pin.png';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';

import ClassDetails from './ClassDetails';
import ClassSessions from './ClassSessions'; 

class Class extends PureComponent {
    state = {
        teacher: "",
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        if(this.props.classObj && this.props.teachers) {
            const { classObj } = this.props;
            let teacher = this.props.teachers[classObj.teacher.id]
            if(teacher) this.setState({ teacher })

        }

        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.classObj && prevProps.teachers !== this.props.teachers) {
            const { classObj } = this.props;
            let teacher = this.props.teachers[classObj.teacher.id]
            if(teacher) this.setState({ teacher })
        }

        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('content-container');
        wrapper.classList.remove('active')
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}))
    }

    render() {
        const { classObj } = this.props;
        const { id } = this.props.match.params;
        
        if(!classObj) {
            return <Loading />;
        };

        return (
            <div className="content-container" id="content-container">
                <header className="class-header">
                    <h1>{classObj.className}</h1>
                    <div className="description">
                        <img className="pin"src={pin} alt="abakidz location"/>
                        <h4 className="content">{classObj.location} | {`${this.state.teacher.firstName} ${this.state.teacher.lastName}`}</h4>
                    </div>
                    
                    <div className="navbar-container">
                        <NavLink activeClassName="nested-active-route" to={`${this.props.match.url}/details`}>Details</NavLink>
                        <NavLink activeClassName="nested-active-route" to={`${this.props.match.url}/sessions`}>Sessions</NavLink>
                        {/* <NavLink activeClassName="nested-active-route" to={`${props.match.url}/attendance`}>Attendance</NavLink> */}
                        {/* <NavLink activeClassName="nested-active-route" to={`${props.match.url}/payment`}>Payment History</NavLink> */}
                    </div>
                </header>

                <div className="main-content-container">
                    <Switch>
                        <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/details`}/>
                        <Route exact path={`${this.props.match.url}/details`} render={props => <ClassDetails {...props} classId={id} classObj={classObj} handleOpenPopup={this.handleOpenPopup}/> }/>
                        <Route exact path={`${this.props.match.url}/sessions`} render={props => <ClassSessions {...props} classId={id} classObj={classObj} handleOpenPopup={this.handleOpenPopup}/>}/>
                    </Switch>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const id = props.match.params.id;
    const classes = state.firestore.data.classes;
    const classObj = classes ? classes[id] : null;
    // console.log(state)
    return { 
        classObj,
        teachers: state.firestore.data.teachers,
        students: state.firestore.data.students,
        sessions: state.firestore.data.sessions,
        subjects: state.firestore.data.subjects,
        classTypes: state.firestore.data.classTypes,
    };
}

export default connect(mapStateToProps)(Class);