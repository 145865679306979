import React from 'react';
import isSameDay from 'date-fns/isSameDay';

const DayOfMonth = (props) => {
    let same = isSameDay(props.selectedDate, props.fullDate)
    let currentDate = isSameDay(props.fullDate, props.currentDate)
    return (
        <div className="day">
            <span>{props.day}</span>
            <span className={same ? "current-day" : "plain-day"}>
                <p className={currentDate ? same ? "current-day-text" : "now-day-text" : same ? "current-day-text" : "plain-day-text"} 
                    onClick={() => props.handleSelectDay(props.fullDate)}>{props.dayOfMonth}</p>
            </span>
        </div>
    )
}

export default DayOfMonth;