import React from 'react';
import './notifications.scss';
import WidgetContainer from '../WidgetContainer';
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import notificationIcon from '../../resources/notification.png';

const Notifications = (props) => {
    const { notifications } = props;

    return (
        <WidgetContainer title="Notifications" icon={notificationIcon}>
            <ul className="notifications-container">
                {notifications && notifications.map(notification => {
                    return (
                        <li key={notification.id} className="notification-item">
                            <h4 className="user">{notification.user}</h4>
                            <h5 className="content">{notification.content}</h5>
                            <h5 className="content">{formatDistanceToNow(notification.time.toDate())}</h5>
                        </li>
                    );
                })}
            </ul>
        </WidgetContainer>
    );
}

export default Notifications;