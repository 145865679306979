import React, { useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const LocationFilter = (props) => {
  // const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined">
            <InputLabel ref={inputLabel}>Location</InputLabel>
            <Select
                value={props.value}
                onChange={props.handleChange}
                input={<OutlinedInput labelWidth={labelWidth} />}>
                {props.profile.isAdmin && <MenuItem value={"All"}>All Locations</MenuItem>}
                <MenuItem value="Online">Online</MenuItem>
                {props.locations && props.locations.map((location, i) => {
                    if(props.profile.isAdmin || ((props.profile.primaryCenter || props.profile.secondaryCenter) && (props.profile.primaryCenter.toLowerCase() === location.location.toLowerCase() || props.profile.secondaryCenter.toLowerCase() === location.location.toLowerCase()))){
                        return <MenuItem key={i} value={location.location}>{location.location}</MenuItem>
                    }
                
            })}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        locations: state.firestore.ordered.locations
    };
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'locations' }
    ])
)(LocationFilter);