import React, { PureComponent } from 'react';

import { Route, NavLink, Redirect, Switch, Link } from 'react-router-dom';
import Loading from '../shared/Loading';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import StudentDetails from './StudentDetails';
import Attendance from './Attendance';

class Student extends PureComponent {
    state = {
        parent: {},
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentDidUpdate(prevProps, prevState){
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200);
    }
    
    componentWillUnmount() {
        const wrapper = document.getElementById('content-container');
        if(wrapper) wrapper.classList.remove('active')
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    handleParentClick = () => {
        if(this.props.student && this.props.student.familyId) {
            this.props.history.replace(`/parents/${this.props.student.familyId.id}`);
            // this.props.student.familyId.get().then(familyDoc => {
            //     if(familyDoc.exists) {
            //         this.props.history.replace(`/parents/${familyDoc.id}`);
            //     }
            // });
        }
    }

    render() {
        const { student } = this.props;
        const { id } =  this.props.match.params;

        if(!student) {
            return <Loading />;
        }

        return(
            <div className="content-container" id="content-container">
                <header className="header">
                    <h1>{student.firstName} {student.lastName}</h1>
                    <div className="navbar-container">
                        <NavLink activeClassName="nested-active-route" to={`${this.props.match.url}/details`}>Account</NavLink>
                        <NavLink activeClassName="nested-active-route" to={`${this.props.match.url}/attendance`}>Attendance</NavLink>
                        <span className="parent-link" onClick={this.handleParentClick}>Parents</span>

                        {/* <NavLink activeClassName="nested-active-route" exact to={`families/${this.state.parent.id}`}>Parents</NavLink> */}
                        {/* <Redirect activeClassName="nested-active-route" to={`families/${this.state.parent.id}`}>Parents</Redirect> */}
                    </div>
                </header>

                <div className="main-content-container">
                    <Switch>
                        <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/details`}/>
                        <Route exact path={`${this.props.match.url}/details`} render={props => <StudentDetails {...props} studentId={id} student={student} handleOpenPopup={this.handleOpenPopup}/>}/>
                        <Route exact path={`${this.props.match.url}/attendance`} render={props => <Attendance {...props} studentId={id}/>}/>
                        {/* {/* <Route exact path={`${props.match.url}/children`} render={props => <ParentsChildren {...props} parentId={id} parent={parent}/>}/> */}
                    </Switch>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const id = props.match.params.id;
    const students = state.firestore.data.students;
    const student = students ? students[id] : null;
    return { 
        student,
        attendanceRecords: state.firestore.ordered.attendanceRecords
    };
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: "students" },
        { collection: "attendanceRecords"}
    ])
)(Student);