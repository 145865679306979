import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import LinkRenderer from '../shared/LinkRenderer';
import ParentLinkRenderer from './ParentLinkRenderer.js'
import { isLocationMatch } from '../../js/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format } from 'date-fns';
import DeleteStudentRenderer from './DeleteStudentRenderer';

class StudentsGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Name",
                    field: "name",
                    cellRenderer: 'linkRenderer'
                }, {
                    headerName: "Parents",
                    field: "parents",
                    cellRenderer: 'parentLinkRenderer'
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter"
                }, {
                    headerName: "Jusan Level",
                    field: "jusanLevel",
                }, {
                    headerName: "Amsan Level",
                    field: "amsanLevel",
                }, {
                    headername: "Attendance Record",
                    field: "attendanceRecord"
                }, {
                    headerName: "Secondary Center",
                    field: "secondaryCenter"
                }, {
                    headerName: "Allergies",
                    field: "allergies"
                }, {
                    headerName: "Birthday",
                    field: "birthday"
                }, {
                    headerName: "Credits",
                    field: "credits"
                }, {
                    headerName: "Package Type",
                    field: "packageType"
                }, {
                    headerName: "Active Date",
                    field: "activeDate"
                }, {
                    headerName: "Inactive Date",
                    field: "inactiveDate"
                }, {
                    headerName: "Active",
                    field: "active",
                    cellClassRules: {
                        "status-green": params => cellStyleRule(params) === 1,
                        "status-red": params => cellStyleRule(params) === 0,
                    }
                }, {
                    headerName: "Delete",
                    field: 'delete',
                    cellRenderer: 'deleteStudentRenderer'
                }]
            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                parentLinkRenderer: ParentLinkRenderer,
                deleteStudentRenderer: DeleteStudentRenderer
            },
            paginationPageSize: 100
        }
    }

    componentDidMount() {
        if(this.props.students) {
            let rowData = convertDataToRow(this.props.students, this.props.families, this.props.locationFilter);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if((prevProps.students !== this.props.students && this.props.students ) 
        || (prevProps.families !== this.props.families && this.props.families)
        || (prevProps.locationFilter !== this.props.locationFilter && this.props.locationFilter)) {
            if(this.props.students && this.props.families) {
               
                let rowData = convertDataToRow(this.props.students, this.props.families, this.props.locationFilter);
                this.setState({ rowData });
            }
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if(this.props.profile.isTeacher) {
            this.state.gridOptions.columnApi.setColumnVisible('delete', false)
        }
    };
    
    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default StudentsGrid;

const convertDataToRow = (data, families, locationFilter) => {
    let rowData = []
    Object.keys(data).map(key => {
        let row = data[key];
        if(row) {
            let currParent = families[row.familyId.id];
            let parentName = "";
            if(currParent) {
                parentName = currParent.parentOneFirstName + ' ' + currParent.parentOneLastName
            }

            let present = 0;
            let absent = 0;
            let other = 0;
            if(typeof row.present === "number" && typeof row.absent === "number" && typeof row.other === "number") {
                present = row.present;
                absent = row.absent;
                other = row.other;
            }

            let current = {
                id: key,
                name: row.firstName + ' ' + row.lastName,
                parents: parentName,
                parentId: row.familyId.id,
                birthday: row.birthday ? format(row.birthday.toDate(), "MMM d, yyyy") : "",
                activeDate: row.activeDate ? format(row.activeDate.toDate(), "MMM d, yyyy") : "",
                inactiveDate: row.inactiveDate ? format(row.inactiveDate.toDate(), "MMM d, yyyy") : "",
                allergies: row.allergies,
                credits: row.credits,
                primaryCenter: row.primaryCenter,
                secondaryCenter: row.secondaryCenter,
                url: "students",
                familyRef: row.familyId,
                active: row.status,
                jusanLevel: row.jusanLevel,
                amsanLevel:row.amsanLevel,
                packageType: row.packageDescription ? row.packageDescription : "", 
                attendanceRecord: `${present} - ${absent} - ${other}`
            }
            if(isLocationMatch(locationFilter, row.primaryCenter, row.secondaryCenter)){
                rowData.push(current);
            }
        }
    });
    return rowData;
}

const cellStyleRule = (params) => {
    if(params.value) return 1;
    else if(!params.value) return 0;
    else return -1;
}