import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format, addMonths, subMonths, getMonth, getYear } from 'date-fns';
import { isLocationMatch, formatMilitaryTime } from '../../js/helpers';
import { connect } from 'react-redux'; 
import DeleteMakeupClassRenderer from './DeleteMakeupClassRenderer';

import '../subscriptions/styles/subscriptions.scss';
import './style/makeupmodal.scss';
import './style/calendar.scss';

class MakeupGrid extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            currentDate: new Date(),
            open: false,
            gridOptions: {
                columnDefs: [{
                    headerName: "Student",
                    field: "student",
                }, {
                    headerName: "Class Name",
                    field: "className"
                }, {
                    headerName: "Date",
                    field: "date"
                }, {
                    headerName: "Time",
                    field: "time"
                }, {
                    headerName: "Teacher",
                    field: "teacher",
                }, {
                    headerName: "Delete",
                    field: 'delete',
                    cellRenderer: 'deleteMakeupClassRenderer'
                }
            ]
            },
            rowData: [],
            frameworkComponents: {
                deleteMakeupClassRenderer: DeleteMakeupClassRenderer,
            },
            paginationPageSize: 20
        }
    }

    componentDidMount() {
        if(this.props.students && this.props.makeupClass) {
            let rowData = convertDataToRow(this.props.makeupClass, this.props.students, this.props.sessions, this.props.teachers, this.props.classes, this.props.locationFilter, this.state.currentDate);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.students !== this.props.students && this.props.students) 
        || (prevProps.locationFilter !== this.props.locationFilter && this.props.locationFilter)
        || (prevState.currentDate !== this.state.currentDate)) {
            if(this.props.students && this.props.makeupClass) {
                let rowData = convertDataToRow(this.props.makeupClass, this.props.students, this.props.sessions, this.props.teachers, this.props.classes, this.props.locationFilter, this.state.currentDate);
                this.setState({ rowData });
            }
        }
    }

    renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className="col col-center">
                    <span>{format(this.state.currentDate, dateFormat)}</span>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon">chevron_right</div>
                </div>
            </div>
        );
    }

    nextMonth = () => {
        this.setState({
            currentDate: addMonths(this.state.currentDate, 1)
        });
    }
    
    prevMonth = () => {
        this.setState({
            currentDate: subMonths(this.state.currentDate, 1)
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        return (
            <>
                {this.renderHeader()}
                <div className="ag-theme-material" style={{ height: '75vh', width: '100%' }} >
                    <AgGridReact
                        onGridReady={this.onGridReady}
                        gridOptions={this.state.gridOptions}
                        // quickFilter={this.props.search}
                        rowData={this.state.rowData}
                        frameworkComponents={this.state.frameworkComponents}
                        pagination={true}
                        paginationPageSize={this.state.paginationPageSize}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                        }}>
                    </AgGridReact>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return { 
        attendanceRecords: state.firestore.data.attendanceRecords,
        students: state.firestore.data.students,
        teachers: state.firestore.data.teachers,
        classes: state.firestore.data.classes,
        sessions: state.firestore.data.sessions,
        makeupClass: state.firestore.data.makeupClass
    };
}

export default connect(mapStateToProps)(MakeupGrid);

const convertDataToRow = (data, students, sessions, teachers, classes, locationFilter, currentDate) => {
    let rowData = []
    let currentMonth = getMonth(currentDate)
    let currentYear = getYear(currentDate)

    Object.keys(data).map(key => {
        let row = data[key];
        if(row) {
            let makeupMonth = getMonth(row.dateOfSession.toDate())
            let makeupYear = getYear(row.dateOfSession.toDate())
           
            if(makeupMonth === currentMonth && makeupYear === currentYear) {
                let student = students[row.student.id];
                let classObj = classes[row.classId.id];
                let teacher = teachers[row.teacher.id];
                let session = sessions[row.session.id];

                let current = {
                    id: key,
                    student: student.firstName + " " + student.lastName,
                    className: classObj ? classObj.className : "",
                    teacher: teacher.firstName + " " + teacher.lastName,
                    date: row.dateOfSession && format(row.dateOfSession.toDate(), "MMM d, yyyy"),
                    time: formatMilitaryTime(row.startTime) + " - " + formatMilitaryTime(row.endTime)
                }
                if(isLocationMatch(locationFilter, student.primaryCenter, student.secondaryCenter)){
                    rowData.push(current);
                }
            }
        }
    });

    rowData.sort((a, b) => new Date(b.date) - new Date(a.date))
    
    return rowData;
}
