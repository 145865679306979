import React from 'react';
import Loading from '../shared/Loading';
import LocationStudentsGrid from './LocationStudentsGrid';

import { connect } from 'react-redux';
import { compose } from 'redux';

import './style/locationStudents.scss';

const LocationStudents = props => {
    if(!props.students) {
        return <Loading />;
    }

    return (
        <div className="location-students-grid">
            {/* <LocationStudentsHeader /> */}
            {/* {renderList()} */}
            <LocationStudentsGrid location={props.location.location}/>
        </div>
    );
}

const mapStateToProps = (state, props) => {
    const students = state.firestore.ordered.students;
    return { students };
}

export default compose(
    connect(mapStateToProps),
)(LocationStudents);