import React, { PureComponent } from 'react';

import { Route, NavLink, Redirect, Switch } from 'react-router-dom';
import Loading from '../shared/Loading';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';

import LocationDetails from './LocationDetails';
import LocationStudents from './LocationStudents';

// import './style/location.scss';

import { connect } from 'react-redux';
import { compose } from 'redux';

class Location extends PureComponent {
    state = {
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentDidUpdate() {
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200);
    }
    

    componentWillUnmount() {
        const wrapper = document.getElementById('content-container');
        if(wrapper) wrapper.classList.remove('active')
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        const { location } = this.props;
        const { params, url } = this.props.match;

        if(!location) {
            return <Loading />;
        }

        return (
            <div className="content-container" id="content-container">
                <header className="header">
                    <h1>{location.location}</h1>
                    <div className="navbar-container">
                        <NavLink activeClassName="nested-active-route" to={`${url}/details`}>Details</NavLink>
                        {/* {/* <NavLink activeClassName="nested-active-route" to={`${props.match.url}/children`}>Children</NavLink> */}
                        <NavLink activeClassName="nested-active-route" to={`${url}/students`}>Students</NavLink>
                    </div>
                </header>

                <div className="main-content-container">
                    <Switch>
                        <Redirect exact from={`${url}`} to={`${url}/details`}/>
                        <Route exact path={`${url}/details`} render={props => <LocationDetails {...props} locationId={params.id} location={location} handleOpenPopup={this.handleOpenPopup}/>}/>
                        <Route exact path={`${url}/students`} render={props => <LocationStudents {...props} locationId={params.id} location={location} handleOpenPopup={this.handleOpenPopup}/>}/>
                    </Switch>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    let id = props.match.params.id;
    let locations = state.firestore.data.locations;
    let location = locations ? locations[id] : null;
    return { location }
}

export default connect(mapStateToProps)(Location);