import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { createLocation } from '../../store/actions/locationsActions';

class AddLocationForm extends PureComponent {
    state = {
        address: "",
        city: "",
        location: "",
        state: "",
        students: 0,
        teachers: 0,
        unit: "",
        zipCode: "",
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleSave = () => {
        if(this.state.location.length > 1) {
            this.props.createLocation(this.state);
            this.props.toggleForm();
            this.props.handleOpenPopup("success", `${this.state.location} location added!`);
            this.setState({
                address: "",
                city: "",
                location: "",
                state: "",
                students: 0,
                teachers: 0,
                unit: "",
                zipCode: "",
            });
        } else {
            this.props.handleOpenPopup("error", `Please complete the required fields!`);
        }
    }

    render() {
        return (
            <form className="details-form">
                <div className="fields-container">
                    <TextField
                        variant="outlined"
                        label="Location*"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange('location')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Address"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleChange('address')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Unit"
                        name="unit"
                        value={this.state.unit}
                        onChange={this.handleChange('unit')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="City"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange('city')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="State"
                        name="state"
                        value={this.state.state}
                        onChange={this.handleChange('state')}
                        margin="normal"
                        className="field"/>
                    <TextField
                        variant="outlined"
                        label="Zip Code"
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleChange('zipCode')}
                        margin="normal"
                        className="field"/>
                </div>
                <div className="action-container">
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={this.handleSave}>
                            Save
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect(null, { createLocation })(AddLocationForm);