import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const DatePicker = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} className={props.className}>
            <Grid container className={props.className}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="dense"
                    id="date-picker-inline"
                    label={props.label}
                    value={props.dateValue}
                    onChange={props.handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}/>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

export default DatePicker;