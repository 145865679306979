export const createStudent = student => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let familyId = student.familyId;

        let newStudent = {
            ...student,
            familyId: firestore.doc('/families/' + familyId)
        }

        firestore.collection('students').add({
            ...newStudent
        }).then(student => {
            let studentRef = firestore.doc('/students/' + student.id);
            firestore.collection('families').doc(familyId).update({
                students: firestore.FieldValue.arrayUnion(studentRef)
            });
            dispatch({ type: "CREATE_STUDENT", student: newStudent });
        }).catch(err => {
            dispatch({ type: "CREATE_STUDENT_ERROR", err});
        });
    }
}

export const updateStudent = (studentId, updatedStudent) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        
        firestore.collection('students').doc(studentId).update({
            ...updatedStudent
        }).then(() => {
            dispatch({ type: "UPDATE_STUDENT", updatedStudent });
        }).catch((err) => {
            dispatch({ type: "UPDATE_STUDENT_ERROR", err});
        });
    }
}

export const deleteStudent = (student, familyRef) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const studentRef = firestore.doc('/students/' + student);

        familyRef.update({
            students: firestore.FieldValue.arrayRemove(studentRef)
        }).then(() => {
            studentRef.get().then(studentSnapshot => {
                if(studentSnapshot.data().attendanceRecords) {
                    studentSnapshot.data().attendanceRecords.forEach(attendanceRecordRef => {
                        attendanceRecordRef.get().then(attendanceRecordSnapshot => {
                            let attendanceRecord = attendanceRecordSnapshot.data();
                            attendanceRecord.session.get().then(sessionSnapshot => {
                                if(sessionSnapshot.exists) {
                                    sessionSnapshot.data().classId.update({
                                        students: firestore.FieldValue.arrayRemove(studentRef)
                                    })
                                }
                            })
                            attendanceRecord.session.update({
                                attendanceRecords: firestore.FieldValue.arrayRemove(attendanceRecordRef),
                                students: firestore.FieldValue.arrayRemove(studentRef)
                            })
                        }).then(() => {
                            attendanceRecordRef.delete();
                        }) 
                    })
                }
            }).then(() => {
                studentRef.delete().then(deletedStudent => {
                    dispatch({ type: "DELETE_STUDENT", student: deletedStudent });
                }).catch(err => {
                    dispatch({ type: "DELETE_STUDENT_ERROR", err});
                })
            })
        }).catch(err => {
            dispatch({ type: "DELETE_STUDENT_ERROR", err});
        });
    }
}