let recordSkeleton = {
    attendance: "",
    jusanLevel: '',
    amsanLevel: '',
    jusanEndPage: '',
    amsanEndPage: '',
    jusanHomework: '',
    amsanHomework: '',
    jusanNotes: '',
    amsanNotes: '',
    comments: ''
}

export const createSessions = (classId, classObj, session, date) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let temp = firestore.doc('/classes/' + classId);
        // Create session 
        firestore.collection('sessions').add({ 
            ...session,
            date,
            location: classObj.location,
            students: classObj.students,
            teacher: classObj.teacher,
            color: classObj.color,
            classId: temp,
            lastUpdated: firestore.FieldValue.serverTimestamp(),
        }).then((session) => {
            let sessionRef = firestore.doc('/sessions/' + session.id);

            // Once session is created
            // The class DOC is updated
            firestore.collection('classes').doc(classId).update({
                sessions: firestore.FieldValue.arrayUnion(sessionRef)
            }).then(() => {
                console.log("success");
            }).catch(err => console.log(err));

            // For every student in the session
            classObj.students.forEach(student => {

                // Create attendance record
                firestore.collection('attendanceRecords').add({
                    ...recordSkeleton,
                    session: sessionRef,
                    student: student,
                }).then(attendanceRecord => { // Once attendance record is created
                    let attendanceRecordRef = firestore.doc('/attendanceRecords/' + attendanceRecord.id)

                    // Update session with new attendance record
                    firestore.collection('/sessions/').doc(session.id).update({
                        attendanceRecords: firestore.FieldValue.arrayUnion(attendanceRecordRef)
                    });

                    // Update student with new attendance record
                    firestore.collection('/students/').doc(student.id).update({
                        attendanceRecords: firestore.FieldValue.arrayUnion(attendanceRecordRef)
                    });
                });
            });
            dispatch({ type: "CREATE_SESSION", session });
        }).catch(err => {
            dispatch({ type: "CREATE_SESSION_ERROR", err });
        });
    }
}

export const updateSessions = (classId, classObj, sessionId, students) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const sessionRef = firestore.collection('sessions').doc(sessionId);
        let temp = students;
        if(!Array.isArray(students)){
            temp = Object.keys(students).map(i => students[i]);
        }
        
        let newStudents = temp.map(student => {
            return firestore.doc('/students/' + student.id);
        });
        

        sessionRef.update({
            students: newStudents,
            subject: firestore.doc('/subjects/' + classObj.subject),
            teacher: firestore.doc('/teachers/' + classObj.teacher),
            lastUpdated: firestore.FieldValue.serverTimestamp(),
        }).then(updatedSession => {
            // Delete all attendanceRecords in session and remove from students
            // Create new attendanceRecords
            sessionRef.get().then(sessionDoc => {
                if(sessionDoc) {
                    let sessionData = sessionDoc.data();
                    if(sessionData.attendanceRecords) {
                        sessionData.attendanceRecords.forEach(recordRef => {
                            recordRef.get().then(recordDoc => {
                                if(recordDoc) {
                                    let recordData = recordDoc.data();
                                    recordData.student.update({
                                        attendanceRecords: firestore.FieldValue.arrayRemove(recordRef)
                                    }).then(() => {
                                        recordRef.delete();
                                    });
                                }
                            });
                        });
                    }
                }
            }).then(() => {
                sessionRef.update({
                    attendanceRecords: firestore.FieldValue.delete()
                }).then(() => {
                    newStudents.forEach(student => {
                        firestore.collection('attendanceRecords').add({
                            ...recordSkeleton,
                            session: sessionRef,
                            student: student,
                        }).then(attendanceRecord => { // Once attendance record is created
                            let attendanceRecordRef = firestore.doc('/attendanceRecords/' + attendanceRecord.id)
        
                            // Update session with new attendance record
                            firestore.collection('/sessions/').doc(sessionRef.id).update({
                                attendanceRecords: firestore.FieldValue.arrayUnion(attendanceRecordRef)
                            });
        
                            // Update student with new attendance record
                            firestore.collection('/students/').doc(student.id).update({
                                attendanceRecords: firestore.FieldValue.arrayUnion(attendanceRecordRef)
                            });
                        });
                    });
                });
            });
            dispatch({ type: "UPDATE_SESSIONS", session: updatedSession});
        }).catch(err => {
            dispatch({ type: "UPDATE_SESSIONS_ERROR", err });
        });
    }
}

export const updateSession = (sessionId, teacherId, comments, callback) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const sessionRef = firestore.doc("/sessions/" + sessionId);
        const teacherRef = firestore.doc("/teachers/" + teacherId);
        sessionRef.update({
            teacher: teacherRef,
            comments: comments,
            isTeacherPaid: false,
            lastUpdated: firestore.FieldValue.serverTimestamp(),
        }).then(newTeacher => {
            dispatch({ type: "UPDATE_SESSION_TEACHER", teacher: newTeacher });
            if(callback) callback()
        }).catch(err => {
            dispatch({ type: "UPDATE_SESSION_TEACHER_ERROR", err });
        });
    }
}

export const addStudentToSession = (sessionId, studentId, isMakeup) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const sessionRef = firestore.collection('sessions').doc(sessionId);
        const studentRef = firestore.doc('/students/' + studentId);
        if(isMakeup) recordSkeleton.isMakeup = true;

        sessionRef.update({
            students: firestore.FieldValue.arrayUnion(studentRef),
            lastUpdated: firestore.FieldValue.serverTimestamp(),
        }).then(session => {
            dispatch({ type: "ADD_STUDENT_TO_SESSION", session });
        }).catch(err => {
            dispatch({ type: "ADD_STUDENT_TO_SESSION_ERROR", err});
        });

        firestore.collection('/attendanceRecords/').add({
            ...recordSkeleton,
            session: sessionRef,
            student: studentRef
        }).then(attendanceRecord => { // Once attendance record is created
            let attendanceRecordRef = firestore.doc('/attendanceRecords/' + attendanceRecord.id);

            // Update session with new attendance record
            sessionRef.update({
                attendanceRecords: firestore.FieldValue.arrayUnion(attendanceRecordRef)
            });

            // Update student with new attendance record
            studentRef.update({
                attendanceRecords: firestore.FieldValue.arrayUnion(attendanceRecordRef)
            });
        });
    }
}

export const deleteStudentFromSession = (sessionId, studentId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const sessionRef = firestore.collection('sessions').doc(sessionId);
        const studentRef = firestore.doc('/students/' + studentId);

        sessionRef.update({
            students: firestore.FieldValue.arrayRemove(studentRef),
            lastUpdated: firestore.FieldValue.serverTimestamp(),
        }).then(session => {
            dispatch({ type: "REMOVE_STUDENT_FROM_SESSION", session });
        }).catch(err => {
            dispatch({ type: "REMOVE_STUDENT_FROM_SESSION_ERROR", err });
        });
        
        sessionRef.get().then(doc => {
            if(doc.exists) {
                doc.data().attendanceRecords.forEach(record => {
                    record.get().then(doc => {
                        if(doc.exists) {
                            let recordId = doc.id;
                            let attendanceRecordRef = firestore.doc('/attendanceRecords/' + recordId);
                            if(doc.data().student.id === studentRef.id) {
                                studentRef.update({
                                    attendanceRecords: firestore.FieldValue.arrayRemove(attendanceRecordRef)
                                });
                                sessionRef.update({
                                    attendanceRecords: firestore.FieldValue.arrayRemove(attendanceRecordRef)
                                });
                                attendanceRecordRef.delete();
                            }
                        }
                    })
                });
            }
        });
    }
}

export const addStudentToMakeupClass = (sessionId, studentId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const state = getState();

        let students = state.firestore.data.students;
        let sessions = state.firestore.data.sessions;

        let student = students[studentId];
        let session = sessions[sessionId];

        let studentRef = firestore.doc('/students/' + studentId);
        let sessionRef = firestore.doc('/sessions/' + sessionId);

        if(student && session) {
            session.attendanceRecords.forEach(record => {
                let attendanceRecord = state.firestore.data.attendanceRecords[record.id];
                if(attendanceRecord.student.id === studentId) {
                    studentRef.update({
                        attendanceRecords: firestore.FieldValue.arrayRemove(record)
                    })
                    sessionRef.update({
                        students: firestore.FieldValue.arrayRemove(studentRef),
                        lastUpdated: firestore.FieldValue.serverTimestamp(),
                        attendanceRecords: firestore.FieldValue.arrayRemove(record)
                    });
                    record.delete()

                }
            })
        }
    }
}

export const deleteStudentFromMakeup = (makeupClassId) => {
    return (disptach, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let makeupClassRef = firestore.doc('/makeupClass/' + makeupClassId);
        makeupClassRef.delete();
    }
}

export const deleteSession = (sessionId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const sessionRef = firestore.collection("sessions").doc(sessionId);

        sessionRef.get().then(doc => {
            if(doc.exists) {
                let session = doc.data();

                session.classId.get().then(doc => {
                    doc.ref.update({
                        sessions: firestore.FieldValue.arrayRemove(sessionRef)
                    });
                }).then(() => {
                    if(session.attendanceRecords) {
                        session.attendanceRecords.forEach(attendanceRecordRef => {
                        attendanceRecordRef.get().then(doc => {
                            doc.data().student.get().then(doc => {
                                doc.ref.update({
                                    attendanceRecords: firestore.FieldValue.arrayRemove(attendanceRecordRef)
                                });
                            });
                        }).then(() => {
                            attendanceRecordRef.delete();
                        });                
                    })}
                });
            }
        }).then(() => {
            sessionRef.delete().then(() => {
                dispatch({ type: "DELETE_SESSION" });
            }).catch(err => {
                dispatch({ type: "DELETE_SESSION_ERROR", err });
            });
        });
    }
}

export const updateSessionStatus = (id, updatedObj) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const sessionsRef = firestore.doc('/sessions/' + id);

        sessionsRef.update({
            ...updatedObj
        }).then(res => {
            console.log(res)
        })
    }
}