import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LetterAvatar from '../../shared/LetterAvatar';
import '../style/navbar.scss';
import { connect } from 'react-redux';
import { logout } from '../../../store/actions/authActions';
import { Link, withRouter } from 'react-router-dom';

const SimpleMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { profile } = props;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        props.logout();
        props.history.push('/');
    }

    // console.log(profile)

  return (
    <div className="menu-container">
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <div className="layout-navbar-avatar-container">
                <LetterAvatar initials={profile.firstName && profile.firstName[0]}/>
                <h4 className="title">{profile.firstName && profile.firstName}</h4>
            </div>
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
            <Link to={`/teachers/${props.auth.uid}`}><MenuItem style={{ color: 'black'}}onClick={handleClose}>My account</MenuItem></Link>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        </div>
    );
}

const mapStateToProps = state => {
    // console.log(state)
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default withRouter(connect(mapStateToProps, { logout })(SimpleMenu));