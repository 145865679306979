import React, { PureComponent } from 'react';
import WidgetContainer from '../WidgetContainer';
import { connect } from 'react-redux'; 
import { getAge } from '../../../../js/helpers';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import './ageGroup.scss';
import '../birthdays/birthdays.scss'

class AgeGroup extends PureComponent {
    state = {
        data: []
    }
    componentDidMount() {
        if(this.props.students) {
            let ageArray = new Array(21).fill(0);
            let data = []
            this.props.students.forEach(student => {
                if(student?.birthday?.toDate) {
                    console.log("student: ", student)
                    let age = getAge(student.birthday.toDate());
                    ageArray[age] += 1;
                }
            })
            
            ageArray.forEach((ageGroup, i) => {
                if(ageGroup > 0) {
                    data.push({
                        "name": i + " year olds",
                        "Number of Students": ageGroup,
                        "amt": ageGroup
                    })
                    
                }
            })
            this.setState({ data });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps !== this.props && this.props.students) {
            let ageArray = new Array(21).fill(0);
            let data = []
            this.props.students.forEach(student => {
                // if(this.props.locationFilter.toLowerCase() === 'all' || this.props.locationFilter.toLowerCase() === student.primaryCenter.toLowerCase()){
                if(student?.birthday?.toDate) { 
                    let age = getAge(student.birthday.toDate());
                    ageArray[age] += 1;
                }
                // }
            })
            
            ageArray.forEach((ageGroup, i) => {
                if(ageGroup > 0) {
                    data.push({
                        "name": i + " year olds",
                        "Number of Students": ageGroup,
                        "amt": ageGroup
                    })
                    
                }
            })
            this.setState({ data });
        }
    }

    render() {
        return (
            <WidgetContainer title="Age Groups">
                {/* <h4 className="age-group-title">Age Groups</h4> */}
                <div className="age-group-container">
                    <BarChart width={730} height={300} data={this.state.data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend wrapperStyle={{ bottom: -10 }}/>
                        <Bar dataKey="Number of Students" fill="#2ecc71" />
                    </BarChart>
                </div>
            </WidgetContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.ordered.students
    }
}

export default connect(mapStateToProps)(AgeGroup);