import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SlidingPane from '../shared/SlidingPane';
import AddSessionForm from './AddSessionForm';
import { connect } from 'react-redux';

class ClassSessionsListHeader extends PureComponent {
    state = {
        showForm: false,
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }

    render() {
        return (
            <>
                {this.state.showForm && <SlidingPane header="Add sessions" toggleForm={this.toggleForm} showForm={this.state.showForm}>
                    {this.state.showForm && <AddSessionForm 
                        handleOpenPopup={this.props.handleOpenPopup}
                        classId={this.props.classId} 
                        classObj={this.props.classObj} 
                        toggleForm={this.toggleForm}/>}
                </SlidingPane>}
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <Button color="secondary" variant="contained" size="small" onClick={this.toggleForm}>
                    <AddIcon />
                    Add Sessions
                </Button>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(ClassSessionsListHeader);