export const updateAttendance = (studentId, sessionId, attendanceRecord) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let state = getState();

        // console.log(studentId, sessionId, attendanceRecord)

        let students = state.firestore.data.students;
        let attendanceRecords = state.firestore.data.attendanceRecords;
        let session = state.firestore.data.sessions[sessionId]
        let makeupClass = state.firestore.data.makeupClass;
        const sessionRef = firestore.doc('/sessions/' + sessionId);

        delete attendanceRecord.open;
        delete attendanceRecord.openAlert;
        // console.log("Session: ", session)
        if(session && session.attendanceRecords) {
            session.attendanceRecords.forEach(recordRef => {
                let currentRecord = attendanceRecords[recordRef.id];
                if(currentRecord) {
                    if(currentRecord.student.id === studentId) {
                        let currentStudent = students[currentRecord.student.id];
                        let present = 0;
                        let absent = 0;
                        let other = 0;
                        let makeupClassId = null;
                        if(currentStudent.present) present = currentStudent.present;
                        if(currentStudent.absent) absent = currentStudent.absent;
                        if(currentStudent.other) other = currentStudent.other;
                        // console.log(currentStudent,"\n","present: ",present,"\n","absent: ",absent,"\n","other: ",other)
                        
                        if(currentRecord.attendance === "") {
                            if(attendanceRecord.attendance === "present") {
                                present++;
                            } else if(attendanceRecord.attendance === "absent") {
                                absent++;
                            } else if(attendanceRecord.attendance === "other") {
                                other++;
                                let makeup = {
                                    student: currentRecord.student,
                                    session: currentRecord.session,
                                    dateOfSession: session.date,
                                    classId: session.classId,
                                    startTime: session.startTime,
                                    endTime: session.endTime,
                                    teacher: session.teacher
                                }
                                firestore.collection('makeupClass').add({
                                    ...makeup
                                }).then(result => {
                                    makeupClassId = result.id
                                    recordRef.update({
                                        makeupClassId,
                                    })
                                })
                            }
                        } else if(currentRecord.attendance === "present") {
                            if(attendanceRecord.attendance === "present") {
                                if(present === 0) present++;
                            } else if(attendanceRecord.attendance === "absent") {
                                if(present > 0) present--;
                                absent++;
                            } else if(attendanceRecord.attendance === "other") {
                                if(present > 0) present--;
                                let makeup = {
                                    student: currentRecord.student,
                                    session: currentRecord.session,
                                    dateOfSession: session.date,
                                    classId: session.classId,
                                    startTime: session.startTime,
                                    endTime: session.endTime,
                                    teacher: session.teacher
                                }
                                firestore.collection('makeupClass').add({
                                    ...makeup
                                }).then(result => {
                                    makeupClassId = result.id
                                    recordRef.update({
                                        makeupClassId,
                                    })
                                })
                            }
                        } else if (currentRecord.attendance === "absent") {
                            if(attendanceRecord.attendance === "absent") {
                                if(absent === 0) absent++;
                            } else if(attendanceRecord.attendance === "present") {
                                if(absent > 0) absent--;
                                present++;
                            } else if(attendanceRecord.attendance === "other") {
                                if(absent > 0) absent--;
                                let makeup = {
                                    student: currentRecord.student,
                                    session: currentRecord.session,
                                    dateOfSession: session.date,
                                    classId: session.classId,
                                    startTime: session.startTime,
                                    endTime: session.endTime,
                                    teacher: session.teacher
                                }
                                firestore.collection('makeupClass').add({
                                    ...makeup
                                }).then(result => {
                                    makeupClassId = result.id
                                    recordRef.update({
                                        makeupClassId,
                                    })
                                })
                            }
                        } else if(currentRecord.attendance === "other") {
                            if(attendanceRecord.attendance === "other") {
                                if(other === 0) {
                                    other++;
                                    let makeup = {
                                        student: currentRecord.student,
                                        session: currentRecord.session,
                                        dateOfSession: session.date,
                                        classId: session.classId,
                                        startTime: session.startTime,
                                        endTime: session.endTime,
                                        teacher: session.teacher
                                    }
                                    firestore.collection('makeupClass').add({
                                        ...makeup
                                    }).then(result => {
                                        makeupClassId = result.id
                                        recordRef.update({
                                            makeupClassId,
                                        })
                                    })
                                }
                            } else if(attendanceRecord.attendance === "present") {
                                if(other > 0) other--;
                                present++;
                                if(currentRecord.makeupClassId) {
                                    firestore.collection('makeupClass').doc(currentRecord.makeupClassId).delete();
                                }
                            } else if(attendanceRecord.attendance === "absent") {
                                if(other > 0) other--;
                                if(currentRecord.makeupClassId) {
                                    firestore.collection('makeupClass').doc(currentRecord.makeupClassId).delete();
                                }
                                absent++;
                            }
                        }
    
                        currentRecord.student.update({
                            present, absent, other
                        })
                        recordRef.update({
                            ...attendanceRecord,
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    }
                }
            })
    
            sessionRef.update({
                lastUpdated: firestore.FieldValue.serverTimestamp(),
            });
        }
    }
}

export const updateAttendanceRecord = (id, updatedObj) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const attendanceRecordRef = firestore.doc('/attendanceRecords/' + id);

        attendanceRecordRef.update({
            ...updatedObj
        }).then(res => {
            console.log(res)
        })
    }
}