import React from 'react';

const TeacherCellRenderer = (props) => {
  let firstName, lastName
  if(props?.value) {
    firstName = props.value.firstName;
    lastName = props.value.lastName;
  }
  return (
    <div>
      {firstName} {lastName}
    </div>
  );
};

export default TeacherCellRenderer;