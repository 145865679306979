import React, { PureComponent} from 'react';
import Alert from '../shared/Alert';
import cancelIcon from '../../resources/images/cancel.png';
import '../subscriptions/styles/subscriptions.scss';

class DeleteReferralCellRenderer extends PureComponent {
    state = {
        openAlert: false,
    }

    toggleAlert = () => {
        this.setState(prevState => ({ openAlert: !prevState.openAlert }));
    }

    toggleAccept = () => {
        this.props.data.delete(this.props.data.i);
    }

    render() {
        return (
            <>
                <button className="cancel-subscription-button" onClick={this.toggleAlert}>
                    <img className="cancel-icon" src={cancelIcon} alt=""/>
                </button>
                <Alert 
                    action={this.toggleAccept} 
                    toggleAlert={this.toggleAlert} 
                    openAlert={this.state.openAlert} 
                    title={`Are you sure you want to delete this referral?`} 
                    message={`NOTE: This action can't be undone.`}/>
            </>
        )
    }
}

export default DeleteReferralCellRenderer;