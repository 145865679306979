import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const SelectStudent = (props) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    let { students } = props;
    const formControl = {
        minWidth: 160,
        marginTop: '1rem',
        marginBottom: '.5rem'
    }

    return (
        <FormControl variant="outlined" className={props.className} style={formControl}>
            <InputLabel ref={inputLabel}>Select Student*</InputLabel>
            <Select
                name="student"
                value={props.value}
                onChange={props.handleChange}
                input={<OutlinedInput labelWidth={labelWidth}/>}>
                  {students && students.slice().sort((a, b) => {
                        if(a.firstName < b.firstName) return -1
                        else if(a.firstName > b.firstName) return 1;
                        return 0
                    }).map(student => {
                        return <MenuItem key={student.id} value={student.id}>{student.firstName} {student.lastName}</MenuItem>
                    })}
            </Select>
        </FormControl>
    );
}


const mapStateToProps = state => {
    return {
        students: state.firestore.ordered.students
    }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'students' }
  ])
)(SelectStudent);