import React, { PureComponent } from 'react';
import ReportsGrid from './ReportsGrid';
import LocationFilter from '../shared/LocationFilter';
import Searchbar from '../shared/Searchbar';
import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Export';
import exportIcon from '../../resources/images/export.png'
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { connect } from 'react-redux';
import './styles/reports.scss';

class Reports extends PureComponent {
    state = {
        showForm: false,
        locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
        search: "",
        exportGrid: false,
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        if(window.localStorage.getItem(this.props.profile.firstName)) {
            let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
            this.setState({ locationFilter: settings.locationFilter })
        } else {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.profile.primaryCenter && this.props.profile.primaryCenter) {
            this.setState({ locationFilter: this.props.profile.primaryCenter });
        }
        if(prevState.locationFilter !== this.state.locationFilter) {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    handleFilter = e => {
        this.setState({ locationFilter: e.target.value });
    }

    handleChange = e => {
        this.setState({ search: e.target.value.toLowerCase() });
    }


    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    handleExport = () => {
        this.setState(prevState => ({ exportGrid: !prevState.exportGrid }))
    }

    render() {
        return (
            <div className="tab-container" id="tab-container">
                <div className="list-actions-container">
                    <LocationFilter 
                        value={this.state.locationFilter}
                        handleChange={this.handleFilter} />
                    <Searchbar 
                        search={this.state.search}
                        handleChange={this.handleChange}/>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                        <div className="action-container">
                            <h4 style={{marginRight: '1rem'}}>Export</h4>
                            <Fab size="small" color="secondary" aria-label="add" onClick={this.handleExport}>
                                {/* <AddIcon/>  */}
                                <img className="export-icon" src={exportIcon} alt="export reports"/>
                            </Fab>
                        </div>
                    }
                </div>
                <div className="reports-grid-container">
                    <ReportsGrid 
                        students={this.props.students}
                        families={this.props.families}
                        attendanceRecords={this.props.attendanceRecords}
                        sessions={this.props.sessions}
                        locationFilter={this.state.locationFilter.toLowerCase()} 
                        profile={this.props.profile}
                        search={this.state.search.toLowerCase()} 
                        exportGrid={this.state.exportGrid}
                        handleOpenPopup={this.handleOpenPopup}/>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        profile: state.firebase.profile,
        students: state.firestore.data.students,
        families: state.firestore.data.families,
        attendanceRecords: state.firestore.data.attendanceRecords,
        sessions: state.firestore.data.sessions
    }
}

export default connect(mapStateToProps)(Reports);