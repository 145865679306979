import React from 'react';
import '../style/navbar.scss';
import { withRouter } from 'react-router-dom';
import MobileLink from './MobileLink';
import { connect } from 'react-redux';
import { adminLinks, centerManagerLinks, teacherLinks } from '../sidebar/SidebarLinkList'

const MobileNavbar = (props) => {
    let links;
    if(props.profile.isAdmin){
        links = adminLinks;
    } else if(props.profile.isCenterManager){
        links = centerManagerLinks;
    } else if(props.profile.isTeacher){
        links = teacherLinks;
    } else {
        links = [];
    }

    const displayLinks = links.map((item, i) => {
        return <MobileLink key={i} {...item}/>;
    });

    return (
        <div className="mobile-routes-container">
            {/* { displayLinks }  */}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default withRouter(connect(mapStateToProps)(MobileNavbar));