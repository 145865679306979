import React, { Component } from 'react';
import PaymentHistoryGrid from './PaymentHistoryGrid';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import axios from 'axios';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import SlidingPane from '../shared/SlidingPane';
import { connect } from 'react-redux';
import './style/payment.scss';

class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            alert: {
                variant: "success",
                open: false,
                message: ""
            },
            credit: 0,
            newInvoice: null,
            invoiceData: [],
            status: "loading"
        }
    }

    componentDidMount() {
        this.getCustomer();
    }

    getCustomer = () => {
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getStripeCustomerAPI", {
            stripeId: this.props.parent.stripeId
        }).then(res => {
            this.setState({ stripe: res.data, status: "loaded" })
        }).catch(err => {
            this.setState({ status: "not exist"})
            console.log(err)
        });
    }

    getCustomerInvoices = () => {
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getStripeCustomersInvoices", {
            stripeId: this.props.parent.stripeId
        }).then(res => {
            let invoiceData = res.data.data;
            
            this.setState({ invoiceData });
        })
        .catch(err => this.props.handleOpenPopup('error', err.message));
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleCredit = () => {
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/issueStripeCustomerCredit", {
            stripeId: this.props.parent.stripeId,
            amount: this.state.credit,
        }).then(res => {
            this.getCustomer();
        }).catch(err => console.log(err))
    }

    render() {
        if(this.state.status === "loading") return <div>Loading</div>
        else if(this.state.status === "not exist") return <div>Customer does not exist in Stripe, please contact Vlad at 917-755-7781</div>
        return (
            <div className="payment-history-grid-container" style={{height: "59vh"}}>
                {((this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                    this.state.showForm) && 
                    <SlidingPane width={30} header="Add credit" toggleForm={this.toggleForm} showForm={this.state.showForm}>
                        <div className="credit-pane">
                            <input 
                                className="credit-input"
                                name="credit" 
                                value={this.state.credit} 
                                onChange={this.handleChange}/>
                            <Button  
                                className="credit-btn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleCredit}>
                                Give credit
                            </Button>
                        </div>
                    </SlidingPane>
                }
                <div className="balance-container">
                    <h4>Current Balance: {this.state.stripe ? "$" + (this.state.stripe.balance / 100).toFixed(2) : 0}</h4>
                    <div className="credit-action">
                        <h4 className="credit-action-text">Apply credit</h4>
                        <Fab size="small" color="secondary" aria-label="add" onClick={this.toggleForm}>
                            <AddIcon/> 
                        </Fab>
                    </div>
                </div>
                <PaymentHistoryGrid 
                    handleOpenPopup={this.handleOpenPopup} 
                    newInvoice={this.state.newInvoice}
                    parent={this.props.parent}/>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
    }
}

export default connect(mapStateToProps)(PaymentHistory);