import React from 'react';

const PresentCellRenderer = (props) => {
  let value = 0;
  if(props.data.attendanceRecords) {
    props.data.attendanceRecords.forEach(record => {
      if(record.attendance === "present") value++;
    })
  }

  return (
    <div>
      {value}
    </div>
  );
};

export default PresentCellRenderer;