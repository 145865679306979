import React, { PureComponent } from 'react';
import MobileCalendarHeader from './MobileCalendarHeader';
import MobileCalendarMain from './MobileCalendarMain';
import MobileSessionDetails from './MobileSessionDetails';
import { startOfWeek, getDate, addDays, subDays } from 'date-fns';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import '../styles/mobilecalendar.scss';
import { Transition, animated } from 'react-spring/renderprops'

class MobileCalendar extends PureComponent {
    constructor() {
        super();
        this.state = {
            days: [],
            selectedDate: new Date(),
            currentDate: new Date(),
            selectedSession: null,
            openSession: false,
        }
    }

    componentDidMount() {
        let startDay = startOfWeek(new Date());
        let days = []
        for(let i = 0; i < 7; i++) {
            let day = "S"
            if(i === 0 || i === 6) day = "S";
            else if(i === 1) day = "M";
            else if(i === 2 || i === 4) day = "T";
            else if(i === 3) day = "W";
            else if(i === 5) day = "F"

            let date = {
                day,
                fullDate: startDay,
                dayOfMonth: getDate(startDay),
            }
            days.push(date);
            startDay = addDays(startDay, 1)
        }
        this.setState({ days })
    }

    handleSelectDay = (selectedDate) => {
        this.setState({ selectedDate })
    }

    nextWeek = () => {
        let days = this.state.days.map(item => {
            let fullDate = addDays(item.fullDate, 7)
            return {
                day: item.day,
                fullDate,
                dayOfMonth: getDate(fullDate)
            }
        })

        this.setState({
            days,
        });
    }
    
    prevWeek = () => {
        let days = this.state.days.map(item => {
            let fullDate = subDays(item.fullDate, 7)
            return {
                day: item.day,
                fullDate,
                dayOfMonth: getDate(fullDate)
            }
        })
        
        this.setState({
            days,
        });
    }

    handleSelectSession = (sessionId) => {
        this.setState({ 
            openSession: true,
            selectedSession: sessionId
        });
    }

    closeSelectedSession = () => {
        this.setState({
            openSession: false,
            selectedSession: null
        });
    }

    render() {
        return (
            <div className="mobile-calendar-container">
                <MobileCalendarHeader 
                    days={this.state.days} 
                    selectedDate={this.state.selectedDate}
                    currentDate={this.state.currentDate}
                    handleSelectDay={this.handleSelectDay}
                    nextWeek={this.nextWeek}
                    prevWeek={this.prevWeek}/>
                <MobileCalendarMain 
                    handleSelectSession={this.handleSelectSession}
                    locationFilter={this.props.locationFilter}
                    selectedDate={this.state.selectedDate}/>
                {this.state.openSession && 
                    <Transition
                        native
                        items={this.state.openSession} 
                        from={{ position: 'absolute', overflow: 'hidden', height: 0 }}
                        enter={[{ height: 'auto' }]}
                        leave={{ height: 0 }}>
                        {show =>
                            show && (props => <animated.div style={props}>
                                                    <MobileSessionDetails 
                                                        selectedSession={this.state.selectedSession}
                                                        closeSelectedSession={this.closeSelectedSession}/>
                                                </animated.div>)
                        }
                    </Transition>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return { 
        sessions: state.firestore.data.sessions,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'sessions' },
        { collection: 'classes' },
        { collection: 'students' },
        { collection: 'teachers' },
        { collection: 'attendanceRecords' },
    ])
)(MobileCalendar);