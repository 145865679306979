import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const SelectParents = (props) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    const formControl = {
        minWidth: 120,
        marginTop: props.margin === "dense" ? ".5rem" : " 1rem",
        marginBottom: '.5rem',
    }

    let { families } = props;

    return (
        <FormControl variant="outlined" className={props.className} style={formControl}>
            <InputLabel ref={inputLabel}>{props.label}</InputLabel>
            <Select
                style={props.style}
                name="familyId"
                value={props.value}
                onChange={props.handleChange}
                width={props.width}
                margin={props.margin}
                input={<OutlinedInput labelWidth={labelWidth}/>}>
                {families && families.slice().sort((a, b) => {
                    if(a.parentOneFirstName < b.parentOneFirstName) return -1
                    else if(a.parentOneFirstName > b.parentOneFirstName) return 1;
                    return 0
                }).map((family, i) => {
                    return <MenuItem key={i} value={family.id}>{family.parentOneFirstName} {family.parentOneLastName}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
}


const mapStateToProps = state => {
    return {
        families: state.firestore.ordered.families
    }
}

export default connect(mapStateToProps)(SelectParents);