import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const SelectLocation = (props) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    
    const formControl = {
        minWidth: 120,
        marginTop: '1rem',
        marginBottom: '.5rem'
    }

    return (
        <FormControl variant="outlined" className={props.className} style={formControl}>
            <InputLabel ref={inputLabel}>{props.title}</InputLabel>
            <Select
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
                input={<OutlinedInput labelWidth={labelWidth}/>}>
                {props.locations && props.locations.map((location, i) => {
                    if((props.auth.isEmpty || props.profile.isAdmin) 
                    || ((props.profile.primaryCenter || props.profile.secondaryCenter) && (props.profile.primaryCenter.toLowerCase() === location.location.toLowerCase() || props.profile.secondaryCenter.toLowerCase() === location.location.toLowerCase())))
                    return <MenuItem key={i} value={location.location}>{location.location}</MenuItem>
                })}
                <MenuItem value="Private">Private</MenuItem>
                {props.profile.isAdmin && <MenuItem value={""}>None</MenuItem>}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        locations: state.firestore.ordered.locations
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'locations' }
    ])
)(SelectLocation);