import React, { PureComponent } from 'react';
import './style/childForm.scss';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SelectLocation from '../shared/SelectLocation';
import SelectJusanLevel from '../shared/SelectJusanLevel';
import SelectAmsanLevel from '../shared/SelectAmsanLevel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from '../shared/DatePicker';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import { createChild } from '../../store/actions/parentActions';

class AddChildForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            birthday: new Date(),
            familyId: props.parentId,
            school: "",
            activeDate: new Date(),
            inactiveDate: null,
            status: false,
            amsanLevel: 0,
            jusanLevel: 0,
            primaryCenter: "",
            secondaryCenter: "",
            credits: 0,
            allergies: "",
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDateChange = date => {
        this.setState({ birthday: date });
    }

    handleSwitch = name => event => {
        if(!event.target.checked){
            this.setState({ inactiveDate: new Date() });
        } else {
            this.setState({ inactiveDate: null });
        }
        this.setState({ [name]: event.target.checked });
    }

    handleSave = () => {
        const { firstName, lastName, primaryCenter } = this.state;
        if(firstName.length === 0 || lastName.length === 0 || primaryCenter.length === 0) {
            this.props.handleOpenPopup("error", "Please fill in required fields!");
        } else {
            this.props.createChild(this.state);
            this.setState({
                firstName: "",
                lastName: "",
                birthday: new Date(),
                familyId: this.props.parentId,
                school: "",
                activeDate: new Date(),
                inactiveDate: null,
                status: "active",
                amsanLevel: 0,
                jusanLevel: 0,
                primaryCenter: "",
                secondaryCenter: "",
                credits: 0,
                allergies: ""
            });
            this.props.toggleForm();
            this.props.handleOpenPopup("success", `${firstName} ${lastName} is added!`);
        }
    }

    render() {
        return (
            <form className="details-form">
                <div className="fields-container" >
                    <TextField
                        className="field"
                        variant="outlined"
                        label="First Name*"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange('firstName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Last Name*"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange('lastName')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="Allergies"
                        name="allergies"
                        value={this.state.allergies}
                        onChange={this.handleChange('allergies')}
                        margin="normal"/>
                    <TextField
                        className="field"
                        variant="outlined"
                        label="School"
                        name="school"
                        value={this.state.school}
                        onChange={this.handleChange('school')}
                        margin="normal"/>
                    <SelectLocation
                        className="field" 
                        title="Primary Center*"
                        name="primaryCenter"
                        value={this.state.primaryCenter} 
                        handleChange={this.handleSelect}/>
                    <SelectLocation
                        className="field" 
                        title="Secondary Center"
                        name="secondaryCenter"
                        value={this.state.secondaryCenter} 
                        handleChange={this.handleSelect}/>  
                    <SelectJusanLevel 
                        className="field"
                        name="jusanLevel"
                        value={this.state.jusanLevel} 
                        handleChange={this.handleSelect}/>
                    <SelectAmsanLevel
                        className="field"
                        name="amsanLevel"
                        value={this.state.amsanLevel} 
                        handleChange={this.handleSelect}/>  
                    <TextField
                            className="field"
                            variant="outlined"
                            label="Credits"
                            name="credits"
                            value={this.state.credits}
                            onChange={this.handleChange('credits')}
                            margin="normal"/>  
                    <DatePicker
                        className="field" 
                        label="Birthday" 
                        dateValue={this.state.birthday} 
                        handleDateChange={this.handleDateChange}/>   
                    
                    <FormControlLabel
                        control={<Switch 
                                    checked={this.state.status} 
                                    onChange={this.handleSwitch('status')} 
                                    value={false} />}
                        label="Active"
                        labelPlacement="start"/>
                    
                </div>
                <div className="form-action-container">
                    <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSave}>
                        save
                    </Button>
                </div>
            </form>
        );
    }
}

export default connect(null, { createChild })(AddChildForm);