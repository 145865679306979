import React, { Component } from 'react';
import SlidingPane from '../shared/SlidingPane';
import LocationFilter from '../shared/LocationFilter';
import Searchbar from '../shared/Searchbar';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SubscriptionForm from './SubscriptionForm';
import { connect } from 'react-redux';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import SubscriptionGrid from './SubscriptionGrid';
import '../invoice/styles/invoiceform.scss';
import './styles/subscriptions.scss';
import { getAllStripePlans } from '../../store/actions/stripeActions';

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
            search: "",
            alert: {
                variant: "success",
                open: false,
                message: ""
            },
            newSubscription: null
        }
    }

    componentDidMount() {
        if(!this.props.stripePlans) {
            this.props.getAllStripePlans();
        }

        if(window.localStorage.getItem(this.props.profile.firstName)) {
            let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
            this.setState({ locationFilter: settings.locationFilter })
        } else {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.locationFilter !== this.state.locationFilter) {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }

    handleChange = e => {
        this.setState({ search: e.target.value });
    }
    
    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    updateNewSubscriber = (newSubscriber) => {
        this.setState({ newSubscriber });
    }

    render() {
        return (
            <div className="tab-container">
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) &&
                    <SlidingPane header="Create a subscription" toggleForm={this.toggleForm} showForm={this.state.showForm} width={80}>
                        {this.state.showForm && <SubscriptionForm 
                                                    toggleForm={this.toggleForm} 
                                                    showForm={this.state.showForm} 
                                                    handleOpenPopup={this.handleOpenPopup} 
                                                    updateNewSubscriber={this.updateNewSubscriber}/>}
                    </SlidingPane>
                }

                <div className="list-actions-container">
                    <LocationFilter 
                        value={this.state.locationFilter}
                        handleChange={this.handleFilter} />
                    <Searchbar 
                        search={this.state.search}
                        handleChange={this.handleChange}/>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                    <div className="action-container">
                        <h4 style={{marginRight: '1rem'}}>Create a subscription</h4>
                        <Fab size="small" color="secondary" aria-label="add" onClick={this.toggleForm}>
                            <AddIcon/> 
                        </Fab>
                    </div>}
                </div>
                <div className="subscription-grid-container">
                    <SubscriptionGrid handleOpenPopup={this.handleOpenPopup} search={this.state.search} newSubscriber={this.state.newSubscriber}/>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps, { getAllStripePlans })(Subscriptions);