import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../shared/DatePicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { convertToMilitaryTime } from '../../js/helpers';
import './style/editsessionform.scss';
import { connect } from 'react-redux';
import { isAfter, addWeeks } from 'date-fns'
import { updateSessionStatus } from '../../store/actions/sessionActions';


class EditSessionForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(props.session.date),
            startTime: convertToMilitaryTime(this.props.session.startTime),
            endTime: convertToMilitaryTime(this.props.session.endTime),
            applyToFutureSessions: false,
        }
    }

    componentDidMount() {
        console.log(convertToMilitaryTime(this.props.session.startTime))
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleDateChange = date => {
        this.setState({  date });
    }

    handleTime = event => {
        this.setState({ [event.target.name]: event.target.value});
    }

    handleSave = () => {
        let { date, startTime, endTime, applyToFutureSessions } = this.state;

        if(applyToFutureSessions) {
            let i = 1;
            let updatedSession = {
                date: this.state.date,
                startTime: this.state.startTime,
                endTime: this.state.endTime
            }
            this.props.updateSessionStatus(this.props.session.id, updatedSession)

            this.props.session.sessions.forEach((sessionRef) => {
                    let session = this.props.sessions[sessionRef.id]
                    if(session) {
                        if(isAfter(session.date.toDate(), this.state.date)) {
                            let updatedSession = {
                                date: addWeeks(this.state.date, i),
                                startTime: this.state.startTime,
                                endTime: this.state.endTime
                            }
                            i++;
                            this.props.updateSessionStatus(sessionRef.id, updatedSession)
                        }
                    }
            })

        } else {
            let updatedSession = {
                date: this.state.date,
                startTime: this.state.startTime,
                endTime: this.state.endTime
            }
            this.props.updateSessionStatus(this.props.session.id, updatedSession)
        }
    }

    handleCheck = event => {
        this.setState({ applyToFutureSessions: event.target.checked});
    };

    render() {

        console.log(this.state)

        return (
            <div className="edit-session-container">
                <DatePicker 
                    label="Date" 
                    className="field"
                    dateValue={this.state.date} 
                    handleDateChange={this.handleDateChange} />  
                <TextField
                    name="startTime"
                    id="time"
                    label="Start time"
                    type="time"
                    defaultValue={this.state.startTime}
                    className="field"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }}
                    onChange={this.handleTime} />
                <TextField
                    name="endTime"
                    id="time"
                    label="End time"
                    type="time"
                    defaultValue={this.state.endTime}
                    className="field"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }} 
                    onChange={this.handleTime}/>

                <FormControlLabel
                    control={
                        <Checkbox
                        checked={this.state.applyToFutureSessions}
                        onChange={this.handleCheck}
                        color="primary"
                        />
                    }
                    label="Apply to future sessions"/>

                <Button className="form-action-btn" variant="contained" color="secondary" onClick={this.handleSave}>
                    Save
                </Button>
            </div>
        )
    }
}

export default connect(null, { updateSessionStatus })(EditSessionForm)