import React, { PureComponent } from 'react';
import '../style/navbar.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LocationFilter from '../../shared/LocationFilter';

import Menu from './Menu';

class Navbar extends PureComponent {
    render() {
        return (
            <div className="layout-navbar-container">
                <Menu />
                <div className="mobile-navbar-container">
                    <LocationFilter 
                        value={this.props.locationFilter} 
                        handleChange={this.props.handleFilter}/>
                    <div className={this.props.state.className} onClick={this.props.toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        profile: state.firebase.profile
    }
}

export default withRouter(connect(mapStateToProps)(Navbar));