import React from 'react';
import './widgetContainer.scss';

const WidgetContainer = props => {
    return (
        <div className="widget-container">
            {props.icon && <div className="widget-icon">
                <img className="icon" src={props.icon} alt="abakidz widget"/>
            </div>}
            {/* <div className="widget-header">
                <h4 className="title">{props.title}</h4>
            </div> */}
            {/* <div className="widget-main"> */}
                { props.children }
            {/* </div> */}
        </div>
    );
}

export default WidgetContainer;