import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ParentRenderer from './ParentRenderer';
import ChildrenRenderer from './ChildrenRenderer';
import InvoiceLinkRenderer from './InvoiceLinkRenderer';
import PayInvoiceRenderer from './PayInvoiceRenderer';
import ChangeInvoiceStatusRenderer from './ChangeInvoiceStatusRenderer';
import { connect } from 'react-redux';
import { isLocationMatch } from '../../js/helpers';
import axios from 'axios';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles/invoice.scss';
import { format, addDays } from 'date-fns';

class InvoiceGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Reference",
                    field: "reference", 
                    cellRenderer: 'payInvoiceRenderer'
                }, {
                    headerName: "Parent",
                    field: "parent", 
                    cellRenderer: 'parentRenderer',
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter", 
                }, {
                    headerName: "Children",
                    field: "children", 
                    cellRenderer: 'childrenRenderer'
                }, {
                    headerName: "Invoice Date",
                    field: "invoiceDate", 
                }, { 
                    headerName: "Due Date", 
                    field: "dueDate" , 
                }, {
                    headerName: "Status",
                    field: "status", 
                    cellClassRules: {
                        "status-grey": (params) => cellStyleRule(params) === 2,
                        "status-green": (params) => cellStyleRule(params) === 1,
                        "status-yellow": (params) => cellStyleRule(params) === 0,
                        "status-red": (params) => cellStyleRule(params) === -1,
                    },
                }, {
                    headerName: "Total",
                    field: "total",
                }, {
                    headerName: "Next Cycle",
                    field: "nextCycle", 
                }, {
                    headerName: "Invoice PDF",
                    field: "invoicePdf",
                    cellRenderer: 'invoiceLinkRenderer'
                }, {
                    headerName: "Change Status",
                    field: "status",
                    cellRenderer: 'changeInvoiceStatusRenderer'
                }],
            },
            frameworkComponents: {
                parentRenderer: ParentRenderer,
                childrenRenderer: ChildrenRenderer,
                invoiceLinkRenderer: InvoiceLinkRenderer,
                payInvoiceRenderer: PayInvoiceRenderer,
                changeInvoiceStatusRenderer: ChangeInvoiceStatusRenderer
            },
            rowData: [],
            paginationPageSize: 20,
        }
    }

    componentDidMount() {
        // axios.get("https://us-central1-abakidz-student-application.cloudfunctions.net/getStripeInvoices")
        // .then(res => {
        //     let data = res.data.data;
        //     // let data = res.json();
        //     // console.log(data)
        //     // console.log(data)
        //     let rowData = convertInvoiceDateToRow(data, this.props.students, this.props.locationFilter)
        //     this.setState({ rowData });
        // })
        // .catch(err => this.props.handleOpenPopup('error', err.message));

        axios.get("https://us-central1-abakidz-student-application.cloudfunctions.net/getStripeInvoices")
        .then(res => {
            let data = res.data.data;
            // let data = res.json();
            // console.log(data)
            // console.log(data)
            let rowData = convertInvoiceDateToRow(data, this.props.students, this.props.locationFilter)
            this.setState({ rowData });
        })
        .catch(err => this.props.handleOpenPopup('error', err.message));
        
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }
        if(prevProps.newInvoice !== this.props.newInvoice && this.props.newInvoice && this.props.students) {
            let newInvoice = convertInvoiceDateToRow([this.props.newInvoice], this.props.students, this.props.locationFilter);
            this.gridApi.updateRowData({ add: newInvoice, addIndex: 0 });
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        // this.state.gridOptions.api.showLoadingOverlay()
    };

    handleNext = () => {
        // if(this.state.rowData.length > 0) {
        //     let lastInvoiceId =  this.state.rowData[this.state.rowData.length - 1].id 
        //     axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getNextStripeInvoices", {
        //         lastInvoiceId
        //     }).then(res => {
        //         let data = res.data.data
        //         let rowData = convertInvoiceDateToRow(data, this.props.students, this.props.locationFilter)
        //         this.setState({ rowData });
        //     })
        //     .catch(err => this.props.handleOpenPopup('error', err.message));
        // }
    }

    handlePrev = () => {
        // if(this.state.rowData.length > 0) {
        //     let firstInvoiceId = this.state.rowData[0].id 
        //     axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/getPrevStripeInvoices", {
        //         firstInvoiceId
        //     }).then(res => {
        //         let data = res.data.data
        //         let rowData = convertInvoiceDateToRow(data, this.props.students, this.props.locationFilter)
        //         this.setState({ rowData });
        //     })
        //     .catch(err => this.props.handleOpenPopup('error', err.message));
        // }
    }   

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '95%', width: '100%' }}>
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    rowData={this.state.rowData}
                    quickFilter={this.props.search || this.props.locationFilter}
                    frameworkComponents={this.state.frameworkComponents}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
                <div className="invoices-pagination-button-container">
                    <button className="pagination-btn" onClick={this.handlePrev}>Previous</button>
                    <button className="pagination-btn" onClick={this.handleNext}>Next</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.data.students
    }
}

export default connect(mapStateToProps, {})(InvoiceGrid);

const convertInvoiceDateToRow = (data, students, locationFilter) => {
    let rowData = []
    data.forEach((row) => {
        let temp = row.metadata.next_billing_cycle ? new Date(row.metadata.next_billing_cycle) : "";
        let start = row.metadata.date_billed ? new Date(row.metadata.date_billed) : "";
        let nextCycle = ""
        let dueDate = ""
        let startDate = ""
        let total = row.amount_due * 0.01
        let altNext = new Date(row.metadata.next_billing_cycle * 1000)
        let altStart = new Date(row.metadata.date_billed * 1000)

        if(start instanceof Date && start.toString() !== "Invalid Date") {
            dueDate = format(addDays(start, 7), "MMM d, yyyy"); 
            startDate = format(start, "MMM d, yyyy"); 
        }  else if(altStart instanceof Date && altStart.toString() !== "Invalid Date"){
            dueDate = format(addDays(altStart, 7), "MMM d, yyyy"); 
            startDate = format(altStart, "MMM d, yyyy"); 
        }

        if(temp instanceof Date && temp.toString() !== "Invalid Date") {
            nextCycle = format(temp, "MMM d, yyyy");
        } else if(altNext instanceof Date && altNext.toString() !== "Invalid Date"){
            nextCycle = format(altNext, "MMM d, yyyy");
        }

        let childrenData = []
        

        if(row.metadata.children) {
            childrenData = row.metadata.children.split(",");
        }

        let current = {
            id: row.id,
            reference: row.number,
            parent: row.customer_name ? row.customer_name : "Unavailable",
            children: childrenData,
            invoiceDate: startDate ? startDate : "",
            dueDate: dueDate ? dueDate : "",
            status: row.status,
            nextCycle,
            total: "$ " + total.toFixed(2),
            primaryCenter: row.metadata.primaryCenter ? row.metadata.primaryCenter : "",
            secondaryCenter: row.metadata.secondaryCenter ? row.metadata.secondaryCenter : "",
            familyId: row.metadata.familyId ? row.metadata.familyId : "",
            invoicePdf: row.invoice_pdf,
            hostedInvoiceUrl: row.hosted_invoice_url,
            students,
        }
        // if(isLocationMatch(locationFilter, row.metadata.primaryCenter)){
            rowData.push(current);
        // }
    });

    return rowData;
} 

const cellStyleRule = (params) => {
    if(params.value === "paid") return 1;
    else if(params.value === "open") return 0;
    else if(params.value === "draft") return  2;
    else return -1;
}