import React, { PureComponent } from 'react';
import editIcon from '../../resources/images/edit-color.png';
// import Alert from '../shared/Alert';
import { connect } from 'react-redux';
// import {  } from '../../store/actions/sessionActions';
import '../subscriptions/styles/subscriptions.scss';

class EditSession extends PureComponent {  
    render() {
        return (
            <button className="cancel-subscription-button" onClick={() => this.props.data.toggleForm(this.props.data)}>
                <img className="cancel-icon" src={editIcon} alt=""/>
            </button>
        )
    }
}

export default connect(null, {  })(EditSession);