import React, { PureComponent } from 'react';
import './styles/invoiceform.scss';
import { connect } from 'react-redux';
import { updateStudent } from '../../store/actions/studentActions';
import { compose } from 'redux';
import {format, addMonths, differenceInDays} from 'date-fns';
import axios from 'axios';

// Components
import ServiceItem from './ServiceItem';
import Button from '@material-ui/core/Button';
import SelectFamily from '../shared/SelectFamily';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../shared/DatePicker';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ListItemText from '@material-ui/core/ListItemText';
import CustomSelect from '../shared/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';
import ReferralGrid from '../parents/ReferralGrid';

class InvoiceForm extends PureComponent {
    constructor() {
        super();
        this.state = {
            familyId: "",
            date: new Date(),
            dueDate: new Date(),
            taxTreatment: "exempt",
            reference: "",
            items: [],
            subtotal: 0,
            salesTax: 0,
            total: 0,
            additional: `A 24-hour advance notice is required to cancel or reschedule a class in order to avoid any charges or loss of class credit.\n\nPlease note that students will no longer be allowed to attend classes prior to receiving payment for them.`,
            discount: null,
            selectedFamily: "",
            recurring: "monthly",
            nextBillingCycle: null,
        }
    } 

    componentDidMount() {
        let now = new Date();
        now.setDate(now.getDate() + 7);
        this.setState({ dueDate: now })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.items !== this.state.items) {
            let subtotal = 0;
            for(let i = 0; i < this.state.items.length; i++) {
                let item = this.state.items[i];
                subtotal += Number(item.amount);
            }
            let discount = 1;
            if(this.state.discount === "12-months-discount") discount = .85; 
            this.setState(prevState => ({ subtotal, total: subtotal * discount }));
        }
        if(prevState.familyId !== this.state.familyId) {
            let selectedFamily = this.props.families[this.state.familyId];
            this.setState({ 
                children: [], 
                selectedFamily 
            });
            selectedFamily.students.forEach(student => {
                this.setState(prevState => ({ children: [...prevState.children, student.id]}));
            });
        }
        // if(prevState.recurring !== this.state.recurring) {
        //     if(this.state.recurring === "annually") {
        //         this.setState({ discount: "12-months-discount"})
        //     }
        // }
        // console.log(this.state)
    }

    componentWillUnmount() {
        this.setState({
            familyId: "",
            date: new Date(),
            dueDate: new Date(),
            taxTreatment: "exempt",
            items: [],
            subtotal: 0,
            salesTax: 0,
            total: 0,
            additional: `A 24-hour advance notice is required to cancel or reschedule a class in order to avoid any charges or loss of class credit.\n\nPlease note that students will no longer be allowed to attend classes prior to receiving payment for them.`,
            discount: null,
            selectedFamily: "",
            recurring: "monthly",
            nextBillingCycle: null,
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if(event.target.name === "recurring") {
            if(event.target.value === "monthly") this.setState({ discount: null, items: [] }); 
            // else if(event.target.value === "quarterly") this.setState({ discount: "3-months-discount", items: []  }); 
            // else if(event.target.value === "semi-annually") this.setState({ discount: "6-months-discount", items: []  }); 
            else if(event.target.value === "annually") this.setState({ discount: "12-months-discount", items: []  }); 
        }
    }

    handleAltChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleDateChange = date => {
        this.setState({ date: date });
    }

    handleDueDateChange = date => {
        this.setState({ dueDate: date });
    }

    handleAddService = () => {
        let item = {
            package: "package-a",
            child: "",
            description: "",
            quantity: 1,
            unitPrice: 0,
            discount: 0,
            amount: 0,
            packageType: ""
        }
        this.setState(prevState => ({ items: [...prevState.items, item] }));
    }

    handleDeleteItem = (e, i) => {
        let newItems = this.state.items.slice();
        newItems.splice(i, 1)
        this.setState({ items: newItems });
    }

    handleChangeItem = (event, i) => {
        let newItems = this.state.items.slice()
        newItems[i][event.target.name] = event.target.value;
        this.setState({ items: newItems });
    }

    getItemAmount = (itemAmount, unitPrice, quantity, i) => {
        let newItems = this.state.items.slice()
        newItems[i].amount = itemAmount ? itemAmount : 0;
        newItems[i].unitPrice = unitPrice ? unitPrice : 0;
        newItems[i].quantity = quantity ? quantity : 0;
        this.setState({ items: newItems });
    }

    handleDescription = (description, packageType, i) => {
        let newItems = this.state.items.slice()
        newItems[i].description = description
        newItems[i].packageType = packageType
        this.setState({ items: newItems });
    }

    handleSend = (e) => {
        e.preventDefault();
        if(this.props.showForm){
            this.props.toggleForm();
            let daysUntilDue = differenceInDays(this.state.date, this.state.dueDate)
            daysUntilDue *= -1;
            let nextBillingCycle = format(addMonths(this.state.date, 1), "MMMM dd, yyyy")
            if(this.state.recurring === 'monthly') nextBillingCycle = addMonths(this.state.date, 1);
            // else if(this.state.recurring === 'quarterly') nextBillingCycle = addMonths(this.state.date, 3)
            // else if(this.state.recurring === 'semi-annually') nextBillingCycle = addMonths(this.state.date, 6)
            else if(this.state.recurring === 'annually') nextBillingCycle = addMonths(this.state.date, 12)
            const { selectedFamily } = this.state
            
            let invoice = {
                id: this.state.familyId,
                parentOneFirstName: selectedFamily.parentOneFirstName,
                parentOneLastName: selectedFamily.parentOneLastName,
                stripeId: selectedFamily.stripeId,
                primaryCenter: selectedFamily.primaryCenter,
                secondaryCenter: selectedFamily.secondaryCenter,
                primaryEmail: selectedFamily.primaryEmail,
                cellNumber: selectedFamily.cellNumber,
                city: selectedFamily.city,
                address: selectedFamily.address,
                zipCode: selectedFamily.zipCode,
                state: selectedFamily.zipCode,
                daysUntilDue,
                items: this.state.items,
                additional: this.state.additional,
                nextBillingCycle,
                discount: this.state.discount,
                dateBilled: new Date(),
                recurring: this.state.recurring
            }

            axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/processStripeInvoiceAPI", {
                ...invoice
            }).then(res => {
                this.props.handleOpenPopup("success", "Invoice sent to " + invoice.parentOneFirstName + " " + invoice.parentOneLastName + "!")
                this.props.handleNewInvoice(res.data)
                this.state.items.forEach(item => {
                    let updatedChild = {
                        packageId: item.package,
                        packageDescription: item.packageType ? item.packageType : "",
                        dateBilled: new Date()
                    }
                    if(item.child) {
                        this.props.updateStudent(item.child, updatedChild)
                    }
                })
            })
            .catch(err => {
                console.log(err)
                this.props.handleOpenPopup("error", err.message)
            })
        }
    }

    render() {
        return (
            <form className="details-form" id="invoice-form">
                <div className="customer-information-container">
                    <SelectFamily
                        style={{ marginTop: '.5rem' }}
                        className="field"
                        label="Select Customer"
                        value={this.state.familyId}
                        handleChange={this.handleChange}
                        margin="dense"/>
                    <CustomSelect
                        style={{ marginTop: '.5rem' }}
                        className="dense-field"
                        name="recurring"
                        value={this.state.recurring} 
                        placeholder="Recurring"
                        margin="dense"
                        handleChange={this.handleChange}>
                        <MenuItem value={"monthly"}>Monthly</MenuItem>
                        {/* <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                        <MenuItem value={"semi-annually"}>Semi-annually</MenuItem> */}
                        <MenuItem value={"annually"}>Annually</MenuItem>
                    </CustomSelect>
                    <DatePicker 
                        className="custom-field" 
                        variant="inline"
                        label="Date" 
                        dateValue={this.state.date} 
                        handleDateChange={this.handleDateChange}/>
                    <DatePicker 
                        className="custom-field" 
                        label="Due date" 
                        dateValue={this.state.dueDate} 
                        handleDateChange={this.handleDueDateChange}/>  
                </div>
                <Divider />
                <div className="services-container">
                    <table className="service-table">
                        <thead>
                            <tr>
                                <th>Student</th>
                                <th>Package Type</th>
                                <th>Qt</th>
                                <th>Unit Price</th>
                                <th>Discount</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.items.map((item, i)=> {
                                return <ServiceItem 
                                            key={i} 
                                            i={i} 
                                            recurring={this.state.recurring}
                                            values={item}
                                            students={this.props.students}
                                            children={this.state.children}
                                            getItemAmount={this.getItemAmount}
                                            handleDescription={this.handleDescription}
                                            handleChangeItem={this.handleChangeItem}
                                            handleDeleteItem={this.handleDeleteItem}
                                            stripePlans={this.props.stripePlans}/>
                            })}
                            <tr>
                                <th className="add-package-container">
                                    <Fab size="small" color="secondary" aria-label="add" onClick={this.handleAddService}>
                                        <AddIcon />
                                    </Fab>
                                    <ListItemText style={{ color: '#2e3436', marginLeft: '.5rem' }} primary="Add item"/>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Divider />
                <div className="totals-container">
                    <div>
                        <textarea 
                            className="additional-notes-input"
                            rows="5" cols="70"
                            type="text" 
                            name="additional" 
                            value={this.state.additional} 
                            onChange={this.handleChange} 
                            placeholder="Additional notes"/>    
                    </div>
                    <div className="totals">
                        <div className="row">
                            <p className="total-title">Subtotal: </p>
                            <p className="total-num">{this.state.subtotal.toFixed(2)}</p>
                        </div>
                        <div className="row" id="discounts-wrapper">
                            <p className="total-title">Discount:</p>
                            <select 
                                className="discount" 
                                name="discount"
                                value={this.state.discount}
                                onChange={this.handleChange}>
                                <option value={null}>None</option>
                                {/* <option value="3-months-discount">3-Months 5% Discount</option>
                                <option value="6-months-discount">6-Months 10% Discount</option> */}
                                <option value="12-months-discount">12-Months 15% Discount</option>
                            </select>
                        </div>
                        <div className="row">
                            <p className="total-title">Total: </p>
                            <p className="total-num">{this.state.total.toFixed(2)}</p>
                        </div>
                    </div>
                </div>

                <div className="form-action-container" id="invoice-action">
                    <Button 
                        className="form-action-btn" 
                        variant="contained" 
                        color="secondary" 
                        onClick={(e) => this.handleSend(e)}>
                        Send
                    </Button>
                </div>

                <div>
                    {this.state.selectedFamily && 
                        <ReferralGrid 
                            parentId={this.state.familyId} 
                            parent={this.state.selectedFamily}/>
                    }
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        family: state.parents,
        students: state.firestore.data.students,
        families: state.firestore.data.families,
        stripePlans: state.stripe.stripePlans
    }
}

export default connect(mapStateToProps, { updateStudent  })(InvoiceForm);