import React, { PureComponent } from 'react';
import { Route, NavLink, Redirect, Switch } from 'react-router-dom';
import Loading from '../shared/Loading';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { connect } from 'react-redux';

import TeacherDetails from './TeacherDetails';
import TeacherScheduleTracker from './TeacherScheduleTracker';

class Teacher extends PureComponent {
    state = {
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }
    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }
    componentDidUpdate() {
        setTimeout(() => {
            const wrapper = document.getElementById('content-container');
            if(wrapper) wrapper.classList.add('active');
        }, 200)
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('content-container');
        wrapper.classList.remove('active')
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        const { teacher } = this.props;
        const { params, url } = this.props.match;
        if(!teacher) {
            return <Loading />;
        }

        return (
            <div className="content-container" id="content-container">
                <header className="header">
                    <h1>{teacher.firstName} {teacher.lastName}</h1>
                    <div className="navbar-container">
                        <NavLink activeClassName="nested-active-route" to={`${url}/details`}>Account</NavLink>
                        <NavLink activeClassName="nested-active-route" to={`${url}/schedule`}>Schedule</NavLink>
                        {/* <NavLink activeClassName="nested-active-route" to={`${props.match.url}/payment`}>Payment History</NavLink> */}
                    </div>
                </header>

                <div className="main-content-container">
                    <Switch>
                        <Redirect exact from={`${url}`} to={`${url}/details`}/>
                        <Route exact path={`${url}/details`} render={props => <TeacherDetails {...props} teacherId={params.id} teacher={teacher} handleOpenPopup={this.handleOpenPopup}/>}/>
                        <Route exact path={`${url}/schedule`} render={props => <TeacherScheduleTracker teacherId={params.id} teacher={teacher} handleOpenPopup={this.handleOpenPopup}/>}/>
                        {/* <Route exact path={`${props.match.url}/payment`} render={props => <PaymentHistory {...props} parentId={id} parent={parent}/>}/> */} */}
                    </Switch>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const id = props.match.params.id;
    const teachers = state.firestore.data.teachers;
    const teacher = teachers ? teachers[id] : null;
    return { teacher };
}

export default connect(mapStateToProps)(Teacher);