import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const SidebarLink = props => {
    return (
        <NavLink exact={ true } className="sidebar-link-container" activeClassName="active" to={props.link} >
            <div className="sidebar-link-icon">
                <img className="sidebar-link-icon-img" src={props.icon} alt={props.icon}/>
            </div>
            <div className="sidebar-link-content">
                <h5 className="link-text">{props.content}</h5>
            </div>
        </NavLink>
    );
}

export default withRouter(SidebarLink);