const initState = {
    stripePlansError: null
}

const stripeReducer = (state = initState, action) => {
    switch(action.type) {
        case "GET_ALL_STRIPE_PLANS":
            return {
                ...state,
                stripePlans: action.stripePlans
            }
        case "GET_ALL_STRIPE_PLANS_ERROR":
            return {
                ...state,
                stripePlansError: action.err
            }
        default: return state;
    }
}

export default stripeReducer;