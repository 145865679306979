import React, { PureComponent } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import DeleteSessionRenderer from './DeleteSessionRenderer';
import TeacherRenderer from './TeacherRenderer';
import { formatMilitaryTime } from '../../js/helpers';
import { format } from 'date-fns';
import StudentsInClassRenderer from './StudentsInClassRenderer';
import EditSessionRenderer from './EditSessionRenderer';
import SlidingPane from '../shared/SlidingPane';
import EditSessionForm from './EditSessionForm';

class SessionsGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            gridOptions: {
                columnDefs: [{
                    headerName: "Date",
                    field: "date",
                }, {
                    headerName: "Start Time",
                    field: "startTime"
                }, {
                    headerName: "End Time",
                    field: "endTime"
                }, {
                    headerName: "Teacher",
                    field: "teacher",
                    cellRenderer: 'teacherRenderer'
                }, {
                    headerName: "Students",
                    field: "numOfStudents",
                    cellRenderer: "studentsInClassRenderer"
                }, {
                    headerName: "Attendance",
                    field: "attendance"
                }, {
                    headerName: "Attendance Rate",
                    field: "attendanceRate",
                }, {
                    headerName: "Edit",
                    field: "edit",
                    cellRenderer: 'editSessionRenderer'
                }, {
                    headerName: "Delete",
                    field: "delete",
                    cellRenderer: 'deleteSessionRenderer'
                }]
            },
            rowData: [],
            frameworkComponents: {
                teacherRenderer: TeacherRenderer,
                deleteSessionRenderer: DeleteSessionRenderer,
                studentsInClassRenderer: StudentsInClassRenderer,
                editSessionRenderer: EditSessionRenderer
            },
            paginationPageSize: 20,
            selectedSession: null
        }
    }

    componentDidMount() {
        if((this.props.teachers && this.props.sessions && this.props.classObj && this.props.attendanceRecords)) {
            let rowData = convertDataToRow(
                this.props.classObj, 
                this.props.sessions, 
                this.props.teachers, 
                this.props.students, 
                this.props.attendanceRecords, 
                this.props.handleOpenPopup,
                this.toggleForm, 
                this.state.showForm);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if((prevProps.teachers !== this.props.teachers && this.props.teachers && this.props.classObj && this.props.students && this.props.attendanceRecords)
        || (prevProps.sessions !== this.props.sessions && this.props.sessions && this.props.classObj && this.props.attendanceRecords)) {
            let rowData = convertDataToRow(
                this.props.classObj, 
                this.props.sessions, 
                this.props.teachers, 
                this.props.students, 
                this.props.attendanceRecords, 
                this.props.handleOpenPopup, 
                this.toggleForm, 
                this.state.showForm);
            this.setState({ rowData });
        }
    }


    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        if(this.props.profile.isTeacher) {
            this.state.gridOptions.columnApi.setColumnVisible('delete', false)
        }
    };

    toggleForm = (props) => {
        this.setState(prevState => ({ 
            selectedSession: props, 
            showForm: !prevState.showForm 
        }));
    }

    render() {
        return (
            <>
                {this.state.showForm && 
                <SlidingPane width={30} header="Edit Session" toggleForm={this.toggleForm} showForm={this.state.showForm}>
                    <EditSessionForm session={this.state.selectedSession} sessions={this.props.sessions}/>
                </SlidingPane>}
            
                <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                    <AgGridReact
                        onGridReady={this.onGridReady}
                        gridOptions={this.state.gridOptions}
                        quickFilter={this.props.search}
                        rowData={this.state.rowData}
                        frameworkComponents={this.state.frameworkComponents}
                        pagination={true}
                        paginationPageSize={this.state.paginationPageSize}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                        }}>
                    </AgGridReact>
                </div>
            </>
        )
    }
}

export default SessionsGrid;

const convertDataToRow = (classObj, sessions, teachers, students, attendanceRecords, handleOpenPopup, toggleForm, showForm) => {
    let rowData = [];

    if(classObj.sessions) {
        classObj.sessions.forEach(session => {
            let row = sessions[session.id];
            if(row) {
                let teacher = teachers[row.teacher.id];
                let teacherName = "";
                if(teacher) {
                    teacherName = teacher.firstName + ' ' + teacher.lastName
                }

                let studentsData = []
                row.students.forEach(studentRef => {
                    let student = students[studentRef.id];
                    if(student) {
                        let currStudent = {
                            name: `${student.firstName} ${student.lastName}`,
                            jusanLevel: student.jusanLevel,
                            amsanLevel: student.amsanLevel
                        };
                        studentsData.push(currStudent)
                    }
                })

                let totalStudents = row.attendanceRecords ? row.attendanceRecords.length : 0;
                let present = 0;
                let absent = 0;
                let other = 0;

                row.attendanceRecords && row.attendanceRecords.forEach(recordRef => {
                    let record = attendanceRecords[recordRef.id];
                    if(record) {
                        if(record.attendance === "present") present++;
                        else if(record.attendance === "absent") absent++;
                        else if(record.attendance === "other") other++;
                    }
                })

                let data = {
                    id: session.id,
                    sessions: classObj.sessions,
                    date: row.date && format(row.date.toDate(), "MMM d, yyyy"),
                    startTime: row.startTime && formatMilitaryTime(row.startTime),
                    endTime: row.endTime && formatMilitaryTime(row.endTime),
                    numOfStudents: row.students.length,
                    students: studentsData,
                    teacher: teacherName,
                    teacherId: row.teacher.id,
                    attendance: `${present} - ${absent} - ${other}`,
                    attendanceRate: present === 0 && totalStudents === 0 ? `${0}%` : `${(present / totalStudents).toFixed(2) * 100}%`,
                    handleOpenPopup: (code, message) => handleOpenPopup(code, message),
                    toggleForm, 
                    showForm
                }
                rowData.push(data)
            }
        })  
    }
    rowData.sort((a, b) => new Date(b.date) - new Date(a.date))
    return rowData;
}