export const createLocation = (location) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection("locations").add({
            ...location,
            createdAt: new Date(),
        }).then(() => {
            dispatch({ type: "CREATE_LOCATION", location });
        }).catch(err => {
            dispatch({ type: "CREATE_LOCATION_ERROR", err});
        });
    }
}

export const updateLocation = (locationId, updatedLocation) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection("locations").doc(locationId).update({
            ...updatedLocation
        }).then(() => {
            dispatch({ type: "UPDATE_LOCATION", location: updatedLocation });
        }).catch(err => {
            dispatch({ type: "UPDATE_LOCATION_ERROR", err });
        });
    }
}

export const deleteLocation = (locationId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection("locations").doc(locationId).delete()
        .then((location) => {
            dispatch({ type: "DELETE_LOCATION", location });
        }).catch(err => {
            dispatch({ type: "DELETE_LOCATION_ERROR", err });
        });
    }
}