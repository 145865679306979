import React from 'react';
import './styles/invoice.scss';

const InvoiceFormHeader = (props) => {
    return (
        <div className="invoice-form-header-container">
            <div className="field-container">
                <h5 className="field-header">Customer</h5>
                <select 
                    className="input"
                    name="familyId"
                    value={props.familyId}
                    placeholder="Select Customer"
                    onChange={props.handleChange}>
                        <option value="" className="option"></option>
                        {props.families && props.families.slice().sort((a, b) => a.parentOneFirstName - b.parentOneFirstName).map(family => {
                            return <option className="option" key={family.id} value={family.id}>{family.parentOneFirstName} {family.parentOneLastName}</option>
                        })}
                </select>
            </div>
            <div className="field-container">
                <h5 className="field-header">Package Type</h5>
                <select 
                    className="input"
                    name="type"
                    value={props.type}
                    placeholder="Type"
                    onChange={props.handleChange}>
                        <option className="option" value="monthly">Monthly</option>
                        <option className="option" value="annually">Annually</option>
                </select>
            </div>
            <div className="field-container">
                <h5 className="field-header">Date</h5>
                <input 
                    className="input"
                    value={props.date}
                    name="date"
                    onChange={props.handleChange}
                    placeholder="Select Date"
                    type="date"/>
            </div>
            <div className="field-container">
                <h5 className="field-header">Days until due</h5>
                <input 
                    className="input"
                    value={props.days_until_due}
                    name="days_until_due"
                    onChange={props.handleChange}
                    placeholder="Days until due:"
                    type="number"/>
            </div>
        </div>

    )
}

export default InvoiceFormHeader;