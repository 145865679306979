import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { Link } from 'react-router-dom';
import { passwordReset } from '../../store/actions/authActions';
import { connect } from 'react-redux';

class ForgotPassword extends PureComponent {
    constructor() {
        super();
        this.state = {
            email: "",
            alert: {
                variant: "Link to reset your password has been e-mailed",
                open: false,
                message: ""
            }
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.authError && ((prevProps.authError !== this.props.authError))) {
            this.handleOpenPopup("error", this.props.authError);
        }
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { 
                                        variant: prevState.alert.variant, 
                                        open: false, 
                                        message: prevState.alert.message 
                                    }
                                }));
    }

    handleChange = name => e => {
        this.setState({ [name]: e.target.value });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.passwordReset(this.state.email);
        this.handleOpenPopup("success", `Link to reset your password has been e-mailed to ${this.state.email}!`);
    }

    render() {
        return(
            <div className="login-wrapper">
                <form className="login-form">
                    <TextField
                        id="outlined-email-input"
                        label="email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"/>
                    <Button 
                        className="login-btn"
                        variant="contained" 
                        onClick={this.handleSubmit}
                        color="secondary">
                        RESET PASSWORD
                    </Button>
                </form>
                <div className="signup-forgot-password-wrapper">
                    <Link to="/">login</Link>
                    <Link to="/signup">sign up</Link>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authError: state.auth.authError
    }
}

export default connect(mapStateToProps, { passwordReset })(ForgotPassword);