import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import './styles/dashboard.scss';
import Birthdays from './widgets/birthdays/Birthdays';
import Notifications from './widgets/notifications/Notifications';
// import FinancialReport from './widgets/financialReport/FinancialReport';
// import Marketing from './widgets/marketing/Marketing';
import LocationFilter from '../shared/LocationFilter';
import AgeGroup from './widgets/ageGroup/AgeGroup';
import axios from 'axios'

class Dashboard extends PureComponent {
    state = {
        locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
        data: null
    }

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('dashboard-container');
            if(wrapper){
                wrapper.classList.add('active')
            }
        }, 200);
        if(window.localStorage.getItem(this.props.profile.firstName)) {
            let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
            this.setState({ locationFilter: settings.locationFilter })
        } else {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    componentWillUnmount() {
        const wrapper = document.getElementById('dashboard-container');
        wrapper.classList.remove('active')
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.profile.primaryCenter && this.props.profile.primaryCenter) {
            this.setState({ locationFilter: this.props.profile.primaryCenter });
        }
        if(prevState.locationFilter !== this.state.locationFilter) {
            let settings = {
                locationFilter: this.state.locationFilter
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    handleFilter = e => {
        this.setState({ locationFilter: e.target.value });
    }

    onDeleteSessionAndAttendanceRecords = () => {
        // https://us-central1-abakidz-student-application.cloudfunctions.net/deleteOldData
        axios.get("https://us-central1-abakidz-student-application.cloudfunctions.net/deleteOldData")
        .then(res => {
            console.log("res: ", res)
        })
    }

    onCleanStudentAttendanceRecords = () => {
        // https://us-central1-abakidz-student-application.cloudfunctions.net/deleteOldData
        axios.get("https://us-central1-abakidz-student-application.cloudfunctions.net/cleanStudent")
        .then(res => {
            console.log("res: ", res)
        })
    }

    render() {
        // console.log(this.state.data);
        return (
            <div className="dashboard-container" id="dashboard-container">
                {/* <button onClick={() => this.onDeleteSessionAndAttendanceRecords()}>Delete old sessions + attendance records</button>
                <button onClick={() => this.onCleanStudentAttendanceRecords()}>Clean student after deleting session + attendance records</button> */}
                <LocationFilter 
                    value={this.state.locationFilter}
                    handleChange={this.handleFilter}/>
                <div className="widgets-container">
                    <Birthdays locationFilter={this.state.locationFilter} />
                    <Notifications locationFilter={this.state.locationFilter} notifications={this.props.notifications} />
                    {/* {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <FinancialReport locationFilter={this.state.locationFilter} />} */}
                    <AgeGroup locationFilter={this.state.locationFilter} />
                    {/* {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <Marketing locationFilter={this.state.locationFilter} />} */}
                </div>
            </div>    
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        profile: state.firebase.profile,
        notifications: state.firestore.ordered.notifications,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'notifications', limit: 5, orderBy: ['time', 'desc']}
    ])
)(Dashboard);