import React, { PureComponent } from 'react';
import DayOfMonth from './DayOfMonth';
import { format } from 'date-fns';

class MobileCalendarHeader extends PureComponent {
    render() {
        let displayDays = this.props.days.map(date => {
                            return <DayOfMonth 
                                        key={date.fullDate} 
                                        {...date} 
                                        selectedDate={this.props.selectedDate}
                                        currentDate={this.props.currentDate}
                                        handleSelectDay={this.props.handleSelectDay}/>
                        });

        return (
            <div className="mobile-calendar-header-container">
                <div className="days-of-week-container">
                    { displayDays }
                </div>

                <div className="display-date-controller-container">
                    <div className="header row flex-middle">
                        <div className="col col-start">
                            <div className="icon" onClick={this.props.prevWeek}>
                                chevron_left
                            </div>
                        </div>
                        <div className="">
                            <span>{format(this.props.selectedDate, "EEEE MMMM d, yyyy")}</span>
                        </div>
                        <div className="col col-end" onClick={this.props.nextWeek}>
                            <div className="icon">chevron_right</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileCalendarHeader