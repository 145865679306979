import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const SelectSubject = props => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const { subjects } = props;
    const formControl = {
        minWidth: 120,
        marginTop: '1rem',
        marginBottom: '.5rem'
    }

    return (
        <FormControl variant="outlined" className={props.className} style={formControl}>
            <InputLabel ref={inputLabel}>Select Subject*</InputLabel>
            <Select
                name="subject"
                value={props.value}
                onChange={props.handleChange}
                input={<OutlinedInput labelWidth={labelWidth}/>}>
                {subjects && subjects.map(subject => {
                    return <MenuItem key={subject.id} value={subject.id}>{subject.title}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = state => {
    return {
        subjects: state.firestore.ordered.subjects
    }
}
  
export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'subjects' }
    ])
)(SelectSubject);
    