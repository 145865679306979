import React, { PureComponent } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProgressForm from './ProgressForm';
import Alert from '../shared/Alert';
import { deleteStudentFromSession, addStudentToMakeupClass } from '../../store/actions/sessionActions';
import { updateAttendance } from '../../store/actions/attendanceActions';
import { updateStudent } from '../../store/actions/studentActions';
import { connect } from 'react-redux';

import './style/sessionStudentItem.scss';

class SessionStudentItem extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            openAlert: false,
            attendance: "",
            jusanLevel: props.student.jusanLevel,
            amsanLevel: props.student.amsanLevel,
            jusanEndPage: '',
            amsanEndPage: '',
            jusanHomework: '',
            amsanHomework: '',
            jusanNotes: '',
            amsanNotes: '',
            comments: ''
        }
    }

    componentDidMount() {
        if(this.props.session && this.props.session.attendanceRecords) {
            const { attendanceRecords } = this.props.session;
            attendanceRecords.forEach(recordRef => {
                let currRecord = this.props.attendanceRecords[recordRef.id]
                this.currRecord = currRecord;

                if(currRecord) {
                    if(currRecord.student.id === this.props.studentId) {
                        this.setState({
                            attendance: currRecord.attendance,
                            jusanLevel: currRecord.jusanLevel.length > 1 ? currRecord.jusanLevel : this.props.student.jusanLevel,
                            amsanLevel: currRecord.amsanLevel.length > 1 ? currRecord.amsanLevel : this.props.student.amsanLevel,
                            jusanEndPage: currRecord.jusanEndPage,
                            amsanEndPage: currRecord.amsanEndPage,
                            jusanHomework: currRecord.jusanHomework,
                            amsanHomework: currRecord.amsanHomework,
                            jusanNotes: currRecord.jusanNotes,
                            amsanNotes: currRecord.amsanNotes,
                            comments: currRecord.comments
                        });
                    }
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.saveToggle !== this.props.saveToggle) {
            let record = Object.assign({}, this.state);
            let updatedStudent = { };
            let credits = 0;

            this.props.updateAttendance(this.props.studentId, this.props.sessionId, record);
            
            if(this.state.amsanLevel !== this.props.student.amsanLevel 
            || this.state.jusanLevel !== this.props.student.jusanLevel) {                
                updatedStudent = {
                    jusanLevel: this.state.jusanLevel,
                    amsanLevel: this.state.amsanLevel,
                }
                
            }
        
            if(this.props.student.credits && Number(this.props.student.credits) > 0) {
                credits = Number(this.props.student.credits)
                if(this.state.attendance === "present") {
                    credits--;
                }
            }
            updatedStudent = {
                ...updatedStudent,
                credits
            }
            this.props.updateStudent(this.props.studentId, updatedStudent)
            this.currRecord = record;
        }
    }

    currRecord = {}

    handleOpen = () => {
        this.setState(prevState => ({open: !prevState.open}));
    }

    toggleAlert = () => {
        this.setState(prevState => ({ openAlert: !prevState.openAlert }));
    }

    toggleAccept = () => {
        this.props.deleteStudentFromSession(this.props.sessionId, this.props.studentId);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleAltChange = name => e => {
        this.setState({[name]: e.target.value});
    }

    render() {
        const { student } = this.props;
        return (
            <div className="student-list-item-container">
                <ListItem dense button>
                    <div className="expand-btn">
                        {this.state.open 
                            ? <ExpandLess onClick={this.handleOpen}/> 
                            : <ExpandMore onClick={this.handleOpen}/>}
                    </div>
                    <div className="list-item">
                        <div className="list-item-properties">
                            <div className="list-item-name">
                                <ListItemText primary={`${student.firstName} ${student.lastName}`}/>
                            </div>
                            <div className="list-item-secondary">
                                <RadioGroup
                                    aria-label="attendance"
                                    name="attendance"
                                    value={this.state.attendance}
                                    onChange={this.handleChange}>
                                    <div className="attendance-controller">
                                        <FormControlLabel value="present" control={<Radio />} label="Present" labelPlacement="top"/>
                                        <FormControlLabel value="absent" control={<Radio />} label="Absent" labelPlacement="top"/>
                                        <FormControlLabel value="other" control={<Radio />} label="Makeup" labelPlacement="top"/>
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                        <div className="delete-btn">
                            <Button size="small" color="secondary" onClick={this.toggleAlert}><DeleteIcon /></Button>
                            <Alert 
                                action={this.toggleAccept} 
                                toggleAlert={this.toggleAlert} 
                                // toggleAlt={this.toggleAlt}
                                openAlert={this.state.openAlert} 
                                title={`Are you sure you want to delete ${student.firstName} ${student.lastName}?`} 
                                message={`NOTE: All children and records will be deleted. This action can't be undone.`}/>
                        </div>
                    }
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <ProgressForm handleOpen={this.handleOpen} handleChange={this.handleChange} handleAltChange={this.handleAltChange} parent={this.state}/>
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const id = props.studentId;
    const students = state.firestore.data.students;
    let student = students ? students[id] : {};

    return { 
        profile: state.firebase.profile,
        student, 
        attendanceRecords: state.firestore.data.attendanceRecords
        // session 
    }
}

export default connect(mapStateToProps, { deleteStudentFromSession, addStudentToMakeupClass, updateAttendance, updateStudent })(SessionStudentItem);