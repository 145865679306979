import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import DateFnsUtils from '@date-io/date-fns';
import isWithinInterval from 'date-fns/isWithinInterval'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Export';
import exportIcon from '../../resources/images/export.png'

// components
import Grid from './Grid';

const AttendanceReport = (props) => {
  let [ogRowData, setOgRowData] = useState([])
  let [rowData, setRowData] = useState([]);
  let [search, setSearch] = useState("")
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [exportGrid, setExportGrid] = useState(false);

  useEffect(() => {
    if(props.rowData) {
      setRowData(props.rowData);
      setOgRowData(props.rowData);
    } 
  }, [props.rowData])

  useEffect(() => {
    if(startDate && endDate) {
      let newRowData = rowData.filter(row => {
        let s = new Date(startDate);
        s.setDate(s.getDate()-1);

        if(row.date) {
          return isWithinInterval(new Date(row.date), {
            start: s,
            end: new Date(endDate)
          })
        }
        return false
      })

      setRowData(newRowData)
    }
  }, [startDate, endDate])

  // console.log("rowData: ", rowData)

  const getAttendanceStatus = (status) => {
    let value = 0
    rowData.forEach(row => {
      row.attendanceRecords.forEach(record => {
        if(record.attendance === status) value++;
      })
    })
    return value;
  }

  const getTotalStudents = () => {
    let value = 0
    rowData.forEach(row => {
      if(row?.students?.length) value += row.students.length;
    })
    return value;
  }

  const displayCards = () => {
    let values = [
      {
        label: "Total Students: ",
        value: getTotalStudents()
      },
      {
        label: "Present: ",
        value: getAttendanceStatus("present")
      },
      {
        label: "Absent: ",
        value: getAttendanceStatus("absent")
      },
      {
        label: "Other: ",
        value: getAttendanceStatus("other")
      }
    ]
    return (
      values.map(item => (
        <div style={{
          display: "flex",
          padding: 16,
          border: "1px solid lightgrey",
          fontSize: ".9rem",
          borderRadius: 4,
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div>{item.label}&nbsp;</div>
          <div>{item.value}</div>
        </div>
      ))
    )
  }

  return (
    <div style={{ height: "90%" }}>
      <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
      }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ display: "flex", alignItems: "center"}}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MMMM dd, yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Start Date"
              value={startDate}
              onChange={date => setStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MMMM dd, yyyy"
              margin="normal"
              id="date-picker-inline"
              label="End Date"
              value={endDate}
              onChange={date => setEndDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
        <div style={{
          display: "flex",
          gap: 6
        }}>
          {displayCards()}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Fab size="small" color="secondary" aria-label="add" onClick={()=> setExportGrid(!exportGrid)}>
              {/* <AddIcon/>  */}
              <img className="export-icon" src={exportIcon} alt="export reports"/>
            </Fab>
          </div>
        </div>
      </div>
      <Grid 
        rowData={rowData} 
        exportGrid={exportGrid}
        search={search}/>
    </div>
  );
};

const getRowData = (sessions = [], students = {}, teachers = {}, ar = {}) => {
  let rowData = [];
  sessions.forEach(session => {
    let obj = {
      ...session
    }
    let teacher;
    if(teachers?.[session?.teacher?.id]) {
      teacher = teachers[session.teacher.id] 
    }

    let studentsArr = []
    if(session?.students) {
      session.students.map(studentRef => {
        if(students?.[studentRef.id]) {
          studentsArr.push(students[studentRef.id])
        }
      })
    }

    let attendanceRecords = [];
    if(ar && session?.attendanceRecords) {
      session.attendanceRecords.map(arRef => {
        if(ar?.[arRef.id]) {
          attendanceRecords.push(ar[arRef.id])
        }
      })
    }

    obj.teacher = teacher;
    obj.students = studentsArr;
    obj.attendanceRecords = attendanceRecords;
    obj.date = format(obj.date.toDate(), "MMMM d, yyyy") || null

    rowData.push(obj)
  })
  return rowData.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.date) - new Date(a.date);
  });;
}

const mapStateToProps = (state) => {

  // console.log(state)

  let rowData = getRowData(
    state.firestore?.ordered?.sessions, 
    state.firestore?.data?.students,
    state.firestore?.data?.teachers,
    state.firestore?.data?.attendanceRecords
  )

  // console.log(rowData);
  // console.log(state);
  return { 
      profile: state.firebase.profile,
      rowData: rowData
  }
}

export default connect(mapStateToProps)(AttendanceReport);