const initState = {
    invoiceErr: null
}

const invoiceReducer = (state = initState, action) => {
    switch(action.type){
        case "INCREMENT_INVOICE_NUMBER": 
            return {
                ...state,
                invoiceNumber: action.invoiceNumber
            }
        case "INCREMENT_INVOICE_NUMBER_ERROR":
            return {
                invoiceErr: action.err
            }
        case "CREATE_INVOICE":
            return {
                invoiceRef: action.invoiceRef
            }
        case "CREATE_INVOICE_ERROR":
            return {
                invoiceErr: action.err
            }
        default: return state
    }
}

export default invoiceReducer;