import React, { PureComponent } from 'react';

class ServiceItem extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            package: "custom-charge",
            child: "",
            description: props.values.description,
            packageType: "",
            quantity: 1,
            unitPrice: 0,
            discount: props.values.discount,
            amount: 0,
            id: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState !== this.state) {
            if(this.state.package === 'custom-charge') {
                this.setState({
                    amount: (this.state.unitPrice * this.state.quantity),
                    id: "custom-charge"
                })
                this.props.getItemAmount(this.state.amount, 0, 0, this.props.i);
                this.props.handleDescription(this.state.description, this.state.packageType, this.props.i);
            } else if(this.state.package) {
                let plan = JSON.parse(this.state.package)
                const { id, amount, interval_count } = plan
                let description = plan.metadata.description ? plan.metadata.description : "";
                let student = this.props.students[this.state.child];
                if(student) { description = `${student.firstName} ${student.lastName} - ${description}`;}
                this.setState({
                    unitPrice: (amount / interval_count) / 100,
                    amount: (amount * this.state.quantity) / 100,
                    description,
                    packageType: plan.metadata.description,
                    id
                })
                setTimeout(() => {
                    this.props.getItemAmount((amount * this.state.quantity) / 100, (amount / interval_count) / 100, this.state.quantity, this.props.i);
                    this.props.handleDescription(this.state.description, plan.nickname, this.props.i);
                }, 500)
            }
        }
    }

    handleChange = event => {
        if(event.target.name === "discount" && event.target.value >= 0 && event.target.value <= 100) {
            this.setState({ discount: event.target.value });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
        this.props.handleChangeItem(event, this.props.i)
    }

    render() {
        return (
            <tr className="table-row">
                <td>
                    <div className="select-container">
                        <select 
                            className="select" 
                            value={this.state.child}
                            name="child"
                            onChange={this.handleChange}>
                            <option value={null}></option>
                            {this.props.children && this.props.children.map(key => {
                                let student = this.props.students[key];
                                let name = student.firstName + " " + student.lastName; 
                            return <option key={key} value={key}>{name}</option>
                            })}
                        </select>
                    </div>
                </td>
                <td>
                    <div className="select-container" >
                        <select 
                            className="select" 
                            value={this.state.package}
                            name="package"
                            onChange={this.handleChange}>
                            <option value={null}></option>
                            {this.props.stripePlans.map((plan, i) => {
                                if((this.props.recurring === "monthly" && plan.interval === "month") || plan.name === "MISC") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                                // else if(this.props.recurring === "quarterly" && plan.interval_count === 3 && plan.interval === "month") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                                // else if(this.props.recurring === "semi-annually" && plan.interval_count === 6 && plan.interval === "month") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                                else if((this.props.recurring === "annually" && plan.interval === "year") || plan.name === "MISC") return <option key={plan.id} value={JSON.stringify(plan)}>{plan.nickname}</option>
                            }).sort((a, b) => a.nickname - b.nickname)}
                        </select>
                    </div>
                </td>
                <td>
                    <input
                        className="input-wrapper" 
                        type="number"
                        name="quantity" 
                        value={this.state.quantity}
                        placeholder="Quantity"
                        onChange={this.handleChange}/>
                </td>
                <td>
                    <div className="input-wrapper">$
                    <input
                        className="input" 
                        type="number" 
                        name="unitPrice" 
                        value={this.state.unitPrice}
                        placeholder="Unit Price"
                        onChange={this.handleChange}/>
                    </div>
                </td>
                <td>
                    <div className="discount-wrapper">
                    <input
                        className="input" 
                        type="number" 
                        name="discount" 
                        value={this.state.discount}
                        placeholder="Discount"
                        onChange={this.handleChange}/>%
                    </div>
                </td>
                <td>
                    <div className="input-wrapper">$
                    <input
                        className="input" 
                        type="number"
                        name="amount" 
                        value={this.state.amount}
                        placeholder="Amount"
                        onChange={this.handleChange}/>
                    </div>
                </td>
                <td>
                <button className="minus-btn" onClick={e => this.props.handleDeleteItem(e, this.props.i)}>
                    -
                </button>
                </td>
            </tr>
        )
    }
}

export default ServiceItem;