import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { format } from 'date-fns';
import { isLocationMatch } from '../../js/helpers';
import ParentLinkRenderer from '../students/ParentLinkRenderer';
import EmailRenderer from '../parents/EmailRenderer';
import LinkRenderer from '../shared/LinkRenderer';

class ReportsGrid extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Students",
                    field: "students",
                    cellRenderer: "linkRenderer"
                }, {
                    headerName: "Parents",
                    field: "parents",
                    cellRenderer: "parentLinkRenderer"
                }, {
                    headerName: "Primary Center",
                    field: "primaryCenter"
                }, {
                    headerName: "Secondary Center",
                    field: "secondaryCenter"
                }, {
                    headerName: "Primary Email",
                    field: "primaryEmail",
                    cellRenderer: "emailRenderer"
                }, {
                    headerName: "Secondary Email",
                    field: "secondaryEmail"
                }, {
                    headerName: "Cell Number",
                    field: "cellNumber"
                }, {
                    headerName: "Home Number",
                    field: "homeNumber"
                }, {
                    headerName: "Jusan Level",
                    field: "jusanLevel",
                }, {
                    headerName: "Amsan Level",
                    field: "amsanLevel",
                }, {
                    headerName: "Attendance Records",
                    field: "attendanceRecords"
                }, {
                    headerName: "Address",
                    field: "address",
                }, {
                    headerName: "City",
                    field: "city"
                }, {
                    headerName: "State",
                    field: "state"
                }, {
                    headerName: "Zip Code",
                    field: "zipCode"
                }, {
                    headerName: "Package Type",
                    field: "packageType"
                }, {
                    headerName: "Active Date",
                    field: "activeDate"
                }, {
                    headerName: "Inactive Date",
                    field: "inactiveDate"
                }, {
                    headerName: "Active",
                    field: "active",
                    cellClassRules: {
                        "status-green": params => cellStyleRule(params) === 1,
                        "status-red": params => cellStyleRule(params) === 0,
                    }
                }]
            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                parentLinkRenderer: ParentLinkRenderer,
                emailRenderer: EmailRenderer
            },
            paginationPageSize: 100
        }
    }

    componentDidMount() {
        if(this.props.students) {
            let rowData = convertDataToRow(this.props.students, this.props.families, this.props.locationFilter);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }

        if((prevProps.students !== this.props.students && this.props.students ) 
        || (prevProps.families !== this.props.families && this.props.families)
        || (prevProps.locationFilter !== this.props.locationFilter && this.props.locationFilter)) {
            if(this.props.students && this.props.families) {
                let rowData = convertDataToRow(this.props.students, this.props.families, this.props.locationFilter);
                this.setState({ rowData });
            }
        }
        if(prevProps.exportGrid !== this.props.exportGrid) {
            this.state.gridOptions.api.exportDataAsCsv();
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        // this.gridApi.sizeColumnsToFit();
        if(this.props.profile.isTeacher) {
            this.state.gridOptions.columnApi.setColumnVisible('delete', false)
        }
    }

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default ReportsGrid;

const convertDataToRow = (data, families, locationFilter) => {
    let rowData = []
    Object.keys(data).map(key => {
        let row = data[key];
        if(row) {
            let currParent = families[row.familyId.id];
            let parentName = "";

            if(currParent) {
                parentName = currParent.parentOneFirstName + ' ' + currParent.parentOneLastName
            }

            let current = {
                id: key,
                students: row.firstName + ' ' + row.lastName,
                parents: parentName,
                parentId: row.familyId.id,
                birthday: row.birthday ? format(row.birthday.toDate(), "MMM d, yyyy") : "",
                activeDate: row.activeDate ? format(row.activeDate.toDate(), "MMM d, yyyy") : "",
                inactiveDate: row.inactiveDate ? format(row.inactiveDate.toDate(), "MMM d, yyyy") : "",
                allergies: row.allergies,
                credits: row.credits,
                primaryCenter: row.primaryCenter,
                secondaryCenter: row.secondaryCenter,
                url: "students",
                familyRef: row.familyId,
                active: row.status,
                jusanLevel: row.jusanLevel,
                amsanLevel:row.amsanLevel,
                primaryEmail: currParent.primaryEmail,
                secondaryEmail: currParent.secondaryEmail,
                cellNumber: currParent.cellNumber,
                homeNumber: currParent.homeNumber,
                address: currParent.address,
                city: currParent.city,
                state: currParent.state,
                zipCode: currParent.zipCode,
                parentRef: row.familyId.id,
                packageType: row.packageDescription ? row.packageDescription : "", 
            }
            
            if(isLocationMatch(locationFilter, row.primaryCenter, row.secondaryCenter)){
                rowData.push(current);
            }
        }
    });
    return rowData;
}

const cellStyleRule = (params) => {
    if(params.value) return 1;
    else if(!params.value) return 0;
    else return -1;
}