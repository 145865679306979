import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import '../style/main.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

// Components
import Dashboard from '../../dashboard/Dashboard';
import CalendarMain from '../../calendar/CalendarMain';
import Classes from '../../class/Classes';
import Class from '../../class/Class';
import Students from '../../students/Students';
import Student from '../../students/Student';
import Parents from '../../parents/Parents';
import Parent from '../../parents/Parent';
import Teachers from '../../teachers/Teachers';
import Teacher from '../../teachers/Teacher';
import Locations from '../../locations/Locations';
import Location from '../../locations/Location';
import Invoice from '../../invoice/Invoice';
import Subscriptions from '../../subscriptions/Subscriptions';
import Reports from '../../reports/Reports';
import InvoiceAlt from '../../invoiceAlt/Invoice';
import AttendanceReport from '../../attendanceReport/AttendanceReport';

class Main extends PureComponent {
    render() {
        return ( 
            <div className="layout-component-wrapper">
                {/* <div className="layout-component-inner-container"> */}
                    <Switch>
                        <Route exact path="/" component={ Dashboard }/>
                        <Route exact path="/calendar" component={ CalendarMain }/>
                        <Route exact path="/classes" component={ Classes }/>
                        <Route path="/classes/:id" component={ Class }/>
                        
                        <Route exact path="/students" component={ Students }/>
                        <Route path="/students/:id" component={ Student }/>
                        <Route exact path="/parents" component={ Parents }/>
                        <Route path="/parents/:id" component={ Parent }/>
                        {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <Route exact path="/teachers" component={ Teachers }/>}
                        <Route path="/teachers/:id" component={ Teacher }/>
                        {(this.props.profile.isAdmin) && <Route exact path="/locations" component={ Locations }/>}
                        {(this.props.profile.isAdmin) && <Route path="/locations/:id" component={ Location }/>}
                        {/* <Route path="/locations/:id" component={ PrivateLessons }/> */}
                        {/* {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <Route exact path="/invoice" component={ Invoice }/>} */}
                        {/* {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <Route exact path="/subscriptions" component={ Subscriptions }/>} */}
                        {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <Route exact path="/invoice" component={ InvoiceAlt }/>}
                        <Route path="/reports" component={ Reports }/>
                        <Route path="/attendance-reports" component={ AttendanceReport }/>
                    </Switch>
                {/* </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        families: state.firestore.data.families,
        students: state.firestore.data.students,
        sessions: state.firestore.data.sessions,
        classes: state.firestore.data.classes,
        attendanceRecords: state.firestore.data.attendanceRecords,
        teachers: state.firestore.data.teachers,
        makeupClass: state.firestore.data.makeupClass
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'families', orderBy: ["parentOneFirstName", "asc"]},
        { collection: 'students', orderBy: ["firstName", "asc"]},
        { collection: 'sessions' },
        { collection: 'classes'},
        { collection: 'attendanceRecords' },
        { collection: 'teachers', orderBy: ["firstName", "asc"]},
        { collection: 'locations' },
        { collection: 'makeupClass' },
    ])
)(Main);