import React from 'react';
import { connect } from 'react-redux';
import '../styles/mobileevent.scss';
import {formatMilitaryTime} from '../../../js/helpers';
import icon from '../../calendar/resources/images/user.png';

const MobileEvent = (props) => {
    const classObj = props.classes && props.classes[props.session.classId.id];
    const teacher = props.teachers && props.teachers[props.session.teacher.id];
    const classTime = props.session && `${formatMilitaryTime(props.session.startTime)} - ${formatMilitaryTime(props.session.endTime)}`
    let statusColor = ""
    if(props.session.attendanceRecords && props.attendanceRecords) {
        let all = props.session.attendanceRecords.every(recordRef => {
            let record = props.attendanceRecords[recordRef.id];
            if(record) {
                return record.attendance.length > 0
            }
        })
        let some = false;
        if(!all) {
            some = props.session.attendanceRecords.some(recordRef => {
                let record = props.attendanceRecords[recordRef.id];
                if(record) {
                    return record.attendance.length > 0
                }
            })
        }
        if(all) statusColor = '#2ecc71';
        else if(some) statusColor = '#f1c40f';
    }

    return (
        <div className="mobile-event-container" style={{backgroundColor: props.session.color}} onClick={() => props.handleSelectSession(props.session.id)}>
            <div className="event-top">
                <div className="mobile-event-status-container">
                    <p className="mobile-event-name">{classObj ? classObj.className : ""}</p>
                    <div className="event-status" style={{ backgroundColor: statusColor }}></div>
                </div>
                <span className="mobile-event-teacher">{teacher ? `${teacher.firstName} ${teacher.lastName}` : ""}</span>
            </div>
            <div className="event-bottom">
                <span className="mobile-event-time">{classTime}</span>
                <span className="mobile-event-student-container">
                    <img className="class-event-student-icon"src={icon} alt="abakidz student"/>
                    <h6 className="number-student-text"> &nbsp; {props.session.students && props.session.students.length}</h6>
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return { 
        classes: state.firestore.data.classes,
        teachers: state.firestore.data.teachers,
        attendanceRecords: state.firestore.data.attendanceRecords
    }
}

export default connect(mapStateToProps)(MobileEvent);