import axios from 'axios';

export const getAllStripePlans = () => {
    return (dispatch, getState) => {
        axios.get("https://us-central1-abakidz-student-application.cloudfunctions.net/getAllStripePlans")
            .then(res => {
                let stripePlans = res.data.data;
                
                dispatch({ 
                    type: "GET_ALL_STRIPE_PLANS",
                    stripePlans
                });
            }).catch(err => {
                dispatch({ 
                    type: "GET_ALL_STRIPE_PLANS",
                    err
                });
            })
    }
}