import React, { PureComponent } from 'react';
import SelectLocation from '../shared/SelectLocation';
import { connect } from 'react-redux';
import { signUp } from '../../store/actions/authActions';

// Components
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';

class SignUp extends PureComponent {
    constructor() {
        super();
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirm_password: "",
            location: "",
            alert: {
                variant: "success",
                open: false,
                message: ""
            }
        }
    }
    
    componentDidUpdate(prevProps, prevState){
        if(this.props.authError && ((prevProps.authError !== this.props.authError))) {
            this.handleOpenPopup("error", this.props.authError);
        }
    }

    handleChange = name => e => {
        this.setState({ [name]: e.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = e => {
        let newUser = Object.assign({}, this.state);
        delete newUser.alert;
        const { firstName, lastName, email, password, confirm_password } = this.state;
    
        if(password !== confirm_password){
            this.handleOpenPopup("error", "Password mismatch!");
        } else if( firstName.length === 0 || lastName.length === 0 || email.length === 0 || password.length === 0 || confirm_password.length === 0) {
            this.handleOpenPopup("error", "Please fill in all fields!");
        } else if(password.length < 8) {
            this.handleOpenPopup("error", "Password must be at least 8 characters!");
        } else {
            this.props.signUp(newUser);
        }
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        return(
            <div className="login-wrapper">
                <form className="login-form">
                    <TextField
                        label="first name"
                        name="first_name"
                        onChange={this.handleChange('firstName')}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        label="last name"
                        name="last_name"
                        onChange={this.handleChange('lastName')}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        id="outlined-email-input"
                        label="email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"
                    />
                    <SelectLocation 
                        title="Select Location"
                        name="location"
                        value={this.state.location} 
                        handleChange={this.handleSelect}/>
                    <TextField
                        id="outlined-password-input"
                        label="password"
                        type="password"
                        onChange={this.handleChange('password')}
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        id="outlined-password-input"
                        label="confirm password"
                        type="password"
                        onChange={this.handleChange('confirm_password')}
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                    />
                    <Button 
                        className="login-btn"
                        variant="contained" 
                        onClick={this.handleSubmit}
                        color="secondary">
                        SIGN UP
                    </Button>
                </form>
                <div className="signup-forgot-password-wrapper">
                    <Link to="/">login</Link>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUp: newUser => dispatch(signUp(newUser))
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);