import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TeacherScheduleGrid from './TeacherScheduleGrid'
import '../students/styles/attendance.scss';

class TeacherSchedule extends PureComponent {
    render() {
        return (
            <div className="sessions-list-container" id="sessions-list-container">
                <TeacherScheduleGrid teacherId={this.props.teacherId} teacher={this.props.teacher}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.firebase.profile,
        studentError: state.students.studentError
    }
}

export default connect(mapStateToProps)(TeacherSchedule);