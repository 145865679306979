const initState = {
    studentError: null
}

const studentReducer = (state = initState, action) => {
    switch(action.type) {
        case "CREATE_STUDENT":
            return {
                ...state,
                student: action.student
            }
        case "CREATE_STUDENT_ERROR":
            return {
                ...state,
                studentError: action.err
            }
        case "UPDATE_STUDENT":
            return {
                ...state,
                student: action.student
            }
        case "UPDATE_STUDENT_ERROR":
            return {
                ...state,
                studentError: action.err
            }
        case "DELETE_STUDENT":
            return {
                ...state,
                student: action.student
            }
        case "DELETE_STUDENT_ERROR": 
            return {
                ...state,
                studentError: action.err
            }
        default:
            return state;
    }
}

export default studentReducer;