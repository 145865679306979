const initState = {
    familyError: null
}

const familyReducer = (state = initState, action) => {
    switch(action.type) {
        case "GET_FAMILY":
            return {
                ...state,
                family: action.family
            }
        case "GET_FAMILY_ERROR":
            return {
                ...state,
                familyError: action.err
            }
        case "CREATE_FAMILY": 
            return {
                ...state,
                family: action.family
            }
        case "CREATE_FAMILY_ERROR": 
            return {
                ...state,
                familyError: action.err
            }
        case "UPDATE_FAMILY":
            return {
                ...state,
                family: action.family
            }
        case "UPDATE_FAMILY_ERROR":
            return {
                ...state,
                familyError: action.err
            }
        case "CREATE_FAMILY_CHILD":
            return {
                ...state,
                child: action.child
            }
        case "CREATE_FAMILY_CHILD_ERROR": 
            return {
                ...state,
                familyError: action.err
            }
        case "DELETE_FAMILY_CHILD": 
            return {
                ...state,
                child: action.child
            }
        case "DELETE_FAMILY_CHILD_ERROR": 
            return {
                ...state,
                familyError: action.err
            }
        case "DELETE_FAMILY": 
            return {
                ...state,
                family: action.deletedFamily
            }
        case "DELETE_FAMILY_ERROR": 
            return {
                ...state,
                familyError: action.err
            }
        default: return state;
    }
}

export default familyReducer;