export const login = credentials => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        return firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: "LOGIN_SUCCESS" });
        }).catch(err => {
            dispatch({ type: "LOGIN_ERROR", err });
        });
    }
}

export const socialLogin = (social) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        const firestore = getFirestore();
        
        return firebase.auth().signInWithPopup(googleProvider).then(res => {
            const { user } = res
            let name = user.displayName.split(" ");
            firestore.collection("teachers").doc(user.uid).get().then(teacherRef => {
                if(!teacherRef.exists) {
                    return firestore.collection("teachers").doc(user.uid).set({
                        firstName: name[0],
                        lastName: name[1],
                        email: user.email,
                        createAt: new Date(),
                        classes: [],
                        isAdmin: false,
                        isTeacher: false,
                        isCenterManager: false,
                        birthday: new Date(),
                        phoneNumber: "",
                        primaryCenter: "",
                        secondaryCenter: "",
                        address: "",
                        city: "",
                        state: "",
                        zipCode: "",
                    }).then(() => {
                        dispatch({ type: "SIGNUP_SUCCESS" });
                    }).catch(err => {
                        dispatch({ type: "SIGNUP_ERROR", err });
                    });
                }
            })
        }).then(() => {
            dispatch({ type: "LOGIN_SUCCESS" });
        }).catch(err => {
            dispatch({ type: "LOGIN_ERROR", err });
        });
    }
}

export const passwordReset = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().sendPasswordResetEmail(email).then(() => {
            dispatch({ type: "PASSWORD_RESET_SUCCESS" });
        }).catch(err => {
            dispatch({ type: "PASSWORD_RESET_ERROR", err });
        });
    }
}

export const logout = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: "LOGOUT_SUCCESS"});
        }).catch(err => {
            dispatch({ type: "LOGOUT_ERROR", err});
        });
    }
}

export const signUp = newUser => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then(res => {
            return firestore.collection("teachers").doc(res.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                email: newUser.email,
                createAt: new Date(),
                classes: [],
                isAdmin: false,
                isTeacher: false,
                isCenterManager: false,
                birthday: new Date(),
                phoneNumber: "",
                primaryCenter: newUser.location,
                secondaryCenter: "",
                address: "",
                city: "",
                state: "",
                zipCode: "",
            })
        }).then(() => {
            dispatch({ type: "SIGNUP_SUCCESS" });
        }).catch(err => {
            dispatch({ type: "SIGNUP_ERROR", err });
        });
    }
}