export const getFamily = (id) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('families').doc(id).get().then(doc => {
            if(doc.exists) {
                let info = doc.data();
                let family = {
                    id: doc.id,
                    ...info
                }
                dispatch({ type: "GET_FAMILY", family })
            } else {
                dispatch({ type: "GET_FAMILY_ERROR", err: "Family does not exist." });
            }
        }).catch(err => {
            dispatch({ type: "GET_FAMILY_ERROR", err });
        })
    }
}

export const createFamily = (family) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('families').add({
            ...family,
            createdAt: new Date(),
            activeDate: 'inactive',
            status: 'inactive',
            students: [],
            userId: null
        }).then(() => {
            dispatch({ type: "CREATE_FAMILY", family})
        }).catch((err) => {
            dispatch({ type: "CREATE_FAMILY_ERROR", err})
        });
    }
}

export const updateFamily = (familyId, updatedFamily) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        console.log(familyId, updatedFamily)
        
        firestore.collection('families').doc(familyId).update({
            ...updatedFamily
        }).then(() => {
            dispatch({ type: "UPDATE_FAMILY", updatedFamily });
        }).catch((err) => {
            dispatch({ type: "UPDATE_FAMILY_ERROR", err})
        });
    }
}

export const createChild = child => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let familyId = child.familyId;
        let newChild = {
            ...child,
            familyId: firestore.doc('/families/' + child.familyId),
        }

        firestore.collection('students').add({
            ...newChild
        }).then((child) => {
            let childRef = firestore.doc('/students/' + child.id);
            firestore.collection('families').doc(familyId).update({
                students: firestore.FieldValue.arrayUnion(childRef)
            });

            dispatch({ type: "CREATE_FAMILY_CHILD", child: newChild });
        }).catch((err) => {
            dispatch({ type: "CREATE_FAMILY_CHILD_ERROR", err});
        });
    }
}

export const deleteFamily = family => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const familyRef = firestore.doc('/families/' + family);

        familyRef.get().then(familySnapshot => {
            if(familySnapshot.exists) {
                let familyDoc = familySnapshot.data();

                familyDoc.students.forEach(studentRef => {
                    studentRef.get().then(studentSnapshot => {
                        if(studentSnapshot.data().attendanceRecords) {
                            studentSnapshot.data().attendanceRecords.forEach(attendanceRecordRef => {
                                attendanceRecordRef.get().then(attendanceRecordSnapshot => {
                                    let attendanceRecord = attendanceRecordSnapshot.data();
                                    attendanceRecord.session.get().then(sessionSnapshot => {
                                        if(sessionSnapshot.exists) {
                                            sessionSnapshot.data().classId.update({
                                                students: firestore.FieldValue.arrayRemove(studentRef)
                                            });
                                        }
                                    });
                                    attendanceRecord.session.update({
                                        attendanceRecords: firestore.FieldValue.arrayRemove(attendanceRecordRef),
                                        students: firestore.FieldValue.arrayRemove(studentRef)
                                    })
                                }).then(() => {
                                    attendanceRecordRef.delete();
                                });
                            });
                        }
                    }).then(() => {
                        studentRef.delete().then(deletedStudent => {
                            dispatch({ type: "DELETE_FAMILY_CHILD", deletedStudent });
                        }).catch(err => {
                            dispatch({ type: "DELETE_FAMILY_CHILD_ERROR", err});
                        });
                    });
                });
            }
        }).then(() => {
            familyRef.delete().then(deletedFamily => {
                dispatch({ type: "DELETE_FAMILY", deletedFamily })
            }).catch(err => {
                dispatch({ type: "DELETE_FAMILY_ERROR", err })
            });
        });
    }
}