import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
}

export const formatMilitaryTime = time => {
    let values = time.split(':');
    let hours = Number(values[0]);
    let minutes = values[1];

    if(hours === 12) {
      return `${hours}:${minutes}PM`
    } else if(hours > 12){
       return `${hours - 12}:${minutes}PM`
    }
    return `${hours}:${minutes}AM`
}

export const convertToMilitaryTime = time => {
    let militaryTime = "";

    if(time[time.length - 2] === 'A') {
        let values = time.split(':');
        let hours = Number(values[0]);
        let minutes = values[1];
        militaryTime += "0" + hours + ":" + minutes

    } else if(time[time.length - 2] === 'P') {
        let values = time.split(':');
        let hours = Number(values[0]);
        let minutes = values[1];
        militaryTime += (hours + 12) + ":" + minutes
    }
    return militaryTime.substring(0, militaryTime.length - 2);
}

export const colorMap = new Map();
colorMap.set("Beginner", "#f9ca24");
colorMap.set("Intermediate", "#00b894");
colorMap.set("Advanced", "#0984e3");

export const colors = [
  { name: "Green", color: "#1dd1a1" },
  { name: "Blue", color: "#0fbcf9" },
  { name: "Red", color: "#ff6b6b" },
  { name: "Purple", color: "#a29bfe" },
  { name: "Grey", color: "#576574" },
  { name: "Blue Violet", color: "#546de5" },
  { name: "Rose", color: "#c44569" },
  { name: "Pink", color: "#f78fb3" },
]

export const getAge = (birthday) => {
  let ageInDays = differenceInCalendarDays(new Date(), birthday);
  return Math.floor(ageInDays / 365)
}

export const isLocationMatch = (filter, location, secondary) => {
    if(!location && !secondary) return false
    return secondary ? filter.toLowerCase() === 'all' || filter.toLowerCase() === location.toLowerCase() || filter.toLowerCase() === secondary.toLowerCase()
                    : filter.toLowerCase() === 'all' || filter.toLowerCase() === location.toLowerCase();
}