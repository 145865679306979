import React, { PureComponent } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SlidingPane from '../shared/SlidingPane';
import LocationFilter from '../shared/LocationFilter';
import AddClassForm from './AddClassForm';
import ClassGrid from './ClassGrid';
import Searchbar from '../shared/Searchbar';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { connect } from 'react-redux';
import './style/classes.scss';

class Classes extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            locationFilter: this.props.profile.isAdmin ? 'All': this.props.profile.primaryCenter,
            search: "",
            alert: {
                variant: "success",
                open: false,
                message: ""
            }
        }
    }

    componentDidMount() {
        if(window.localStorage.getItem(this.props.profile.firstName)) {
            let settings = JSON.parse(window.localStorage.getItem(this.props.profile.firstName))
            this.setState({ 
                locationFilter: settings.locationFilter,
                search: settings.search
            })
        } else {
            let settings = {
                locationFilter: this.state.locationFilter,
                search: this.state.search
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.profile.primaryCenter && this.props.profile.primaryCenter) {
            this.setState({ locationFilter: this.props.profile.primaryCenter });
        }
        if(prevState.locationFilter !== this.state.locationFilter || prevState.search !== this.state.search) {
            let settings = {
                locationFilter: this.state.locationFilter,
                search: this.state.search
            }
            window.localStorage.setItem(this.props.profile.firstName, JSON.stringify(settings))
        }
    }

    handleFilter = e => {
        this.setState({ locationFilter: e.target.value });
    }

    handleChange = e => {
        this.setState({ search: e.target.value.toLowerCase() });
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        return (
            <div className="tab-container">
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && this.state.showForm &&
                    <SlidingPane header="Add a class" toggleForm={this.toggleForm} showForm={this.state.showForm}>
                        <AddClassForm toggleForm={this.toggleForm} handleOpenPopup={this.handleOpenPopup}/>
                    </SlidingPane>
                }
                <div className="list-actions-container">
                    <LocationFilter 
                        value={this.state.locationFilter}
                        handleChange={this.handleFilter} />
                    <Searchbar 
                        search={this.state.search}
                        handleChange={this.handleChange}/>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <div className="action-container">
                        <h4 style={{marginRight: '1rem'}}>Add a class</h4>
                        <Fab size="small" color="secondary" aria-label="add" onClick={this.toggleForm}>
                            <AddIcon/> 
                        </Fab>
                    </div>}
                </div>
                <div className="class-grid-container">
                    <ClassGrid 
                        classes={this.props.classes}
                        teachers={this.props.teachers}
                        students={this.props.students}
                        profile={this.props.profile}
                        locationFilter={this.state.locationFilter} 
                        search={this.state.search} 
                        handleOpenPopup={this.handleOpenPopup}/>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        classes: state.firestore.data.classes,
        teachers: state.firestore.data.teachers,
        students: state.firestore.data.students
    }
}

export default connect(mapStateToProps)(Classes);