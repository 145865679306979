import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple, cyan, pink, red } from '@material-ui/core/colors';

const LetterAvatar = props => {
    const classes = useStyles();
    const i = Math.floor(Math.random() * 5);
    const randClass = [classes.orangeAvatar, classes.purpleAvatar, classes.cyanAvatar, classes.pinkAvatar, classes.redAvatar];
    return <Avatar className={randClass[i]}>{props.initials}</Avatar>
}


export default LetterAvatar;

const useStyles = makeStyles({
    avatar: {
      margin: 10,
      color: '#fff',
    },
    orangeAvatar: {
        margin: 10,
        backgroundColor: deepOrange[500],
    },
    purpleAvatar: {
        margin: 10,
        backgroundColor: deepPurple[500],
    },
    cyanAvatar: {
        margin: 10,
        backgroundColor: cyan[500],
    },
    pinkAvatar: {
        margin: 10,
        backgroundColor: pink[500],
    },
    redAvatar: {
        margin: 10,
        backgroundColor: red[500],
    }
});