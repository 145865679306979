import React, { PureComponent } from 'react';
import cancelIcon from '../../resources/images/cancel.png';
import Alert from '../shared/Alert';
import './styles/subscriptions.scss';
import axios from 'axios';

class CancelSubscriptionRenderer extends PureComponent {
    state = {
        openAlert: false,
    }

    toggleAlert = () => {
        this.setState(prevState => ({ openAlert: !prevState.openAlert }));
    }

    toggleAccept = () => {
        axios.post("https://us-central1-abakidz-student-application.cloudfunctions.net/cancelSubscription", {subscriptionId: this.props.data.subscriptionId})
            .then(res => {
                console.log(res.data)
                this.props.api.updateRowData({ remove: [this.props.data] })
            });
    }

    render() {
        return (
            <>
                <button className="cancel-subscription-button" onClick={this.toggleAlert}>
                    <img className="cancel-icon" src={cancelIcon} alt=""/>
                </button>
                <Alert 
                    action={this.toggleAccept} 
                    toggleAlert={this.toggleAlert} 
                    openAlert={this.state.openAlert} 
                    title={`Are you sure you want to cancel ${this.props.data.parent}'s subscription?`} 
                    message={`NOTE: This action can't be undone.`}/>
            </>
        )
    }
}

export default CancelSubscriptionRenderer;