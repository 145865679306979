export const createClass = (classObj) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let students = classObj.selectedStudents.map(student => firestore.doc('/students/' + student.id));
        delete classObj.selectedStudents;
        
        firestore.collection('classes').add({
            ...classObj,
            classType: firestore.doc('/classTypes/' + classObj.classType),
            subject: firestore.doc('/subjects/' + classObj.subject),
            teacher: firestore.doc('/teachers/' + classObj.teacher),
            students
        }).then(() => {
            dispatch({ type: "CREATE_CLASS", classObj });
        }).catch(err => {
            dispatch({ type: "CREATE_CLASS_ERROR", err});
        });
    }
}


export const updateClass = (classId, updatedClass) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let temp = updatedClass.selectedStudents
        if(!Array.isArray(updatedClass.selectedStudents)){
            temp = Object.keys(updatedClass.selectedStudents).map(i => updatedClass.selectedStudents[i])
        }
        let students = temp.map(student => firestore.doc('/students/' + student.id));
        delete updatedClass.selectedStudents;
        
        firestore.collection('classes').doc(classId).update({
            ...updatedClass,
            classType: firestore.doc('/classTypes/' + updatedClass.classType),
            subject: firestore.doc('/subjects/' + updatedClass.subject),
            teacher: firestore.doc('/teachers/' + updatedClass.teacher),
            students
        }).then(() => {
            dispatch({ type: "UPDATE_CLASS", updatedClass });
        }).catch(err => {
            dispatch({ type: "UPDATE_CLASS_ERROR", err});
        });
    }
}

export const deleteClass = (classId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        const classRef = firestore.doc('/classes/' + classId);

        classRef.get().then(classSnapshot => {
            if(classSnapshot.exists) {
                if(classSnapshot.data().sessions) {
                    classSnapshot.data().sessions.forEach(sessionRef => {
                        sessionRef.get().then(sessionSnapshot => {
                            if(sessionSnapshot.data().attendanceRecords) {
                                sessionSnapshot.data().attendanceRecords.forEach(attendanceRecordRef => {
                                    // console.log(attendanceRecordRef)
                                    attendanceRecordRef.get().then(attendanceRecordSnapshot => {
                                        attendanceRecordSnapshot.data().student.update({
                                            attendanceRecords: firestore.FieldValue.arrayRemove(attendanceRecordRef)
                                        });
                                    }).then(() => {
                                        attendanceRecordRef.delete();
                                    });
                                });
                            }
                        }).then(() => {
                            sessionRef.delete();
                        });
                    });
                }
            }
        }).then(() => {
            classRef.delete();
        });
    }
}

export const deleteStudentFromMakeup = (id) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const makeupClassRef = firestore.doc('/makeupClass/' + id);

        makeupClassRef.delete()
    }
}