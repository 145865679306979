import React, { PureComponent } from 'react';
import '../style/sidebar.scss';

// Components
import { Link } from 'react-router-dom';
import SidebarLinkList from './SidebarLinkList';

// Images
import logo from '../../../resources/images/abakidz-logo.png';

class Sidebar extends PureComponent {

    componentDidMount() {
        setTimeout(() => {
            const sidebar = document.getElementById('layout-sidebar-container');
            if(sidebar) sidebar.classList.add('active');
        }, 200)
    }

    render() {
        return (
            <div id="layout-sidebar-container" className="layout-sidebar-container">
                <Link className="layout-sidebar-logo-container" to="/">
                    <img className="layout-sidebar-logo" src={logo} alt="tactile brain abakidz logo"/>
                </Link>
                <SidebarLinkList />
            </div>
        );
    }
}

export default Sidebar;