import React from 'react';

const StudentsCellRenderer = (props) => {
  let students = props.value || []
  return (
      <div>
          {students?.map(student => `${student?.firstName} ${student?.lastName}`).join(",\n")}
      </div>
  )
};

export default StudentsCellRenderer;