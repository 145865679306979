import React, { PureComponent } from 'react';
import './style/sessionDetails.scss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Alert from '../shared/Alert';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { addStudentToSession, deleteSession, updateSession, deleteStudentFromMakeup } from '../../store/actions/sessionActions';
import { connect } from 'react-redux';
import CustomSelect from '../shared/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';
import SelectTeacher from '../shared/SelectTeacher';
import SessionStudentItem from './SessionStudentItem';
import TextField from '@material-ui/core/TextField';
import { Backdrop, CircularProgress } from '@material-ui/core';

class SessionDetails extends PureComponent {
    state = {
        students: [],
        selectedStudent: "",
        teacher: this.props.session.teacher ? this.props.session.teacher : "",
        saveToggle: false,
        openAlert: false,
        comments: this.props.session.comments ? this.props.session.comments : "",
        isSaving: false,
        alert: {
            variant: "success",
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        if(this.props.session) {
            this.props.session.students.forEach(studentRef => {
                let student = this.props.students[studentRef.id];
                if(student) {
                    let data = {
                        id: studentRef.id,
                        student
                    }
                    this.setState(prevState => ({ students: [...prevState.students, data] }));
                }
            }) 
        }

        if(this.props.session.teacher) {
            this.setState({ teacher: this.props.session.teacher.id})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.session !== this.props.session){
            this.setState({ students: [] });
            this.props.session.students.forEach(studentRef => {
                let student = this.props.students[studentRef.id];
                if(student) {
                    let data = {
                        id: studentRef.id,
                        student
                    }
                    this.setState(prevState => ({ students: [...prevState.students, data] }));
                }
            }) 
        }
    }

    handleStudents = event => {
        this.setState({ selectedStudents: event.target.value });
    }

    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChange = name => e => {
        this.setState({[name]: e.target.value});
    }

    renderStudents = () => {
        return this.state.students.map(student => {
            return <SessionStudentItem  
                        saveToggle={this.state.saveToggle}
                        key={student.id} 
                        studentId={student.id} 
                        data={this.data}
                        session={this.props.session}
                        sessionId={this.props.sessionId}/>
        });
    }

    handleAddStudent = () => {
        // this.setState({ selectedStudent: "" });
        if(this.state.selectedStudent.length !== 0) {
            let foundStudent = this.state.students.find(student => {
                return student.id === this.state.selectedStudent;
            });
    
            if(!foundStudent){
                let isMakeup = false;
                // Finds student from makeup classes and deletes from list
                if(this.props.makeupClass) {
                    for(let i = 0; i < this.props.makeupClass.length; i++) {
                        let makeupStudent = this.props.makeupClass[i];
                        if(makeupStudent.student.id === this.state.selectedStudent) {
                            this.props.deleteStudentFromMakeup(makeupStudent.id);
                            isMakeup = true;
                            break;
                        }
                    }
                }

                this.props.addStudentToSession(this.props.sessionId, this.state.selectedStudent, isMakeup);
                this.setState({ selectedStudent: "" });
            } else {
                this.handleOpenPopup("warning", `${foundStudent.student.firstName} ${foundStudent.student.lastName} is already in the class!`);
            }
        } else {
            this.handleOpenPopup("warning", `No student selected`);
        }
    }

    // Toggle that triggers Update Requests in the SessionStudentItem Component
    handleSave = () => {
        this.setState({ isSaving: true })
        this.props.updateSession(this.props.sessionId, this.state.teacher, this.state.comments, this.handleCallback);
    }

    handleCallback = () => {
        this.setState(prevState => ({ 
            saveToggle: !prevState.saveToggle,
            isSaving: false
        }));
        this.handleOpenPopup("success", `Session updated!`);
    }

    toggleAlert = () => {
        this.setState(prevState => ({ openAlert: !prevState.openAlert }));
    }

    toggleAccept = () => {
        this.props.deleteSession(this.props.sessionId);
        this.props.toggleForm();
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        return (
            <div className="session-details-container">
                <div className="actions-container">
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && <div className="add-student">
                        <CustomSelect
                            width={140}
                            name="selectedStudent"
                            placeholder="Add a student"
                            value={this.state.selectedStudent}
                            handleChange={this.handleSelect}>
                                {this.props.students && this.props.studentsArray.slice().map(student => {
                                        return (<MenuItem key={student.id} value={student.id}>
                                            {student.firstName} {student.lastName}
                                        </MenuItem>)
                                    }).sort((a, b) => a.firstName - b.firstName)
                                })
                        </CustomSelect>
                        <div className="add-btn">
                            <Button color="secondary" variant="contained" size="small" onClick={this.handleAddStudent}>
                                <AddIcon />
                                Add
                            </Button>
                        </div>
                    </div>}
                    <SelectTeacher 
                        handleChange={this.handleSelect}
                        value={this.state.teacher}/>
                </div>
                <div>
                { this.renderStudents() }
                <TextField
                    id="outlined-multiline-static"
                    label="Class comments"
                    name="comments"
                    multiline
                    style={{width: '50%', float: 'right'}}
                    value={this.state.comments}
                    onChange={this.handleChange("comments")}
                    rows="2"
                    margin="normal"
                    variant="outlined"/>
                </div>
                <div className="actions-container">
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                        <Button color="secondary" variant="outlined" size="small" onClick={this.toggleAlert}>  
                            Delete session
                        </Button>
                    }
                    <Button color="secondary" variant="contained" size="small" onClick={this.handleSave}>  
                        Save
                    </Button>
                </div>
                <Alert 
                    action={this.toggleAccept} 
                    toggleAlert={this.toggleAlert} 
                    openAlert={this.state.openAlert} 
                    title={`Are you sure you want to delete this session?`} 
                    message={`NOTE: All children and records will be deleted. This action can't be undone.`}/>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
                <Backdrop
                    sx={{ color: '#fff' }}
                    open={this.state.isSaving}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return { 
        profile: state.firebase.profile,
        makeupClass: state.firestore.ordered.makeupClass,
        students: state.firestore.data.students,
        studentsArray: state.firestore.ordered.students,
        teachers: state.firestore.data.teachers
    }
}

export default connect(mapStateToProps, { addStudentToSession, deleteSession, updateSession, deleteStudentFromMakeup })(SessionDetails);