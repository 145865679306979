import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import LinkRenderer from '../shared/LinkRenderer';
import TeacherRenderer from './TeacherRenderer';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { isLocationMatch } from '../../js/helpers';
import DeleteClassRenderer from './DeleteClassRenderer';
import StudentsInClassRenderer from './StudentsInClassRenderer';

class ClassGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridOptions: {
                columnDefs: [{
                    headerName: "Class Name",
                    field: "className",
                    cellRenderer: 'linkRenderer'
                }, {
                    headerName: "Location",
                    field: "location"
                }, {
                    headerName: "Teacher",
                    field: "teacher",
                    cellRenderer: "teacherRenderer"
                }, {
                    headerName: "Number of Students",
                    field: "numOfStudents",
                    cellRenderer: 'studentsInClassRenderer'
                }, {
                    headerName: "Delete",
                    field: 'delete',
                    cellRenderer: 'deleteClassRenderer'
                }]
            },
            rowData: [],
            frameworkComponents: {
                linkRenderer: LinkRenderer,
                teacherRenderer: TeacherRenderer,
                deleteClassRenderer: DeleteClassRenderer,
                studentsInClassRenderer: StudentsInClassRenderer
            },
            paginationPageSize: 20
        }
    }

    componentDidMount() {
        if(this.props.classes) {
            let rowData = convertDataToRow(this.props.classes, this.props.teachers, this.props.locationFilter, this.props.students, this.props.handleOpenPopup);
            this.setState({ rowData });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.search !== this.props.search) {
            this.state.gridOptions.api.setQuickFilter(this.props.search)
        }
        if((prevProps.classes !== this.props.classes && this.props.classes ) ||
            (prevProps.teachers !== this.props.teachers && this.props.teachers)
            || (prevProps.locationFilter !== this.props.locationFilter)) {
            if(this.props.classes && this.props.teachers) {
                let rowData = convertDataToRow(this.props.classes, this.props.teachers, this.props.locationFilter, this.props.students, this.props.handleOpenPopup);
                this.setState({ rowData });
            }
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        if(this.props.profile.isTeacher) {
            this.state.gridOptions.columnApi.setColumnVisible('delete', false)
        }
    };

    render() {
        return (
            <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                <AgGridReact
                    onGridReady={this.onGridReady}
                    gridOptions={this.state.gridOptions}
                    quickFilter={this.props.search}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}>
                </AgGridReact>
            </div>
        )
    }
}

export default ClassGrid;

const convertDataToRow = (data, teachers, locationFilter, students, handleOpenPopup) => {
    let rowData = []
    Object.keys(data).map(key => {
        let row = data[key];
        if(row) {
            let currTeacher = teachers[row.teacher.id];
            let teacherName = "";
            let teacherId = "";

            if(currTeacher) {
                teacherName = currTeacher.firstName + ' ' + currTeacher.lastName
                teacherId = currTeacher.id
            }
            let studentsData = []
            row.students.forEach(studentRef => {
                let student = students[studentRef.id];
                if(student) {
                    let currStudent = {
                        name: `${student.firstName} ${student.lastName}`,
                        jusanLevel: student.jusanLevel,
                        amsanLevel: student.amsanLevel
                    };
                    studentsData.push(currStudent)
                }
            })

            let current = {
                id: key,
                className: row.className,
                location: row.location,
                students: studentsData,
                numOfStudents: row.students.length,
                teacher: teacherName,
                teacherId: row.teacher.id,
                url: "classes",
                handleOpenPopup: (code, message) => handleOpenPopup(code, message)
            }
            if(isLocationMatch(locationFilter, row.location)){
                rowData.push(current);
            }
        }
    });
    return rowData;
}