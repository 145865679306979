import React, { PureComponent } from 'react';
import SlidingPane from '../shared/SlidingPane';
import LocationFilter from '../shared/LocationFilter';
import Searchbar from '../shared/Searchbar';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { connect } from 'react-redux';
import InvoiceForm from './InvoiceForm';
import EditForm from './EditForm';
import InvoiceGrid from './InvoiceGrid';
import './styles/invoice.scss';
import { getAllStripePlans } from '../../store/actions/stripeActions';

class Invoice extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            search: "",
            alert: {
                variant: "success",
                open: false,
                message: ""
            },
            invoice: null,
            editForm: false,
            editableInvoice: null,
            updatedInvoice: null
        }
    }

    componentDidMount() {
        if(!this.props.stripePlans) {
            this.props.getAllStripePlans();
        }

    }

    handleChange = e => {
        this.setState({ search: e.target.value.toLowerCase() });
    }

    toggleForm = () => {
        this.setState(prevState => ({ showForm: !prevState.showForm }));
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    handleInvoice = (invoice) => {
        this.setState({ invoice })
    }

    handleUpdateInvoice = (updatedInvoice) => {
        this.setState({ updatedInvoice })
    }

    toggleEditForm = (editableInvoice) => {
        this.setState(prevState => ({ 
            editForm: !prevState.editForm,
            editableInvoice
         }));
    }


    render() {
        return (
            <div className="tab-container">
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) &&
                    <SlidingPane header="Create an invoice" toggleForm={this.toggleForm} showForm={this.state.showForm} width={70}>
                        {this.state.showForm && <InvoiceForm 
                                                    toggleForm={this.toggleForm} 
                                                    showForm={this.state.showForm} 
                                                    handleOpenPopup={this.handleOpenPopup}
                                                    handleInvoice={this.handleInvoice}/>}
                    </SlidingPane>
                }
                
                {(this.props.profile.isAdmin || this.props.profile.isCenterManager) &&
                    <SlidingPane header="Edit invoice" toggleForm={this.toggleEditForm} showForm={this.state.editForm} width={70}>
                        {this.state.editForm && <EditForm 
                                                    toggleForm={this.toggleEditForm} 
                                                    showForm={this.state.editForm} 
                                                    handleOpenPopup={this.handleOpenPopup}
                                                    editableInvoice={this.state.editableInvoice}
                                                    handleUpdateInvoice={this.handleUpdateInvoice}/>}
                    </SlidingPane>
                }
                
                <div className="list-actions-container">
                    <Searchbar 
                        search={this.state.search}
                        handleChange={this.handleChange}/>
                    {(this.props.profile.isAdmin || this.props.profile.isCenterManager) && 
                    <>
                        <div className="action-container">
                            <h4 style={{marginRight: '1rem'}}>Create invoice</h4>
                            <Fab size="small" color="secondary" aria-label="add" onClick={this.toggleForm}>
                                <AddIcon/> 
                            </Fab>
                        </div>
                    </>
                }
                </div>
                <div className="invoice-grid-container">
                    <InvoiceGrid 
                        newInvoice={this.state.invoice} 
                        updatedInvoice={this.state.updatedInvoice}
                        editableInvoice={this.state.editableInvoice}
                        toggleEditForm={this.toggleEditForm}/>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        families: state.firestore.data.families,
    }
}

export default connect(mapStateToProps, { getAllStripePlans })(Invoice);