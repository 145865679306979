import React, { PureComponent } from 'react';
import ClassSessionsListHeader from './ClassSessionsListHeader';
import SessionsGrid from './SessionsGrid';
import { PopupSnackbar } from '../shared/MySnackbarContentWrapper';
import { connect } from 'react-redux';
import './style/sessions.scss';

class ClassSessions extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            alert: {
                variant: "success",
                open: false,
                message: ""
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const wrapper = document.getElementById('sub-list-container');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentDidUpdate(prevProps, prevState) {
        setTimeout(() => {
            const wrapper = document.getElementById('sub-list-container');
            if(wrapper) 
                wrapper.classList.add('active');
        }, 300);
    }

    componentWillUnmount() {
        this.setState({ sessions: []});
        const wrapper = document.getElementById('sub-list-container');
        if(wrapper) wrapper.classList.remove('active')
    }

    handleOpenPopup = (state, message) => {
        this.setState({ alert: { variant: state, open: true, message }});
    }

    handleClosePopup = (event, reason) => {
        this.setState(prevState => ({ alert: { variant: prevState.alert.variant, open: false, message: prevState.alert.message }}));
    }

    render() {
        const { classId, classObj } = this.props;
        return (
            <div className="sub-list-container" id="sub-list-container">
                <ClassSessionsListHeader 
                    classId={classId} 
                    classObj={classObj} 
                    handleOpenPopup={this.props.handleOpenPopup}/>
                <div className="sessions-grid-container">
                    <SessionsGrid 
                        isTeacher={this.props.profile.isTeacher}
                        classObj={classObj} 
                        profile={this.props.profile}
                        teachers={this.props.teachers}
                        sessions={this.props.sessions}
                        students={this.props.students}
                        attendanceRecords={this.props.attendanceRecords}
                        handleOpenPopup={this.handleOpenPopup}/>
                </div>
                <PopupSnackbar 
                    variant={this.state.alert.variant} 
                    message={this.state.alert.message} 
                    open={this.state.alert.open} 
                    close={this.handleClosePopup}
                    duration={4000}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return { 
        teachers: state.firestore.data.teachers,
        students: state.firestore.data.students,
        sessions: state.firestore.data.sessions,
        subjects: state.firestore.data.subjects,
        classTypes: state.firestore.data.classTypes,
        attendanceRecords: state.firestore.data.attendanceRecords,
        profile: state.firebase.profile
    };
}

export default connect(mapStateToProps)(ClassSessions);