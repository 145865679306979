import React, { PureComponent } from 'react';
import WidgetContainer from '../WidgetContainer';
import birthdayIcon from '../../resources/birthday.png';
import getDayOfYear from 'date-fns/getDayOfYear'
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import ArrowBack from '@material-ui/icons/ArrowLeft';
import ArrowForward from '@material-ui/icons/ArrowRight';

import format from 'date-fns/format';
import './birthdays.scss';

import { connect } from 'react-redux';

class Birthdays extends PureComponent {
    state = {
        // currentMonth: format(new Date(), 'LLLL'),
        currentMonth: new Date()
    }

    renderBirthdays = () => {
        if(this.props.students) {
            const { students } = this.props;
            let displayStudents = students
                .filter(stu => {
                    if(stu.birthday) {
                        return format(this.state.currentMonth, "MM") === format(stu.birthday.toDate(), "MM");
                    }
                    return false;
                })
                .sort((a, b) => getDayOfYear(a.birthday.toDate()) - getDayOfYear(b.birthday.toDate()))

            return displayStudents
                .map(student => (
                    <div key={student.id} className="row">
                        <h4 className="name">{student.firstName} {student.lastName}</h4>
                        <h4 className="date">{format(student?.birthday?.toDate(), "MM/dd/yyyy")}</h4>
                    </div>
                ))
        }
        
    }

    nextMonth = () => {
        this.setState({
            currentMonth: addMonths(this.state.currentMonth, 1)
        });
    }
    
    prevMonth = () => {
        this.setState({
            currentMonth: subMonths(this.state.currentMonth, 1)
        });
    }

    render() {
        return (
            <WidgetContainer title="Birthdays" icon={birthdayIcon}>
                <div className="birthdays-container">

                    <div className="header-container">
                        <div className="arrow" onClick={this.prevMonth}>
                            <ArrowBack />
                        </div>
                        <div className="month">
                            <h4 className="header">{format(this.state.currentMonth, 'LLLL')}</h4>
                        </div>
                        <div className="arrow" onClick={this.nextMonth}>
                            <ArrowForward />
                        </div>
                    </div>

                    <div className="birthdays">
                        {this.props.students && this.renderBirthdays()}
                    </div>
                    
                </div>
            </WidgetContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        students: state.firestore.ordered.students
    }
}

export default connect(mapStateToProps)(Birthdays);