import React from 'react';

const StudentsInClassRenderer = (props) => {

    const handleClick = (props) => {
        let students = props.data.students;
        let studentsData = students.map(student => {
            return student.name
        }).join(",  ")

        props.data.handleOpenPopup("info", studentsData);
    }
    
    return (
        <div className="students-in-class-container" onClick={() => handleClick(props)}>
            {props.value}
        </div>
    )
}

export default StudentsInClassRenderer;